/*
Template Name: Landrick - Saas & Software Bootstrap 5 Landing & Admin Dashboard Template
Author: Shreethemes
E-mail: support@shreethemes.in
Created: August 2019
Version: 4.4.0
File: Icons Css File
*/
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=5.8.55");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=5.8.55") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=5.8.55") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=5.8.55") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=5.8.55") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "\f01c9";
}

.mdi-abjad-arabic::before {
  content: "\f1328";
}

.mdi-abjad-hebrew::before {
  content: "\f1329";
}

.mdi-abugida-devanagari::before {
  content: "\f132a";
}

.mdi-abugida-thai::before {
  content: "\f132b";
}

.mdi-access-point::before {
  content: "\f0003";
}

.mdi-access-point-check::before {
  content: "\f1538";
}

.mdi-access-point-minus::before {
  content: "\f1539";
}

.mdi-access-point-network::before {
  content: "\f0002";
}

.mdi-access-point-network-off::before {
  content: "\f0be1";
}

.mdi-access-point-off::before {
  content: "\f1511";
}

.mdi-access-point-plus::before {
  content: "\f153a";
}

.mdi-access-point-remove::before {
  content: "\f153b";
}

.mdi-account::before {
  content: "\f0004";
}

.mdi-account-alert::before {
  content: "\f0005";
}

.mdi-account-alert-outline::before {
  content: "\f0b50";
}

.mdi-account-arrow-left::before {
  content: "\f0b51";
}

.mdi-account-arrow-left-outline::before {
  content: "\f0b52";
}

.mdi-account-arrow-right::before {
  content: "\f0b53";
}

.mdi-account-arrow-right-outline::before {
  content: "\f0b54";
}

.mdi-account-box::before {
  content: "\f0006";
}

.mdi-account-box-multiple::before {
  content: "\f0934";
}

.mdi-account-box-multiple-outline::before {
  content: "\f100a";
}

.mdi-account-box-outline::before {
  content: "\f0007";
}

.mdi-account-cancel::before {
  content: "\f12df";
}

.mdi-account-cancel-outline::before {
  content: "\f12e0";
}

.mdi-account-cash::before {
  content: "\f1097";
}

.mdi-account-cash-outline::before {
  content: "\f1098";
}

.mdi-account-check::before {
  content: "\f0008";
}

.mdi-account-check-outline::before {
  content: "\f0be2";
}

.mdi-account-child::before {
  content: "\f0a89";
}

.mdi-account-child-circle::before {
  content: "\f0a8a";
}

.mdi-account-child-outline::before {
  content: "\f10c8";
}

.mdi-account-circle::before {
  content: "\f0009";
}

.mdi-account-circle-outline::before {
  content: "\f0b55";
}

.mdi-account-clock::before {
  content: "\f0b56";
}

.mdi-account-clock-outline::before {
  content: "\f0b57";
}

.mdi-account-cog::before {
  content: "\f1370";
}

.mdi-account-cog-outline::before {
  content: "\f1371";
}

.mdi-account-convert::before {
  content: "\f000a";
}

.mdi-account-convert-outline::before {
  content: "\f1301";
}

.mdi-account-cowboy-hat::before {
  content: "\f0e9b";
}

.mdi-account-details::before {
  content: "\f0631";
}

.mdi-account-details-outline::before {
  content: "\f1372";
}

.mdi-account-edit::before {
  content: "\f06bc";
}

.mdi-account-edit-outline::before {
  content: "\f0ffb";
}

.mdi-account-group::before {
  content: "\f0849";
}

.mdi-account-group-outline::before {
  content: "\f0b58";
}

.mdi-account-hard-hat::before {
  content: "\f05b5";
}

.mdi-account-heart::before {
  content: "\f0899";
}

.mdi-account-heart-outline::before {
  content: "\f0be3";
}

.mdi-account-key::before {
  content: "\f000b";
}

.mdi-account-key-outline::before {
  content: "\f0be4";
}

.mdi-account-lock::before {
  content: "\f115e";
}

.mdi-account-lock-outline::before {
  content: "\f115f";
}

.mdi-account-minus::before {
  content: "\f000d";
}

.mdi-account-minus-outline::before {
  content: "\f0aec";
}

.mdi-account-multiple::before {
  content: "\f000e";
}

.mdi-account-multiple-check::before {
  content: "\f08c5";
}

.mdi-account-multiple-check-outline::before {
  content: "\f11fe";
}

.mdi-account-multiple-minus::before {
  content: "\f05d3";
}

.mdi-account-multiple-minus-outline::before {
  content: "\f0be5";
}

.mdi-account-multiple-outline::before {
  content: "\f000f";
}

.mdi-account-multiple-plus::before {
  content: "\f0010";
}

.mdi-account-multiple-plus-outline::before {
  content: "\f0800";
}

.mdi-account-multiple-remove::before {
  content: "\f120a";
}

.mdi-account-multiple-remove-outline::before {
  content: "\f120b";
}

.mdi-account-music::before {
  content: "\f0803";
}

.mdi-account-music-outline::before {
  content: "\f0ce9";
}

.mdi-account-network::before {
  content: "\f0011";
}

.mdi-account-network-outline::before {
  content: "\f0be6";
}

.mdi-account-off::before {
  content: "\f0012";
}

.mdi-account-off-outline::before {
  content: "\f0be7";
}

.mdi-account-outline::before {
  content: "\f0013";
}

.mdi-account-plus::before {
  content: "\f0014";
}

.mdi-account-plus-outline::before {
  content: "\f0801";
}

.mdi-account-question::before {
  content: "\f0b59";
}

.mdi-account-question-outline::before {
  content: "\f0b5a";
}

.mdi-account-reactivate::before {
  content: "\f152b";
}

.mdi-account-reactivate-outline::before {
  content: "\f152c";
}

.mdi-account-remove::before {
  content: "\f0015";
}

.mdi-account-remove-outline::before {
  content: "\f0aed";
}

.mdi-account-search::before {
  content: "\f0016";
}

.mdi-account-search-outline::before {
  content: "\f0935";
}

.mdi-account-settings::before {
  content: "\f0630";
}

.mdi-account-settings-outline::before {
  content: "\f10c9";
}

.mdi-account-star::before {
  content: "\f0017";
}

.mdi-account-star-outline::before {
  content: "\f0be8";
}

.mdi-account-supervisor::before {
  content: "\f0a8b";
}

.mdi-account-supervisor-circle::before {
  content: "\f0a8c";
}

.mdi-account-supervisor-circle-outline::before {
  content: "\f14ec";
}

.mdi-account-supervisor-outline::before {
  content: "\f112d";
}

.mdi-account-switch::before {
  content: "\f0019";
}

.mdi-account-switch-outline::before {
  content: "\f04cb";
}

.mdi-account-tie::before {
  content: "\f0ce3";
}

.mdi-account-tie-outline::before {
  content: "\f10ca";
}

.mdi-account-tie-voice::before {
  content: "\f1308";
}

.mdi-account-tie-voice-off::before {
  content: "\f130a";
}

.mdi-account-tie-voice-off-outline::before {
  content: "\f130b";
}

.mdi-account-tie-voice-outline::before {
  content: "\f1309";
}

.mdi-account-voice::before {
  content: "\f05cb";
}

.mdi-adjust::before {
  content: "\f001a";
}

.mdi-adobe::before {
  content: "\f0936";
}

.mdi-adobe-acrobat::before {
  content: "\f0f9d";
}

.mdi-air-conditioner::before {
  content: "\f001b";
}

.mdi-air-filter::before {
  content: "\f0d43";
}

.mdi-air-horn::before {
  content: "\f0dac";
}

.mdi-air-humidifier::before {
  content: "\f1099";
}

.mdi-air-humidifier-off::before {
  content: "\f1466";
}

.mdi-air-purifier::before {
  content: "\f0d44";
}

.mdi-airbag::before {
  content: "\f0be9";
}

.mdi-airballoon::before {
  content: "\f001c";
}

.mdi-airballoon-outline::before {
  content: "\f100b";
}

.mdi-airplane::before {
  content: "\f001d";
}

.mdi-airplane-landing::before {
  content: "\f05d4";
}

.mdi-airplane-off::before {
  content: "\f001e";
}

.mdi-airplane-takeoff::before {
  content: "\f05d5";
}

.mdi-airport::before {
  content: "\f084b";
}

.mdi-alarm::before {
  content: "\f0020";
}

.mdi-alarm-bell::before {
  content: "\f078e";
}

.mdi-alarm-check::before {
  content: "\f0021";
}

.mdi-alarm-light::before {
  content: "\f078f";
}

.mdi-alarm-light-outline::before {
  content: "\f0bea";
}

.mdi-alarm-multiple::before {
  content: "\f0022";
}

.mdi-alarm-note::before {
  content: "\f0e71";
}

.mdi-alarm-note-off::before {
  content: "\f0e72";
}

.mdi-alarm-off::before {
  content: "\f0023";
}

.mdi-alarm-panel::before {
  content: "\f15c4";
}

.mdi-alarm-panel-outline::before {
  content: "\f15c5";
}

.mdi-alarm-plus::before {
  content: "\f0024";
}

.mdi-alarm-snooze::before {
  content: "\f068e";
}

.mdi-album::before {
  content: "\f0025";
}

.mdi-alert::before {
  content: "\f0026";
}

.mdi-alert-box::before {
  content: "\f0027";
}

.mdi-alert-box-outline::before {
  content: "\f0ce4";
}

.mdi-alert-circle::before {
  content: "\f0028";
}

.mdi-alert-circle-check::before {
  content: "\f11ed";
}

.mdi-alert-circle-check-outline::before {
  content: "\f11ee";
}

.mdi-alert-circle-outline::before {
  content: "\f05d6";
}

.mdi-alert-decagram::before {
  content: "\f06bd";
}

.mdi-alert-decagram-outline::before {
  content: "\f0ce5";
}

.mdi-alert-minus::before {
  content: "\f14bb";
}

.mdi-alert-minus-outline::before {
  content: "\f14be";
}

.mdi-alert-octagon::before {
  content: "\f0029";
}

.mdi-alert-octagon-outline::before {
  content: "\f0ce6";
}

.mdi-alert-octagram::before {
  content: "\f0767";
}

.mdi-alert-octagram-outline::before {
  content: "\f0ce7";
}

.mdi-alert-outline::before {
  content: "\f002a";
}

.mdi-alert-plus::before {
  content: "\f14ba";
}

.mdi-alert-plus-outline::before {
  content: "\f14bd";
}

.mdi-alert-remove::before {
  content: "\f14bc";
}

.mdi-alert-remove-outline::before {
  content: "\f14bf";
}

.mdi-alert-rhombus::before {
  content: "\f11ce";
}

.mdi-alert-rhombus-outline::before {
  content: "\f11cf";
}

.mdi-alien::before {
  content: "\f089a";
}

.mdi-alien-outline::before {
  content: "\f10cb";
}

.mdi-align-horizontal-center::before {
  content: "\f11c3";
}

.mdi-align-horizontal-left::before {
  content: "\f11c2";
}

.mdi-align-horizontal-right::before {
  content: "\f11c4";
}

.mdi-align-vertical-bottom::before {
  content: "\f11c5";
}

.mdi-align-vertical-center::before {
  content: "\f11c6";
}

.mdi-align-vertical-top::before {
  content: "\f11c7";
}

.mdi-all-inclusive::before {
  content: "\f06be";
}

.mdi-allergy::before {
  content: "\f1258";
}

.mdi-alpha::before {
  content: "\f002b";
}

.mdi-alpha-a::before {
  content: "\f0aee";
}

.mdi-alpha-a-box::before {
  content: "\f0b08";
}

.mdi-alpha-a-box-outline::before {
  content: "\f0beb";
}

.mdi-alpha-a-circle::before {
  content: "\f0bec";
}

.mdi-alpha-a-circle-outline::before {
  content: "\f0bed";
}

.mdi-alpha-b::before {
  content: "\f0aef";
}

.mdi-alpha-b-box::before {
  content: "\f0b09";
}

.mdi-alpha-b-box-outline::before {
  content: "\f0bee";
}

.mdi-alpha-b-circle::before {
  content: "\f0bef";
}

.mdi-alpha-b-circle-outline::before {
  content: "\f0bf0";
}

.mdi-alpha-c::before {
  content: "\f0af0";
}

.mdi-alpha-c-box::before {
  content: "\f0b0a";
}

.mdi-alpha-c-box-outline::before {
  content: "\f0bf1";
}

.mdi-alpha-c-circle::before {
  content: "\f0bf2";
}

.mdi-alpha-c-circle-outline::before {
  content: "\f0bf3";
}

.mdi-alpha-d::before {
  content: "\f0af1";
}

.mdi-alpha-d-box::before {
  content: "\f0b0b";
}

.mdi-alpha-d-box-outline::before {
  content: "\f0bf4";
}

.mdi-alpha-d-circle::before {
  content: "\f0bf5";
}

.mdi-alpha-d-circle-outline::before {
  content: "\f0bf6";
}

.mdi-alpha-e::before {
  content: "\f0af2";
}

.mdi-alpha-e-box::before {
  content: "\f0b0c";
}

.mdi-alpha-e-box-outline::before {
  content: "\f0bf7";
}

.mdi-alpha-e-circle::before {
  content: "\f0bf8";
}

.mdi-alpha-e-circle-outline::before {
  content: "\f0bf9";
}

.mdi-alpha-f::before {
  content: "\f0af3";
}

.mdi-alpha-f-box::before {
  content: "\f0b0d";
}

.mdi-alpha-f-box-outline::before {
  content: "\f0bfa";
}

.mdi-alpha-f-circle::before {
  content: "\f0bfb";
}

.mdi-alpha-f-circle-outline::before {
  content: "\f0bfc";
}

.mdi-alpha-g::before {
  content: "\f0af4";
}

.mdi-alpha-g-box::before {
  content: "\f0b0e";
}

.mdi-alpha-g-box-outline::before {
  content: "\f0bfd";
}

.mdi-alpha-g-circle::before {
  content: "\f0bfe";
}

.mdi-alpha-g-circle-outline::before {
  content: "\f0bff";
}

.mdi-alpha-h::before {
  content: "\f0af5";
}

.mdi-alpha-h-box::before {
  content: "\f0b0f";
}

.mdi-alpha-h-box-outline::before {
  content: "\f0c00";
}

.mdi-alpha-h-circle::before {
  content: "\f0c01";
}

.mdi-alpha-h-circle-outline::before {
  content: "\f0c02";
}

.mdi-alpha-i::before {
  content: "\f0af6";
}

.mdi-alpha-i-box::before {
  content: "\f0b10";
}

.mdi-alpha-i-box-outline::before {
  content: "\f0c03";
}

.mdi-alpha-i-circle::before {
  content: "\f0c04";
}

.mdi-alpha-i-circle-outline::before {
  content: "\f0c05";
}

.mdi-alpha-j::before {
  content: "\f0af7";
}

.mdi-alpha-j-box::before {
  content: "\f0b11";
}

.mdi-alpha-j-box-outline::before {
  content: "\f0c06";
}

.mdi-alpha-j-circle::before {
  content: "\f0c07";
}

.mdi-alpha-j-circle-outline::before {
  content: "\f0c08";
}

.mdi-alpha-k::before {
  content: "\f0af8";
}

.mdi-alpha-k-box::before {
  content: "\f0b12";
}

.mdi-alpha-k-box-outline::before {
  content: "\f0c09";
}

.mdi-alpha-k-circle::before {
  content: "\f0c0a";
}

.mdi-alpha-k-circle-outline::before {
  content: "\f0c0b";
}

.mdi-alpha-l::before {
  content: "\f0af9";
}

.mdi-alpha-l-box::before {
  content: "\f0b13";
}

.mdi-alpha-l-box-outline::before {
  content: "\f0c0c";
}

.mdi-alpha-l-circle::before {
  content: "\f0c0d";
}

.mdi-alpha-l-circle-outline::before {
  content: "\f0c0e";
}

.mdi-alpha-m::before {
  content: "\f0afa";
}

.mdi-alpha-m-box::before {
  content: "\f0b14";
}

.mdi-alpha-m-box-outline::before {
  content: "\f0c0f";
}

.mdi-alpha-m-circle::before {
  content: "\f0c10";
}

.mdi-alpha-m-circle-outline::before {
  content: "\f0c11";
}

.mdi-alpha-n::before {
  content: "\f0afb";
}

.mdi-alpha-n-box::before {
  content: "\f0b15";
}

.mdi-alpha-n-box-outline::before {
  content: "\f0c12";
}

.mdi-alpha-n-circle::before {
  content: "\f0c13";
}

.mdi-alpha-n-circle-outline::before {
  content: "\f0c14";
}

.mdi-alpha-o::before {
  content: "\f0afc";
}

.mdi-alpha-o-box::before {
  content: "\f0b16";
}

.mdi-alpha-o-box-outline::before {
  content: "\f0c15";
}

.mdi-alpha-o-circle::before {
  content: "\f0c16";
}

.mdi-alpha-o-circle-outline::before {
  content: "\f0c17";
}

.mdi-alpha-p::before {
  content: "\f0afd";
}

.mdi-alpha-p-box::before {
  content: "\f0b17";
}

.mdi-alpha-p-box-outline::before {
  content: "\f0c18";
}

.mdi-alpha-p-circle::before {
  content: "\f0c19";
}

.mdi-alpha-p-circle-outline::before {
  content: "\f0c1a";
}

.mdi-alpha-q::before {
  content: "\f0afe";
}

.mdi-alpha-q-box::before {
  content: "\f0b18";
}

.mdi-alpha-q-box-outline::before {
  content: "\f0c1b";
}

.mdi-alpha-q-circle::before {
  content: "\f0c1c";
}

.mdi-alpha-q-circle-outline::before {
  content: "\f0c1d";
}

.mdi-alpha-r::before {
  content: "\f0aff";
}

.mdi-alpha-r-box::before {
  content: "\f0b19";
}

.mdi-alpha-r-box-outline::before {
  content: "\f0c1e";
}

.mdi-alpha-r-circle::before {
  content: "\f0c1f";
}

.mdi-alpha-r-circle-outline::before {
  content: "\f0c20";
}

.mdi-alpha-s::before {
  content: "\f0b00";
}

.mdi-alpha-s-box::before {
  content: "\f0b1a";
}

.mdi-alpha-s-box-outline::before {
  content: "\f0c21";
}

.mdi-alpha-s-circle::before {
  content: "\f0c22";
}

.mdi-alpha-s-circle-outline::before {
  content: "\f0c23";
}

.mdi-alpha-t::before {
  content: "\f0b01";
}

.mdi-alpha-t-box::before {
  content: "\f0b1b";
}

.mdi-alpha-t-box-outline::before {
  content: "\f0c24";
}

.mdi-alpha-t-circle::before {
  content: "\f0c25";
}

.mdi-alpha-t-circle-outline::before {
  content: "\f0c26";
}

.mdi-alpha-u::before {
  content: "\f0b02";
}

.mdi-alpha-u-box::before {
  content: "\f0b1c";
}

.mdi-alpha-u-box-outline::before {
  content: "\f0c27";
}

.mdi-alpha-u-circle::before {
  content: "\f0c28";
}

.mdi-alpha-u-circle-outline::before {
  content: "\f0c29";
}

.mdi-alpha-v::before {
  content: "\f0b03";
}

.mdi-alpha-v-box::before {
  content: "\f0b1d";
}

.mdi-alpha-v-box-outline::before {
  content: "\f0c2a";
}

.mdi-alpha-v-circle::before {
  content: "\f0c2b";
}

.mdi-alpha-v-circle-outline::before {
  content: "\f0c2c";
}

.mdi-alpha-w::before {
  content: "\f0b04";
}

.mdi-alpha-w-box::before {
  content: "\f0b1e";
}

.mdi-alpha-w-box-outline::before {
  content: "\f0c2d";
}

.mdi-alpha-w-circle::before {
  content: "\f0c2e";
}

.mdi-alpha-w-circle-outline::before {
  content: "\f0c2f";
}

.mdi-alpha-x::before {
  content: "\f0b05";
}

.mdi-alpha-x-box::before {
  content: "\f0b1f";
}

.mdi-alpha-x-box-outline::before {
  content: "\f0c30";
}

.mdi-alpha-x-circle::before {
  content: "\f0c31";
}

.mdi-alpha-x-circle-outline::before {
  content: "\f0c32";
}

.mdi-alpha-y::before {
  content: "\f0b06";
}

.mdi-alpha-y-box::before {
  content: "\f0b20";
}

.mdi-alpha-y-box-outline::before {
  content: "\f0c33";
}

.mdi-alpha-y-circle::before {
  content: "\f0c34";
}

.mdi-alpha-y-circle-outline::before {
  content: "\f0c35";
}

.mdi-alpha-z::before {
  content: "\f0b07";
}

.mdi-alpha-z-box::before {
  content: "\f0b21";
}

.mdi-alpha-z-box-outline::before {
  content: "\f0c36";
}

.mdi-alpha-z-circle::before {
  content: "\f0c37";
}

.mdi-alpha-z-circle-outline::before {
  content: "\f0c38";
}

.mdi-alphabet-aurebesh::before {
  content: "\f132c";
}

.mdi-alphabet-cyrillic::before {
  content: "\f132d";
}

.mdi-alphabet-greek::before {
  content: "\f132e";
}

.mdi-alphabet-latin::before {
  content: "\f132f";
}

.mdi-alphabet-piqad::before {
  content: "\f1330";
}

.mdi-alphabet-tengwar::before {
  content: "\f1337";
}

.mdi-alphabetical::before {
  content: "\f002c";
}

.mdi-alphabetical-off::before {
  content: "\f100c";
}

.mdi-alphabetical-variant::before {
  content: "\f100d";
}

.mdi-alphabetical-variant-off::before {
  content: "\f100e";
}

.mdi-altimeter::before {
  content: "\f05d7";
}

.mdi-amazon::before {
  content: "\f002d";
}

.mdi-amazon-alexa::before {
  content: "\f08c6";
}

.mdi-ambulance::before {
  content: "\f002f";
}

.mdi-ammunition::before {
  content: "\f0ce8";
}

.mdi-ampersand::before {
  content: "\f0a8d";
}

.mdi-amplifier::before {
  content: "\f0030";
}

.mdi-amplifier-off::before {
  content: "\f11b5";
}

.mdi-anchor::before {
  content: "\f0031";
}

.mdi-android::before {
  content: "\f0032";
}

.mdi-android-auto::before {
  content: "\f0a8e";
}

.mdi-android-debug-bridge::before {
  content: "\f0033";
}

.mdi-android-messages::before {
  content: "\f0d45";
}

.mdi-android-studio::before {
  content: "\f0034";
}

.mdi-angle-acute::before {
  content: "\f0937";
}

.mdi-angle-obtuse::before {
  content: "\f0938";
}

.mdi-angle-right::before {
  content: "\f0939";
}

.mdi-angular::before {
  content: "\f06b2";
}

.mdi-angularjs::before {
  content: "\f06bf";
}

.mdi-animation::before {
  content: "\f05d8";
}

.mdi-animation-outline::before {
  content: "\f0a8f";
}

.mdi-animation-play::before {
  content: "\f093a";
}

.mdi-animation-play-outline::before {
  content: "\f0a90";
}

.mdi-ansible::before {
  content: "\f109a";
}

.mdi-antenna::before {
  content: "\f1119";
}

.mdi-anvil::before {
  content: "\f089b";
}

.mdi-apache-kafka::before {
  content: "\f100f";
}

.mdi-api::before {
  content: "\f109b";
}

.mdi-api-off::before {
  content: "\f1257";
}

.mdi-apple::before {
  content: "\f0035";
}

.mdi-apple-airplay::before {
  content: "\f001f";
}

.mdi-apple-finder::before {
  content: "\f0036";
}

.mdi-apple-icloud::before {
  content: "\f0038";
}

.mdi-apple-ios::before {
  content: "\f0037";
}

.mdi-apple-keyboard-caps::before {
  content: "\f0632";
}

.mdi-apple-keyboard-command::before {
  content: "\f0633";
}

.mdi-apple-keyboard-control::before {
  content: "\f0634";
}

.mdi-apple-keyboard-option::before {
  content: "\f0635";
}

.mdi-apple-keyboard-shift::before {
  content: "\f0636";
}

.mdi-apple-safari::before {
  content: "\f0039";
}

.mdi-application::before {
  content: "\f0614";
}

.mdi-application-cog::before {
  content: "\f1577";
}

.mdi-application-export::before {
  content: "\f0dad";
}

.mdi-application-import::before {
  content: "\f0dae";
}

.mdi-application-settings::before {
  content: "\f1555";
}

.mdi-approximately-equal::before {
  content: "\f0f9e";
}

.mdi-approximately-equal-box::before {
  content: "\f0f9f";
}

.mdi-apps::before {
  content: "\f003b";
}

.mdi-apps-box::before {
  content: "\f0d46";
}

.mdi-arch::before {
  content: "\f08c7";
}

.mdi-archive::before {
  content: "\f003c";
}

.mdi-archive-alert::before {
  content: "\f14fd";
}

.mdi-archive-alert-outline::before {
  content: "\f14fe";
}

.mdi-archive-arrow-down::before {
  content: "\f1259";
}

.mdi-archive-arrow-down-outline::before {
  content: "\f125a";
}

.mdi-archive-arrow-up::before {
  content: "\f125b";
}

.mdi-archive-arrow-up-outline::before {
  content: "\f125c";
}

.mdi-archive-outline::before {
  content: "\f120e";
}

.mdi-arm-flex::before {
  content: "\f0fd7";
}

.mdi-arm-flex-outline::before {
  content: "\f0fd6";
}

.mdi-arrange-bring-forward::before {
  content: "\f003d";
}

.mdi-arrange-bring-to-front::before {
  content: "\f003e";
}

.mdi-arrange-send-backward::before {
  content: "\f003f";
}

.mdi-arrange-send-to-back::before {
  content: "\f0040";
}

.mdi-arrow-all::before {
  content: "\f0041";
}

.mdi-arrow-bottom-left::before {
  content: "\f0042";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\f09b7";
}

.mdi-arrow-bottom-left-thick::before {
  content: "\f09b8";
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\f1596";
}

.mdi-arrow-bottom-right::before {
  content: "\f0043";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\f09b9";
}

.mdi-arrow-bottom-right-thick::before {
  content: "\f09ba";
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\f1595";
}

.mdi-arrow-collapse::before {
  content: "\f0615";
}

.mdi-arrow-collapse-all::before {
  content: "\f0044";
}

.mdi-arrow-collapse-down::before {
  content: "\f0792";
}

.mdi-arrow-collapse-horizontal::before {
  content: "\f084c";
}

.mdi-arrow-collapse-left::before {
  content: "\f0793";
}

.mdi-arrow-collapse-right::before {
  content: "\f0794";
}

.mdi-arrow-collapse-up::before {
  content: "\f0795";
}

.mdi-arrow-collapse-vertical::before {
  content: "\f084d";
}

.mdi-arrow-decision::before {
  content: "\f09bb";
}

.mdi-arrow-decision-auto::before {
  content: "\f09bc";
}

.mdi-arrow-decision-auto-outline::before {
  content: "\f09bd";
}

.mdi-arrow-decision-outline::before {
  content: "\f09be";
}

.mdi-arrow-down::before {
  content: "\f0045";
}

.mdi-arrow-down-bold::before {
  content: "\f072e";
}

.mdi-arrow-down-bold-box::before {
  content: "\f072f";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "\f0730";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f0047";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f0048";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f0049";
}

.mdi-arrow-down-bold-outline::before {
  content: "\f09bf";
}

.mdi-arrow-down-box::before {
  content: "\f06c0";
}

.mdi-arrow-down-circle::before {
  content: "\f0cdb";
}

.mdi-arrow-down-circle-outline::before {
  content: "\f0cdc";
}

.mdi-arrow-down-drop-circle::before {
  content: "\f004a";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "\f004b";
}

.mdi-arrow-down-thick::before {
  content: "\f0046";
}

.mdi-arrow-down-thin-circle-outline::before {
  content: "\f1599";
}

.mdi-arrow-expand::before {
  content: "\f0616";
}

.mdi-arrow-expand-all::before {
  content: "\f004c";
}

.mdi-arrow-expand-down::before {
  content: "\f0796";
}

.mdi-arrow-expand-horizontal::before {
  content: "\f084e";
}

.mdi-arrow-expand-left::before {
  content: "\f0797";
}

.mdi-arrow-expand-right::before {
  content: "\f0798";
}

.mdi-arrow-expand-up::before {
  content: "\f0799";
}

.mdi-arrow-expand-vertical::before {
  content: "\f084f";
}

.mdi-arrow-horizontal-lock::before {
  content: "\f115b";
}

.mdi-arrow-left::before {
  content: "\f004d";
}

.mdi-arrow-left-bold::before {
  content: "\f0731";
}

.mdi-arrow-left-bold-box::before {
  content: "\f0732";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "\f0733";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f004f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f0050";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f0051";
}

.mdi-arrow-left-bold-outline::before {
  content: "\f09c0";
}

.mdi-arrow-left-box::before {
  content: "\f06c1";
}

.mdi-arrow-left-circle::before {
  content: "\f0cdd";
}

.mdi-arrow-left-circle-outline::before {
  content: "\f0cde";
}

.mdi-arrow-left-drop-circle::before {
  content: "\f0052";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "\f0053";
}

.mdi-arrow-left-right::before {
  content: "\f0e73";
}

.mdi-arrow-left-right-bold::before {
  content: "\f0e74";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "\f09c1";
}

.mdi-arrow-left-thick::before {
  content: "\f004e";
}

.mdi-arrow-left-thin-circle-outline::before {
  content: "\f159a";
}

.mdi-arrow-right::before {
  content: "\f0054";
}

.mdi-arrow-right-bold::before {
  content: "\f0734";
}

.mdi-arrow-right-bold-box::before {
  content: "\f0735";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "\f0736";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f0056";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f0057";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f0058";
}

.mdi-arrow-right-bold-outline::before {
  content: "\f09c2";
}

.mdi-arrow-right-box::before {
  content: "\f06c2";
}

.mdi-arrow-right-circle::before {
  content: "\f0cdf";
}

.mdi-arrow-right-circle-outline::before {
  content: "\f0ce0";
}

.mdi-arrow-right-drop-circle::before {
  content: "\f0059";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "\f005a";
}

.mdi-arrow-right-thick::before {
  content: "\f0055";
}

.mdi-arrow-right-thin-circle-outline::before {
  content: "\f1598";
}

.mdi-arrow-split-horizontal::before {
  content: "\f093b";
}

.mdi-arrow-split-vertical::before {
  content: "\f093c";
}

.mdi-arrow-top-left::before {
  content: "\f005b";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "\f09c3";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "\f0e75";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\f0e76";
}

.mdi-arrow-top-left-thick::before {
  content: "\f09c4";
}

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\f1593";
}

.mdi-arrow-top-right::before {
  content: "\f005c";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "\f09c5";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "\f0e77";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\f0e78";
}

.mdi-arrow-top-right-thick::before {
  content: "\f09c6";
}

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\f1594";
}

.mdi-arrow-up::before {
  content: "\f005d";
}

.mdi-arrow-up-bold::before {
  content: "\f0737";
}

.mdi-arrow-up-bold-box::before {
  content: "\f0738";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "\f0739";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f005f";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f0060";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f0061";
}

.mdi-arrow-up-bold-outline::before {
  content: "\f09c7";
}

.mdi-arrow-up-box::before {
  content: "\f06c3";
}

.mdi-arrow-up-circle::before {
  content: "\f0ce1";
}

.mdi-arrow-up-circle-outline::before {
  content: "\f0ce2";
}

.mdi-arrow-up-down::before {
  content: "\f0e79";
}

.mdi-arrow-up-down-bold::before {
  content: "\f0e7a";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "\f09c8";
}

.mdi-arrow-up-drop-circle::before {
  content: "\f0062";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "\f0063";
}

.mdi-arrow-up-thick::before {
  content: "\f005e";
}

.mdi-arrow-up-thin-circle-outline::before {
  content: "\f1597";
}

.mdi-arrow-vertical-lock::before {
  content: "\f115c";
}

.mdi-artstation::before {
  content: "\f0b5b";
}

.mdi-aspect-ratio::before {
  content: "\f0a24";
}

.mdi-assistant::before {
  content: "\f0064";
}

.mdi-asterisk::before {
  content: "\f06c4";
}

.mdi-at::before {
  content: "\f0065";
}

.mdi-atlassian::before {
  content: "\f0804";
}

.mdi-atm::before {
  content: "\f0d47";
}

.mdi-atom::before {
  content: "\f0768";
}

.mdi-atom-variant::before {
  content: "\f0e7b";
}

.mdi-attachment::before {
  content: "\f0066";
}

.mdi-audio-video::before {
  content: "\f093d";
}

.mdi-audio-video-off::before {
  content: "\f11b6";
}

.mdi-augmented-reality::before {
  content: "\f0850";
}

.mdi-auto-download::before {
  content: "\f137e";
}

.mdi-auto-fix::before {
  content: "\f0068";
}

.mdi-auto-upload::before {
  content: "\f0069";
}

.mdi-autorenew::before {
  content: "\f006a";
}

.mdi-av-timer::before {
  content: "\f006b";
}

.mdi-aws::before {
  content: "\f0e0f";
}

.mdi-axe::before {
  content: "\f08c8";
}

.mdi-axis::before {
  content: "\f0d48";
}

.mdi-axis-arrow::before {
  content: "\f0d49";
}

.mdi-axis-arrow-info::before {
  content: "\f140e";
}

.mdi-axis-arrow-lock::before {
  content: "\f0d4a";
}

.mdi-axis-lock::before {
  content: "\f0d4b";
}

.mdi-axis-x-arrow::before {
  content: "\f0d4c";
}

.mdi-axis-x-arrow-lock::before {
  content: "\f0d4d";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "\f0d4e";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\f0d4f";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "\f0d50";
}

.mdi-axis-y-arrow::before {
  content: "\f0d51";
}

.mdi-axis-y-arrow-lock::before {
  content: "\f0d52";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "\f0d53";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\f0d54";
}

.mdi-axis-z-arrow::before {
  content: "\f0d55";
}

.mdi-axis-z-arrow-lock::before {
  content: "\f0d56";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "\f0d57";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\f0d58";
}

.mdi-babel::before {
  content: "\f0a25";
}

.mdi-baby::before {
  content: "\f006c";
}

.mdi-baby-bottle::before {
  content: "\f0f39";
}

.mdi-baby-bottle-outline::before {
  content: "\f0f3a";
}

.mdi-baby-buggy::before {
  content: "\f13e0";
}

.mdi-baby-carriage::before {
  content: "\f068f";
}

.mdi-baby-carriage-off::before {
  content: "\f0fa0";
}

.mdi-baby-face::before {
  content: "\f0e7c";
}

.mdi-baby-face-outline::before {
  content: "\f0e7d";
}

.mdi-backburger::before {
  content: "\f006d";
}

.mdi-backspace::before {
  content: "\f006e";
}

.mdi-backspace-outline::before {
  content: "\f0b5c";
}

.mdi-backspace-reverse::before {
  content: "\f0e7e";
}

.mdi-backspace-reverse-outline::before {
  content: "\f0e7f";
}

.mdi-backup-restore::before {
  content: "\f006f";
}

.mdi-bacteria::before {
  content: "\f0ed5";
}

.mdi-bacteria-outline::before {
  content: "\f0ed6";
}

.mdi-badge-account::before {
  content: "\f0da7";
}

.mdi-badge-account-alert::before {
  content: "\f0da8";
}

.mdi-badge-account-alert-outline::before {
  content: "\f0da9";
}

.mdi-badge-account-horizontal::before {
  content: "\f0e0d";
}

.mdi-badge-account-horizontal-outline::before {
  content: "\f0e0e";
}

.mdi-badge-account-outline::before {
  content: "\f0daa";
}

.mdi-badminton::before {
  content: "\f0851";
}

.mdi-bag-carry-on::before {
  content: "\f0f3b";
}

.mdi-bag-carry-on-check::before {
  content: "\f0d65";
}

.mdi-bag-carry-on-off::before {
  content: "\f0f3c";
}

.mdi-bag-checked::before {
  content: "\f0f3d";
}

.mdi-bag-personal::before {
  content: "\f0e10";
}

.mdi-bag-personal-off::before {
  content: "\f0e11";
}

.mdi-bag-personal-off-outline::before {
  content: "\f0e12";
}

.mdi-bag-personal-outline::before {
  content: "\f0e13";
}

.mdi-bag-suitcase::before {
  content: "\f158b";
}

.mdi-bag-suitcase-off::before {
  content: "\f158d";
}

.mdi-bag-suitcase-off-outline::before {
  content: "\f158e";
}

.mdi-bag-suitcase-outline::before {
  content: "\f158c";
}

.mdi-baguette::before {
  content: "\f0f3e";
}

.mdi-balloon::before {
  content: "\f0a26";
}

.mdi-ballot::before {
  content: "\f09c9";
}

.mdi-ballot-outline::before {
  content: "\f09ca";
}

.mdi-ballot-recount::before {
  content: "\f0c39";
}

.mdi-ballot-recount-outline::before {
  content: "\f0c3a";
}

.mdi-bandage::before {
  content: "\f0daf";
}

.mdi-bandcamp::before {
  content: "\f0675";
}

.mdi-bank::before {
  content: "\f0070";
}

.mdi-bank-check::before {
  content: "\f1655";
}

.mdi-bank-minus::before {
  content: "\f0db0";
}

.mdi-bank-off::before {
  content: "\f1656";
}

.mdi-bank-off-outline::before {
  content: "\f1657";
}

.mdi-bank-outline::before {
  content: "\f0e80";
}

.mdi-bank-plus::before {
  content: "\f0db1";
}

.mdi-bank-remove::before {
  content: "\f0db2";
}

.mdi-bank-transfer::before {
  content: "\f0a27";
}

.mdi-bank-transfer-in::before {
  content: "\f0a28";
}

.mdi-bank-transfer-out::before {
  content: "\f0a29";
}

.mdi-barcode::before {
  content: "\f0071";
}

.mdi-barcode-off::before {
  content: "\f1236";
}

.mdi-barcode-scan::before {
  content: "\f0072";
}

.mdi-barley::before {
  content: "\f0073";
}

.mdi-barley-off::before {
  content: "\f0b5d";
}

.mdi-barn::before {
  content: "\f0b5e";
}

.mdi-barrel::before {
  content: "\f0074";
}

.mdi-baseball::before {
  content: "\f0852";
}

.mdi-baseball-bat::before {
  content: "\f0853";
}

.mdi-baseball-diamond::before {
  content: "\f15ec";
}

.mdi-baseball-diamond-outline::before {
  content: "\f15ed";
}

.mdi-bash::before {
  content: "\f1183";
}

.mdi-basket::before {
  content: "\f0076";
}

.mdi-basket-fill::before {
  content: "\f0077";
}

.mdi-basket-minus::before {
  content: "\f1523";
}

.mdi-basket-minus-outline::before {
  content: "\f1524";
}

.mdi-basket-off::before {
  content: "\f1525";
}

.mdi-basket-off-outline::before {
  content: "\f1526";
}

.mdi-basket-outline::before {
  content: "\f1181";
}

.mdi-basket-plus::before {
  content: "\f1527";
}

.mdi-basket-plus-outline::before {
  content: "\f1528";
}

.mdi-basket-remove::before {
  content: "\f1529";
}

.mdi-basket-remove-outline::before {
  content: "\f152a";
}

.mdi-basket-unfill::before {
  content: "\f0078";
}

.mdi-basketball::before {
  content: "\f0806";
}

.mdi-basketball-hoop::before {
  content: "\f0c3b";
}

.mdi-basketball-hoop-outline::before {
  content: "\f0c3c";
}

.mdi-bat::before {
  content: "\f0b5f";
}

.mdi-battery::before {
  content: "\f0079";
}

.mdi-battery-10::before {
  content: "\f007a";
}

.mdi-battery-10-bluetooth::before {
  content: "\f093e";
}

.mdi-battery-20::before {
  content: "\f007b";
}

.mdi-battery-20-bluetooth::before {
  content: "\f093f";
}

.mdi-battery-30::before {
  content: "\f007c";
}

.mdi-battery-30-bluetooth::before {
  content: "\f0940";
}

.mdi-battery-40::before {
  content: "\f007d";
}

.mdi-battery-40-bluetooth::before {
  content: "\f0941";
}

.mdi-battery-50::before {
  content: "\f007e";
}

.mdi-battery-50-bluetooth::before {
  content: "\f0942";
}

.mdi-battery-60::before {
  content: "\f007f";
}

.mdi-battery-60-bluetooth::before {
  content: "\f0943";
}

.mdi-battery-70::before {
  content: "\f0080";
}

.mdi-battery-70-bluetooth::before {
  content: "\f0944";
}

.mdi-battery-80::before {
  content: "\f0081";
}

.mdi-battery-80-bluetooth::before {
  content: "\f0945";
}

.mdi-battery-90::before {
  content: "\f0082";
}

.mdi-battery-90-bluetooth::before {
  content: "\f0946";
}

.mdi-battery-alert::before {
  content: "\f0083";
}

.mdi-battery-alert-bluetooth::before {
  content: "\f0947";
}

.mdi-battery-alert-variant::before {
  content: "\f10cc";
}

.mdi-battery-alert-variant-outline::before {
  content: "\f10cd";
}

.mdi-battery-bluetooth::before {
  content: "\f0948";
}

.mdi-battery-bluetooth-variant::before {
  content: "\f0949";
}

.mdi-battery-charging::before {
  content: "\f0084";
}

.mdi-battery-charging-10::before {
  content: "\f089c";
}

.mdi-battery-charging-100::before {
  content: "\f0085";
}

.mdi-battery-charging-20::before {
  content: "\f0086";
}

.mdi-battery-charging-30::before {
  content: "\f0087";
}

.mdi-battery-charging-40::before {
  content: "\f0088";
}

.mdi-battery-charging-50::before {
  content: "\f089d";
}

.mdi-battery-charging-60::before {
  content: "\f0089";
}

.mdi-battery-charging-70::before {
  content: "\f089e";
}

.mdi-battery-charging-80::before {
  content: "\f008a";
}

.mdi-battery-charging-90::before {
  content: "\f008b";
}

.mdi-battery-charging-high::before {
  content: "\f12a6";
}

.mdi-battery-charging-low::before {
  content: "\f12a4";
}

.mdi-battery-charging-medium::before {
  content: "\f12a5";
}

.mdi-battery-charging-outline::before {
  content: "\f089f";
}

.mdi-battery-charging-wireless::before {
  content: "\f0807";
}

.mdi-battery-charging-wireless-10::before {
  content: "\f0808";
}

.mdi-battery-charging-wireless-20::before {
  content: "\f0809";
}

.mdi-battery-charging-wireless-30::before {
  content: "\f080a";
}

.mdi-battery-charging-wireless-40::before {
  content: "\f080b";
}

.mdi-battery-charging-wireless-50::before {
  content: "\f080c";
}

.mdi-battery-charging-wireless-60::before {
  content: "\f080d";
}

.mdi-battery-charging-wireless-70::before {
  content: "\f080e";
}

.mdi-battery-charging-wireless-80::before {
  content: "\f080f";
}

.mdi-battery-charging-wireless-90::before {
  content: "\f0810";
}

.mdi-battery-charging-wireless-alert::before {
  content: "\f0811";
}

.mdi-battery-charging-wireless-outline::before {
  content: "\f0812";
}

.mdi-battery-heart::before {
  content: "\f120f";
}

.mdi-battery-heart-outline::before {
  content: "\f1210";
}

.mdi-battery-heart-variant::before {
  content: "\f1211";
}

.mdi-battery-high::before {
  content: "\f12a3";
}

.mdi-battery-low::before {
  content: "\f12a1";
}

.mdi-battery-medium::before {
  content: "\f12a2";
}

.mdi-battery-minus::before {
  content: "\f008c";
}

.mdi-battery-negative::before {
  content: "\f008d";
}

.mdi-battery-off::before {
  content: "\f125d";
}

.mdi-battery-off-outline::before {
  content: "\f125e";
}

.mdi-battery-outline::before {
  content: "\f008e";
}

.mdi-battery-plus::before {
  content: "\f008f";
}

.mdi-battery-positive::before {
  content: "\f0090";
}

.mdi-battery-unknown::before {
  content: "\f0091";
}

.mdi-battery-unknown-bluetooth::before {
  content: "\f094a";
}

.mdi-battlenet::before {
  content: "\f0b60";
}

.mdi-beach::before {
  content: "\f0092";
}

.mdi-beaker::before {
  content: "\f0cea";
}

.mdi-beaker-alert::before {
  content: "\f1229";
}

.mdi-beaker-alert-outline::before {
  content: "\f122a";
}

.mdi-beaker-check::before {
  content: "\f122b";
}

.mdi-beaker-check-outline::before {
  content: "\f122c";
}

.mdi-beaker-minus::before {
  content: "\f122d";
}

.mdi-beaker-minus-outline::before {
  content: "\f122e";
}

.mdi-beaker-outline::before {
  content: "\f0690";
}

.mdi-beaker-plus::before {
  content: "\f122f";
}

.mdi-beaker-plus-outline::before {
  content: "\f1230";
}

.mdi-beaker-question::before {
  content: "\f1231";
}

.mdi-beaker-question-outline::before {
  content: "\f1232";
}

.mdi-beaker-remove::before {
  content: "\f1233";
}

.mdi-beaker-remove-outline::before {
  content: "\f1234";
}

.mdi-bed::before {
  content: "\f02e3";
}

.mdi-bed-double::before {
  content: "\f0fd4";
}

.mdi-bed-double-outline::before {
  content: "\f0fd3";
}

.mdi-bed-empty::before {
  content: "\f08a0";
}

.mdi-bed-king::before {
  content: "\f0fd2";
}

.mdi-bed-king-outline::before {
  content: "\f0fd1";
}

.mdi-bed-outline::before {
  content: "\f0099";
}

.mdi-bed-queen::before {
  content: "\f0fd0";
}

.mdi-bed-queen-outline::before {
  content: "\f0fdb";
}

.mdi-bed-single::before {
  content: "\f106d";
}

.mdi-bed-single-outline::before {
  content: "\f106e";
}

.mdi-bee::before {
  content: "\f0fa1";
}

.mdi-bee-flower::before {
  content: "\f0fa2";
}

.mdi-beehive-off-outline::before {
  content: "\f13ed";
}

.mdi-beehive-outline::before {
  content: "\f10ce";
}

.mdi-beekeeper::before {
  content: "\f14e2";
}

.mdi-beer::before {
  content: "\f0098";
}

.mdi-beer-outline::before {
  content: "\f130c";
}

.mdi-bell::before {
  content: "\f009a";
}

.mdi-bell-alert::before {
  content: "\f0d59";
}

.mdi-bell-alert-outline::before {
  content: "\f0e81";
}

.mdi-bell-cancel::before {
  content: "\f13e7";
}

.mdi-bell-cancel-outline::before {
  content: "\f13e8";
}

.mdi-bell-check::before {
  content: "\f11e5";
}

.mdi-bell-check-outline::before {
  content: "\f11e6";
}

.mdi-bell-circle::before {
  content: "\f0d5a";
}

.mdi-bell-circle-outline::before {
  content: "\f0d5b";
}

.mdi-bell-minus::before {
  content: "\f13e9";
}

.mdi-bell-minus-outline::before {
  content: "\f13ea";
}

.mdi-bell-off::before {
  content: "\f009b";
}

.mdi-bell-off-outline::before {
  content: "\f0a91";
}

.mdi-bell-outline::before {
  content: "\f009c";
}

.mdi-bell-plus::before {
  content: "\f009d";
}

.mdi-bell-plus-outline::before {
  content: "\f0a92";
}

.mdi-bell-remove::before {
  content: "\f13eb";
}

.mdi-bell-remove-outline::before {
  content: "\f13ec";
}

.mdi-bell-ring::before {
  content: "\f009e";
}

.mdi-bell-ring-outline::before {
  content: "\f009f";
}

.mdi-bell-sleep::before {
  content: "\f00a0";
}

.mdi-bell-sleep-outline::before {
  content: "\f0a93";
}

.mdi-beta::before {
  content: "\f00a1";
}

.mdi-betamax::before {
  content: "\f09cb";
}

.mdi-biathlon::before {
  content: "\f0e14";
}

.mdi-bicycle::before {
  content: "\f109c";
}

.mdi-bicycle-basket::before {
  content: "\f1235";
}

.mdi-bicycle-electric::before {
  content: "\f15b4";
}

.mdi-bicycle-penny-farthing::before {
  content: "\f15e9";
}

.mdi-bike::before {
  content: "\f00a3";
}

.mdi-bike-fast::before {
  content: "\f111f";
}

.mdi-billboard::before {
  content: "\f1010";
}

.mdi-billiards::before {
  content: "\f0b61";
}

.mdi-billiards-rack::before {
  content: "\f0b62";
}

.mdi-binoculars::before {
  content: "\f00a5";
}

.mdi-bio::before {
  content: "\f00a6";
}

.mdi-biohazard::before {
  content: "\f00a7";
}

.mdi-bird::before {
  content: "\f15c6";
}

.mdi-bitbucket::before {
  content: "\f00a8";
}

.mdi-bitcoin::before {
  content: "\f0813";
}

.mdi-black-mesa::before {
  content: "\f00a9";
}

.mdi-blender::before {
  content: "\f0ceb";
}

.mdi-blender-software::before {
  content: "\f00ab";
}

.mdi-blinds::before {
  content: "\f00ac";
}

.mdi-blinds-open::before {
  content: "\f1011";
}

.mdi-block-helper::before {
  content: "\f00ad";
}

.mdi-blogger::before {
  content: "\f00ae";
}

.mdi-blood-bag::before {
  content: "\f0cec";
}

.mdi-bluetooth::before {
  content: "\f00af";
}

.mdi-bluetooth-audio::before {
  content: "\f00b0";
}

.mdi-bluetooth-connect::before {
  content: "\f00b1";
}

.mdi-bluetooth-off::before {
  content: "\f00b2";
}

.mdi-bluetooth-settings::before {
  content: "\f00b3";
}

.mdi-bluetooth-transfer::before {
  content: "\f00b4";
}

.mdi-blur::before {
  content: "\f00b5";
}

.mdi-blur-linear::before {
  content: "\f00b6";
}

.mdi-blur-off::before {
  content: "\f00b7";
}

.mdi-blur-radial::before {
  content: "\f00b8";
}

.mdi-bolnisi-cross::before {
  content: "\f0ced";
}

.mdi-bolt::before {
  content: "\f0db3";
}

.mdi-bomb::before {
  content: "\f0691";
}

.mdi-bomb-off::before {
  content: "\f06c5";
}

.mdi-bone::before {
  content: "\f00b9";
}

.mdi-book::before {
  content: "\f00ba";
}

.mdi-book-account::before {
  content: "\f13ad";
}

.mdi-book-account-outline::before {
  content: "\f13ae";
}

.mdi-book-alert::before {
  content: "\f167c";
}

.mdi-book-alert-outline::before {
  content: "\f167d";
}

.mdi-book-alphabet::before {
  content: "\f061d";
}

.mdi-book-arrow-down::before {
  content: "\f167e";
}

.mdi-book-arrow-down-outline::before {
  content: "\f167f";
}

.mdi-book-arrow-left::before {
  content: "\f1680";
}

.mdi-book-arrow-left-outline::before {
  content: "\f1681";
}

.mdi-book-arrow-right::before {
  content: "\f1682";
}

.mdi-book-arrow-right-outline::before {
  content: "\f1683";
}

.mdi-book-arrow-up::before {
  content: "\f1684";
}

.mdi-book-arrow-up-outline::before {
  content: "\f1685";
}

.mdi-book-cancel::before {
  content: "\f1686";
}

.mdi-book-cancel-outline::before {
  content: "\f1687";
}

.mdi-book-check::before {
  content: "\f14f3";
}

.mdi-book-check-outline::before {
  content: "\f14f4";
}

.mdi-book-clock::before {
  content: "\f1688";
}

.mdi-book-clock-outline::before {
  content: "\f1689";
}

.mdi-book-cog::before {
  content: "\f168a";
}

.mdi-book-cog-outline::before {
  content: "\f168b";
}

.mdi-book-cross::before {
  content: "\f00a2";
}

.mdi-book-edit::before {
  content: "\f168c";
}

.mdi-book-edit-outline::before {
  content: "\f168d";
}

.mdi-book-education::before {
  content: "\f16c9";
}

.mdi-book-education-outline::before {
  content: "\f16ca";
}

.mdi-book-information-variant::before {
  content: "\f106f";
}

.mdi-book-lock::before {
  content: "\f079a";
}

.mdi-book-lock-open::before {
  content: "\f079b";
}

.mdi-book-lock-open-outline::before {
  content: "\f168e";
}

.mdi-book-lock-outline::before {
  content: "\f168f";
}

.mdi-book-marker::before {
  content: "\f1690";
}

.mdi-book-marker-outline::before {
  content: "\f1691";
}

.mdi-book-minus::before {
  content: "\f05d9";
}

.mdi-book-minus-multiple::before {
  content: "\f0a94";
}

.mdi-book-minus-multiple-outline::before {
  content: "\f090b";
}

.mdi-book-minus-outline::before {
  content: "\f1692";
}

.mdi-book-multiple::before {
  content: "\f00bb";
}

.mdi-book-multiple-outline::before {
  content: "\f0436";
}

.mdi-book-music::before {
  content: "\f0067";
}

.mdi-book-music-outline::before {
  content: "\f1693";
}

.mdi-book-off::before {
  content: "\f1694";
}

.mdi-book-off-outline::before {
  content: "\f1695";
}

.mdi-book-open::before {
  content: "\f00bd";
}

.mdi-book-open-blank-variant::before {
  content: "\f00be";
}

.mdi-book-open-outline::before {
  content: "\f0b63";
}

.mdi-book-open-page-variant::before {
  content: "\f05da";
}

.mdi-book-open-page-variant-outline::before {
  content: "\f15d6";
}

.mdi-book-open-variant::before {
  content: "\f14f7";
}

.mdi-book-outline::before {
  content: "\f0b64";
}

.mdi-book-play::before {
  content: "\f0e82";
}

.mdi-book-play-outline::before {
  content: "\f0e83";
}

.mdi-book-plus::before {
  content: "\f05db";
}

.mdi-book-plus-multiple::before {
  content: "\f0a95";
}

.mdi-book-plus-multiple-outline::before {
  content: "\f0ade";
}

.mdi-book-plus-outline::before {
  content: "\f1696";
}

.mdi-book-refresh::before {
  content: "\f1697";
}

.mdi-book-refresh-outline::before {
  content: "\f1698";
}

.mdi-book-remove::before {
  content: "\f0a97";
}

.mdi-book-remove-multiple::before {
  content: "\f0a96";
}

.mdi-book-remove-multiple-outline::before {
  content: "\f04ca";
}

.mdi-book-remove-outline::before {
  content: "\f1699";
}

.mdi-book-search::before {
  content: "\f0e84";
}

.mdi-book-search-outline::before {
  content: "\f0e85";
}

.mdi-book-settings::before {
  content: "\f169a";
}

.mdi-book-settings-outline::before {
  content: "\f169b";
}

.mdi-book-sync::before {
  content: "\f169c";
}

.mdi-book-sync-outline::before {
  content: "\f16c8";
}

.mdi-book-variant::before {
  content: "\f00bf";
}

.mdi-book-variant-multiple::before {
  content: "\f00bc";
}

.mdi-bookmark::before {
  content: "\f00c0";
}

.mdi-bookmark-check::before {
  content: "\f00c1";
}

.mdi-bookmark-check-outline::before {
  content: "\f137b";
}

.mdi-bookmark-minus::before {
  content: "\f09cc";
}

.mdi-bookmark-minus-outline::before {
  content: "\f09cd";
}

.mdi-bookmark-multiple::before {
  content: "\f0e15";
}

.mdi-bookmark-multiple-outline::before {
  content: "\f0e16";
}

.mdi-bookmark-music::before {
  content: "\f00c2";
}

.mdi-bookmark-music-outline::before {
  content: "\f1379";
}

.mdi-bookmark-off::before {
  content: "\f09ce";
}

.mdi-bookmark-off-outline::before {
  content: "\f09cf";
}

.mdi-bookmark-outline::before {
  content: "\f00c3";
}

.mdi-bookmark-plus::before {
  content: "\f00c5";
}

.mdi-bookmark-plus-outline::before {
  content: "\f00c4";
}

.mdi-bookmark-remove::before {
  content: "\f00c6";
}

.mdi-bookmark-remove-outline::before {
  content: "\f137a";
}

.mdi-bookshelf::before {
  content: "\f125f";
}

.mdi-boom-gate::before {
  content: "\f0e86";
}

.mdi-boom-gate-alert::before {
  content: "\f0e87";
}

.mdi-boom-gate-alert-outline::before {
  content: "\f0e88";
}

.mdi-boom-gate-down::before {
  content: "\f0e89";
}

.mdi-boom-gate-down-outline::before {
  content: "\f0e8a";
}

.mdi-boom-gate-outline::before {
  content: "\f0e8b";
}

.mdi-boom-gate-up::before {
  content: "\f0e8c";
}

.mdi-boom-gate-up-outline::before {
  content: "\f0e8d";
}

.mdi-boombox::before {
  content: "\f05dc";
}

.mdi-boomerang::before {
  content: "\f10cf";
}

.mdi-bootstrap::before {
  content: "\f06c6";
}

.mdi-border-all::before {
  content: "\f00c7";
}

.mdi-border-all-variant::before {
  content: "\f08a1";
}

.mdi-border-bottom::before {
  content: "\f00c8";
}

.mdi-border-bottom-variant::before {
  content: "\f08a2";
}

.mdi-border-color::before {
  content: "\f00c9";
}

.mdi-border-horizontal::before {
  content: "\f00ca";
}

.mdi-border-inside::before {
  content: "\f00cb";
}

.mdi-border-left::before {
  content: "\f00cc";
}

.mdi-border-left-variant::before {
  content: "\f08a3";
}

.mdi-border-none::before {
  content: "\f00cd";
}

.mdi-border-none-variant::before {
  content: "\f08a4";
}

.mdi-border-outside::before {
  content: "\f00ce";
}

.mdi-border-right::before {
  content: "\f00cf";
}

.mdi-border-right-variant::before {
  content: "\f08a5";
}

.mdi-border-style::before {
  content: "\f00d0";
}

.mdi-border-top::before {
  content: "\f00d1";
}

.mdi-border-top-variant::before {
  content: "\f08a6";
}

.mdi-border-vertical::before {
  content: "\f00d2";
}

.mdi-bottle-soda::before {
  content: "\f1070";
}

.mdi-bottle-soda-classic::before {
  content: "\f1071";
}

.mdi-bottle-soda-classic-outline::before {
  content: "\f1363";
}

.mdi-bottle-soda-outline::before {
  content: "\f1072";
}

.mdi-bottle-tonic::before {
  content: "\f112e";
}

.mdi-bottle-tonic-outline::before {
  content: "\f112f";
}

.mdi-bottle-tonic-plus::before {
  content: "\f1130";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "\f1131";
}

.mdi-bottle-tonic-skull::before {
  content: "\f1132";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "\f1133";
}

.mdi-bottle-wine::before {
  content: "\f0854";
}

.mdi-bottle-wine-outline::before {
  content: "\f1310";
}

.mdi-bow-tie::before {
  content: "\f0678";
}

.mdi-bowl::before {
  content: "\f028e";
}

.mdi-bowl-mix::before {
  content: "\f0617";
}

.mdi-bowl-mix-outline::before {
  content: "\f02e4";
}

.mdi-bowl-outline::before {
  content: "\f02a9";
}

.mdi-bowling::before {
  content: "\f00d3";
}

.mdi-box::before {
  content: "\f00d4";
}

.mdi-box-cutter::before {
  content: "\f00d5";
}

.mdi-box-cutter-off::before {
  content: "\f0b4a";
}

.mdi-box-shadow::before {
  content: "\f0637";
}

.mdi-boxing-glove::before {
  content: "\f0b65";
}

.mdi-braille::before {
  content: "\f09d0";
}

.mdi-brain::before {
  content: "\f09d1";
}

.mdi-bread-slice::before {
  content: "\f0cee";
}

.mdi-bread-slice-outline::before {
  content: "\f0cef";
}

.mdi-bridge::before {
  content: "\f0618";
}

.mdi-briefcase::before {
  content: "\f00d6";
}

.mdi-briefcase-account::before {
  content: "\f0cf0";
}

.mdi-briefcase-account-outline::before {
  content: "\f0cf1";
}

.mdi-briefcase-check::before {
  content: "\f00d7";
}

.mdi-briefcase-check-outline::before {
  content: "\f131e";
}

.mdi-briefcase-clock::before {
  content: "\f10d0";
}

.mdi-briefcase-clock-outline::before {
  content: "\f10d1";
}

.mdi-briefcase-download::before {
  content: "\f00d8";
}

.mdi-briefcase-download-outline::before {
  content: "\f0c3d";
}

.mdi-briefcase-edit::before {
  content: "\f0a98";
}

.mdi-briefcase-edit-outline::before {
  content: "\f0c3e";
}

.mdi-briefcase-minus::before {
  content: "\f0a2a";
}

.mdi-briefcase-minus-outline::before {
  content: "\f0c3f";
}

.mdi-briefcase-off::before {
  content: "\f1658";
}

.mdi-briefcase-off-outline::before {
  content: "\f1659";
}

.mdi-briefcase-outline::before {
  content: "\f0814";
}

.mdi-briefcase-plus::before {
  content: "\f0a2b";
}

.mdi-briefcase-plus-outline::before {
  content: "\f0c40";
}

.mdi-briefcase-remove::before {
  content: "\f0a2c";
}

.mdi-briefcase-remove-outline::before {
  content: "\f0c41";
}

.mdi-briefcase-search::before {
  content: "\f0a2d";
}

.mdi-briefcase-search-outline::before {
  content: "\f0c42";
}

.mdi-briefcase-upload::before {
  content: "\f00d9";
}

.mdi-briefcase-upload-outline::before {
  content: "\f0c43";
}

.mdi-briefcase-variant::before {
  content: "\f1494";
}

.mdi-briefcase-variant-off::before {
  content: "\f165a";
}

.mdi-briefcase-variant-off-outline::before {
  content: "\f165b";
}

.mdi-briefcase-variant-outline::before {
  content: "\f1495";
}

.mdi-brightness-1::before {
  content: "\f00da";
}

.mdi-brightness-2::before {
  content: "\f00db";
}

.mdi-brightness-3::before {
  content: "\f00dc";
}

.mdi-brightness-4::before {
  content: "\f00dd";
}

.mdi-brightness-5::before {
  content: "\f00de";
}

.mdi-brightness-6::before {
  content: "\f00df";
}

.mdi-brightness-7::before {
  content: "\f00e0";
}

.mdi-brightness-auto::before {
  content: "\f00e1";
}

.mdi-brightness-percent::before {
  content: "\f0cf2";
}

.mdi-broom::before {
  content: "\f00e2";
}

.mdi-brush::before {
  content: "\f00e3";
}

.mdi-bucket::before {
  content: "\f1415";
}

.mdi-bucket-outline::before {
  content: "\f1416";
}

.mdi-buddhism::before {
  content: "\f094b";
}

.mdi-buffer::before {
  content: "\f0619";
}

.mdi-buffet::before {
  content: "\f0578";
}

.mdi-bug::before {
  content: "\f00e4";
}

.mdi-bug-check::before {
  content: "\f0a2e";
}

.mdi-bug-check-outline::before {
  content: "\f0a2f";
}

.mdi-bug-outline::before {
  content: "\f0a30";
}

.mdi-bugle::before {
  content: "\f0db4";
}

.mdi-bulldozer::before {
  content: "\f0b22";
}

.mdi-bullet::before {
  content: "\f0cf3";
}

.mdi-bulletin-board::before {
  content: "\f00e5";
}

.mdi-bullhorn::before {
  content: "\f00e6";
}

.mdi-bullhorn-outline::before {
  content: "\f0b23";
}

.mdi-bullseye::before {
  content: "\f05dd";
}

.mdi-bullseye-arrow::before {
  content: "\f08c9";
}

.mdi-bulma::before {
  content: "\f12e7";
}

.mdi-bunk-bed::before {
  content: "\f1302";
}

.mdi-bunk-bed-outline::before {
  content: "\f0097";
}

.mdi-bus::before {
  content: "\f00e7";
}

.mdi-bus-alert::before {
  content: "\f0a99";
}

.mdi-bus-articulated-end::before {
  content: "\f079c";
}

.mdi-bus-articulated-front::before {
  content: "\f079d";
}

.mdi-bus-clock::before {
  content: "\f08ca";
}

.mdi-bus-double-decker::before {
  content: "\f079e";
}

.mdi-bus-marker::before {
  content: "\f1212";
}

.mdi-bus-multiple::before {
  content: "\f0f3f";
}

.mdi-bus-school::before {
  content: "\f079f";
}

.mdi-bus-side::before {
  content: "\f07a0";
}

.mdi-bus-stop::before {
  content: "\f1012";
}

.mdi-bus-stop-covered::before {
  content: "\f1013";
}

.mdi-bus-stop-uncovered::before {
  content: "\f1014";
}

.mdi-butterfly::before {
  content: "\f1589";
}

.mdi-butterfly-outline::before {
  content: "\f158a";
}

.mdi-cable-data::before {
  content: "\f1394";
}

.mdi-cached::before {
  content: "\f00e8";
}

.mdi-cactus::before {
  content: "\f0db5";
}

.mdi-cake::before {
  content: "\f00e9";
}

.mdi-cake-layered::before {
  content: "\f00ea";
}

.mdi-cake-variant::before {
  content: "\f00eb";
}

.mdi-calculator::before {
  content: "\f00ec";
}

.mdi-calculator-variant::before {
  content: "\f0a9a";
}

.mdi-calculator-variant-outline::before {
  content: "\f15a6";
}

.mdi-calendar::before {
  content: "\f00ed";
}

.mdi-calendar-account::before {
  content: "\f0ed7";
}

.mdi-calendar-account-outline::before {
  content: "\f0ed8";
}

.mdi-calendar-alert::before {
  content: "\f0a31";
}

.mdi-calendar-arrow-left::before {
  content: "\f1134";
}

.mdi-calendar-arrow-right::before {
  content: "\f1135";
}

.mdi-calendar-blank::before {
  content: "\f00ee";
}

.mdi-calendar-blank-multiple::before {
  content: "\f1073";
}

.mdi-calendar-blank-outline::before {
  content: "\f0b66";
}

.mdi-calendar-check::before {
  content: "\f00ef";
}

.mdi-calendar-check-outline::before {
  content: "\f0c44";
}

.mdi-calendar-clock::before {
  content: "\f00f0";
}

.mdi-calendar-cursor::before {
  content: "\f157b";
}

.mdi-calendar-edit::before {
  content: "\f08a7";
}

.mdi-calendar-end::before {
  content: "\f166c";
}

.mdi-calendar-export::before {
  content: "\f0b24";
}

.mdi-calendar-heart::before {
  content: "\f09d2";
}

.mdi-calendar-import::before {
  content: "\f0b25";
}

.mdi-calendar-lock::before {
  content: "\f1641";
}

.mdi-calendar-lock-outline::before {
  content: "\f1642";
}

.mdi-calendar-minus::before {
  content: "\f0d5c";
}

.mdi-calendar-month::before {
  content: "\f0e17";
}

.mdi-calendar-month-outline::before {
  content: "\f0e18";
}

.mdi-calendar-multiple::before {
  content: "\f00f1";
}

.mdi-calendar-multiple-check::before {
  content: "\f00f2";
}

.mdi-calendar-multiselect::before {
  content: "\f0a32";
}

.mdi-calendar-outline::before {
  content: "\f0b67";
}

.mdi-calendar-plus::before {
  content: "\f00f3";
}

.mdi-calendar-question::before {
  content: "\f0692";
}

.mdi-calendar-range::before {
  content: "\f0679";
}

.mdi-calendar-range-outline::before {
  content: "\f0b68";
}

.mdi-calendar-refresh::before {
  content: "\f01e1";
}

.mdi-calendar-refresh-outline::before {
  content: "\f0203";
}

.mdi-calendar-remove::before {
  content: "\f00f4";
}

.mdi-calendar-remove-outline::before {
  content: "\f0c45";
}

.mdi-calendar-search::before {
  content: "\f094c";
}

.mdi-calendar-star::before {
  content: "\f09d3";
}

.mdi-calendar-start::before {
  content: "\f166d";
}

.mdi-calendar-sync::before {
  content: "\f0e8e";
}

.mdi-calendar-sync-outline::before {
  content: "\f0e8f";
}

.mdi-calendar-text::before {
  content: "\f00f5";
}

.mdi-calendar-text-outline::before {
  content: "\f0c46";
}

.mdi-calendar-today::before {
  content: "\f00f6";
}

.mdi-calendar-week::before {
  content: "\f0a33";
}

.mdi-calendar-week-begin::before {
  content: "\f0a34";
}

.mdi-calendar-weekend::before {
  content: "\f0ed9";
}

.mdi-calendar-weekend-outline::before {
  content: "\f0eda";
}

.mdi-call-made::before {
  content: "\f00f7";
}

.mdi-call-merge::before {
  content: "\f00f8";
}

.mdi-call-missed::before {
  content: "\f00f9";
}

.mdi-call-received::before {
  content: "\f00fa";
}

.mdi-call-split::before {
  content: "\f00fb";
}

.mdi-camcorder::before {
  content: "\f00fc";
}

.mdi-camcorder-off::before {
  content: "\f00ff";
}

.mdi-camera::before {
  content: "\f0100";
}

.mdi-camera-account::before {
  content: "\f08cb";
}

.mdi-camera-burst::before {
  content: "\f0693";
}

.mdi-camera-control::before {
  content: "\f0b69";
}

.mdi-camera-enhance::before {
  content: "\f0101";
}

.mdi-camera-enhance-outline::before {
  content: "\f0b6a";
}

.mdi-camera-flip::before {
  content: "\f15d9";
}

.mdi-camera-flip-outline::before {
  content: "\f15da";
}

.mdi-camera-front::before {
  content: "\f0102";
}

.mdi-camera-front-variant::before {
  content: "\f0103";
}

.mdi-camera-gopro::before {
  content: "\f07a1";
}

.mdi-camera-image::before {
  content: "\f08cc";
}

.mdi-camera-iris::before {
  content: "\f0104";
}

.mdi-camera-metering-center::before {
  content: "\f07a2";
}

.mdi-camera-metering-matrix::before {
  content: "\f07a3";
}

.mdi-camera-metering-partial::before {
  content: "\f07a4";
}

.mdi-camera-metering-spot::before {
  content: "\f07a5";
}

.mdi-camera-off::before {
  content: "\f05df";
}

.mdi-camera-outline::before {
  content: "\f0d5d";
}

.mdi-camera-party-mode::before {
  content: "\f0105";
}

.mdi-camera-plus::before {
  content: "\f0edb";
}

.mdi-camera-plus-outline::before {
  content: "\f0edc";
}

.mdi-camera-rear::before {
  content: "\f0106";
}

.mdi-camera-rear-variant::before {
  content: "\f0107";
}

.mdi-camera-retake::before {
  content: "\f0e19";
}

.mdi-camera-retake-outline::before {
  content: "\f0e1a";
}

.mdi-camera-switch::before {
  content: "\f0108";
}

.mdi-camera-switch-outline::before {
  content: "\f084a";
}

.mdi-camera-timer::before {
  content: "\f0109";
}

.mdi-camera-wireless::before {
  content: "\f0db6";
}

.mdi-camera-wireless-outline::before {
  content: "\f0db7";
}

.mdi-campfire::before {
  content: "\f0edd";
}

.mdi-cancel::before {
  content: "\f073a";
}

.mdi-candle::before {
  content: "\f05e2";
}

.mdi-candycane::before {
  content: "\f010a";
}

.mdi-cannabis::before {
  content: "\f07a6";
}

.mdi-cannabis-off::before {
  content: "\f166e";
}

.mdi-caps-lock::before {
  content: "\f0a9b";
}

.mdi-car::before {
  content: "\f010b";
}

.mdi-car-2-plus::before {
  content: "\f1015";
}

.mdi-car-3-plus::before {
  content: "\f1016";
}

.mdi-car-arrow-left::before {
  content: "\f13b2";
}

.mdi-car-arrow-right::before {
  content: "\f13b3";
}

.mdi-car-back::before {
  content: "\f0e1b";
}

.mdi-car-battery::before {
  content: "\f010c";
}

.mdi-car-brake-abs::before {
  content: "\f0c47";
}

.mdi-car-brake-alert::before {
  content: "\f0c48";
}

.mdi-car-brake-hold::before {
  content: "\f0d5e";
}

.mdi-car-brake-parking::before {
  content: "\f0d5f";
}

.mdi-car-brake-retarder::before {
  content: "\f1017";
}

.mdi-car-child-seat::before {
  content: "\f0fa3";
}

.mdi-car-clutch::before {
  content: "\f1018";
}

.mdi-car-cog::before {
  content: "\f13cc";
}

.mdi-car-connected::before {
  content: "\f010d";
}

.mdi-car-convertible::before {
  content: "\f07a7";
}

.mdi-car-coolant-level::before {
  content: "\f1019";
}

.mdi-car-cruise-control::before {
  content: "\f0d60";
}

.mdi-car-defrost-front::before {
  content: "\f0d61";
}

.mdi-car-defrost-rear::before {
  content: "\f0d62";
}

.mdi-car-door::before {
  content: "\f0b6b";
}

.mdi-car-door-lock::before {
  content: "\f109d";
}

.mdi-car-electric::before {
  content: "\f0b6c";
}

.mdi-car-electric-outline::before {
  content: "\f15b5";
}

.mdi-car-emergency::before {
  content: "\f160f";
}

.mdi-car-esp::before {
  content: "\f0c49";
}

.mdi-car-estate::before {
  content: "\f07a8";
}

.mdi-car-hatchback::before {
  content: "\f07a9";
}

.mdi-car-info::before {
  content: "\f11be";
}

.mdi-car-key::before {
  content: "\f0b6d";
}

.mdi-car-lifted-pickup::before {
  content: "\f152d";
}

.mdi-car-light-dimmed::before {
  content: "\f0c4a";
}

.mdi-car-light-fog::before {
  content: "\f0c4b";
}

.mdi-car-light-high::before {
  content: "\f0c4c";
}

.mdi-car-limousine::before {
  content: "\f08cd";
}

.mdi-car-multiple::before {
  content: "\f0b6e";
}

.mdi-car-off::before {
  content: "\f0e1c";
}

.mdi-car-outline::before {
  content: "\f14ed";
}

.mdi-car-parking-lights::before {
  content: "\f0d63";
}

.mdi-car-pickup::before {
  content: "\f07aa";
}

.mdi-car-seat::before {
  content: "\f0fa4";
}

.mdi-car-seat-cooler::before {
  content: "\f0fa5";
}

.mdi-car-seat-heater::before {
  content: "\f0fa6";
}

.mdi-car-settings::before {
  content: "\f13cd";
}

.mdi-car-shift-pattern::before {
  content: "\f0f40";
}

.mdi-car-side::before {
  content: "\f07ab";
}

.mdi-car-sports::before {
  content: "\f07ac";
}

.mdi-car-tire-alert::before {
  content: "\f0c4d";
}

.mdi-car-traction-control::before {
  content: "\f0d64";
}

.mdi-car-turbocharger::before {
  content: "\f101a";
}

.mdi-car-wash::before {
  content: "\f010e";
}

.mdi-car-windshield::before {
  content: "\f101b";
}

.mdi-car-windshield-outline::before {
  content: "\f101c";
}

.mdi-carabiner::before {
  content: "\f14c0";
}

.mdi-caravan::before {
  content: "\f07ad";
}

.mdi-card::before {
  content: "\f0b6f";
}

.mdi-card-account-details::before {
  content: "\f05d2";
}

.mdi-card-account-details-outline::before {
  content: "\f0dab";
}

.mdi-card-account-details-star::before {
  content: "\f02a3";
}

.mdi-card-account-details-star-outline::before {
  content: "\f06db";
}

.mdi-card-account-mail::before {
  content: "\f018e";
}

.mdi-card-account-mail-outline::before {
  content: "\f0e98";
}

.mdi-card-account-phone::before {
  content: "\f0e99";
}

.mdi-card-account-phone-outline::before {
  content: "\f0e9a";
}

.mdi-card-bulleted::before {
  content: "\f0b70";
}

.mdi-card-bulleted-off::before {
  content: "\f0b71";
}

.mdi-card-bulleted-off-outline::before {
  content: "\f0b72";
}

.mdi-card-bulleted-outline::before {
  content: "\f0b73";
}

.mdi-card-bulleted-settings::before {
  content: "\f0b74";
}

.mdi-card-bulleted-settings-outline::before {
  content: "\f0b75";
}

.mdi-card-minus::before {
  content: "\f1600";
}

.mdi-card-minus-outline::before {
  content: "\f1601";
}

.mdi-card-off::before {
  content: "\f1602";
}

.mdi-card-off-outline::before {
  content: "\f1603";
}

.mdi-card-outline::before {
  content: "\f0b76";
}

.mdi-card-plus::before {
  content: "\f11ff";
}

.mdi-card-plus-outline::before {
  content: "\f1200";
}

.mdi-card-remove::before {
  content: "\f1604";
}

.mdi-card-remove-outline::before {
  content: "\f1605";
}

.mdi-card-search::before {
  content: "\f1074";
}

.mdi-card-search-outline::before {
  content: "\f1075";
}

.mdi-card-text::before {
  content: "\f0b77";
}

.mdi-card-text-outline::before {
  content: "\f0b78";
}

.mdi-cards::before {
  content: "\f0638";
}

.mdi-cards-club::before {
  content: "\f08ce";
}

.mdi-cards-diamond::before {
  content: "\f08cf";
}

.mdi-cards-diamond-outline::before {
  content: "\f101d";
}

.mdi-cards-heart::before {
  content: "\f08d0";
}

.mdi-cards-outline::before {
  content: "\f0639";
}

.mdi-cards-playing-outline::before {
  content: "\f063a";
}

.mdi-cards-spade::before {
  content: "\f08d1";
}

.mdi-cards-variant::before {
  content: "\f06c7";
}

.mdi-carrot::before {
  content: "\f010f";
}

.mdi-cart::before {
  content: "\f0110";
}

.mdi-cart-arrow-down::before {
  content: "\f0d66";
}

.mdi-cart-arrow-right::before {
  content: "\f0c4e";
}

.mdi-cart-arrow-up::before {
  content: "\f0d67";
}

.mdi-cart-check::before {
  content: "\f15ea";
}

.mdi-cart-minus::before {
  content: "\f0d68";
}

.mdi-cart-off::before {
  content: "\f066b";
}

.mdi-cart-outline::before {
  content: "\f0111";
}

.mdi-cart-plus::before {
  content: "\f0112";
}

.mdi-cart-remove::before {
  content: "\f0d69";
}

.mdi-cart-variant::before {
  content: "\f15eb";
}

.mdi-case-sensitive-alt::before {
  content: "\f0113";
}

.mdi-cash::before {
  content: "\f0114";
}

.mdi-cash-100::before {
  content: "\f0115";
}

.mdi-cash-check::before {
  content: "\f14ee";
}

.mdi-cash-lock::before {
  content: "\f14ea";
}

.mdi-cash-lock-open::before {
  content: "\f14eb";
}

.mdi-cash-marker::before {
  content: "\f0db8";
}

.mdi-cash-minus::before {
  content: "\f1260";
}

.mdi-cash-multiple::before {
  content: "\f0116";
}

.mdi-cash-plus::before {
  content: "\f1261";
}

.mdi-cash-refund::before {
  content: "\f0a9c";
}

.mdi-cash-register::before {
  content: "\f0cf4";
}

.mdi-cash-remove::before {
  content: "\f1262";
}

.mdi-cash-usd::before {
  content: "\f1176";
}

.mdi-cash-usd-outline::before {
  content: "\f0117";
}

.mdi-cassette::before {
  content: "\f09d4";
}

.mdi-cast::before {
  content: "\f0118";
}

.mdi-cast-audio::before {
  content: "\f101e";
}

.mdi-cast-connected::before {
  content: "\f0119";
}

.mdi-cast-education::before {
  content: "\f0e1d";
}

.mdi-cast-off::before {
  content: "\f078a";
}

.mdi-castle::before {
  content: "\f011a";
}

.mdi-cat::before {
  content: "\f011b";
}

.mdi-cctv::before {
  content: "\f07ae";
}

.mdi-ceiling-light::before {
  content: "\f0769";
}

.mdi-cellphone::before {
  content: "\f011c";
}

.mdi-cellphone-android::before {
  content: "\f011d";
}

.mdi-cellphone-arrow-down::before {
  content: "\f09d5";
}

.mdi-cellphone-basic::before {
  content: "\f011e";
}

.mdi-cellphone-charging::before {
  content: "\f1397";
}

.mdi-cellphone-cog::before {
  content: "\f0951";
}

.mdi-cellphone-dock::before {
  content: "\f011f";
}

.mdi-cellphone-erase::before {
  content: "\f094d";
}

.mdi-cellphone-information::before {
  content: "\f0f41";
}

.mdi-cellphone-iphone::before {
  content: "\f0120";
}

.mdi-cellphone-key::before {
  content: "\f094e";
}

.mdi-cellphone-link::before {
  content: "\f0121";
}

.mdi-cellphone-link-off::before {
  content: "\f0122";
}

.mdi-cellphone-lock::before {
  content: "\f094f";
}

.mdi-cellphone-message::before {
  content: "\f08d3";
}

.mdi-cellphone-message-off::before {
  content: "\f10d2";
}

.mdi-cellphone-nfc::before {
  content: "\f0e90";
}

.mdi-cellphone-nfc-off::before {
  content: "\f12d8";
}

.mdi-cellphone-off::before {
  content: "\f0950";
}

.mdi-cellphone-play::before {
  content: "\f101f";
}

.mdi-cellphone-screenshot::before {
  content: "\f0a35";
}

.mdi-cellphone-settings::before {
  content: "\f0123";
}

.mdi-cellphone-sound::before {
  content: "\f0952";
}

.mdi-cellphone-text::before {
  content: "\f08d2";
}

.mdi-cellphone-wireless::before {
  content: "\f0815";
}

.mdi-celtic-cross::before {
  content: "\f0cf5";
}

.mdi-centos::before {
  content: "\f111a";
}

.mdi-certificate::before {
  content: "\f0124";
}

.mdi-certificate-outline::before {
  content: "\f1188";
}

.mdi-chair-rolling::before {
  content: "\f0f48";
}

.mdi-chair-school::before {
  content: "\f0125";
}

.mdi-charity::before {
  content: "\f0c4f";
}

.mdi-chart-arc::before {
  content: "\f0126";
}

.mdi-chart-areaspline::before {
  content: "\f0127";
}

.mdi-chart-areaspline-variant::before {
  content: "\f0e91";
}

.mdi-chart-bar::before {
  content: "\f0128";
}

.mdi-chart-bar-stacked::before {
  content: "\f076a";
}

.mdi-chart-bell-curve::before {
  content: "\f0c50";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "\f0fa7";
}

.mdi-chart-box::before {
  content: "\f154d";
}

.mdi-chart-box-outline::before {
  content: "\f154e";
}

.mdi-chart-box-plus-outline::before {
  content: "\f154f";
}

.mdi-chart-bubble::before {
  content: "\f05e3";
}

.mdi-chart-donut::before {
  content: "\f07af";
}

.mdi-chart-donut-variant::before {
  content: "\f07b0";
}

.mdi-chart-gantt::before {
  content: "\f066c";
}

.mdi-chart-histogram::before {
  content: "\f0129";
}

.mdi-chart-line::before {
  content: "\f012a";
}

.mdi-chart-line-stacked::before {
  content: "\f076b";
}

.mdi-chart-line-variant::before {
  content: "\f07b1";
}

.mdi-chart-multiline::before {
  content: "\f08d4";
}

.mdi-chart-multiple::before {
  content: "\f1213";
}

.mdi-chart-pie::before {
  content: "\f012b";
}

.mdi-chart-ppf::before {
  content: "\f1380";
}

.mdi-chart-sankey::before {
  content: "\f11df";
}

.mdi-chart-sankey-variant::before {
  content: "\f11e0";
}

.mdi-chart-scatter-plot::before {
  content: "\f0e92";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "\f066d";
}

.mdi-chart-timeline::before {
  content: "\f066e";
}

.mdi-chart-timeline-variant::before {
  content: "\f0e93";
}

.mdi-chart-timeline-variant-shimmer::before {
  content: "\f15b6";
}

.mdi-chart-tree::before {
  content: "\f0e94";
}

.mdi-chat::before {
  content: "\f0b79";
}

.mdi-chat-alert::before {
  content: "\f0b7a";
}

.mdi-chat-alert-outline::before {
  content: "\f12c9";
}

.mdi-chat-minus::before {
  content: "\f1410";
}

.mdi-chat-minus-outline::before {
  content: "\f1413";
}

.mdi-chat-outline::before {
  content: "\f0ede";
}

.mdi-chat-plus::before {
  content: "\f140f";
}

.mdi-chat-plus-outline::before {
  content: "\f1412";
}

.mdi-chat-processing::before {
  content: "\f0b7b";
}

.mdi-chat-processing-outline::before {
  content: "\f12ca";
}

.mdi-chat-remove::before {
  content: "\f1411";
}

.mdi-chat-remove-outline::before {
  content: "\f1414";
}

.mdi-chat-sleep::before {
  content: "\f12d1";
}

.mdi-chat-sleep-outline::before {
  content: "\f12d2";
}

.mdi-check::before {
  content: "\f012c";
}

.mdi-check-all::before {
  content: "\f012d";
}

.mdi-check-bold::before {
  content: "\f0e1e";
}

.mdi-check-box-multiple-outline::before {
  content: "\f0c51";
}

.mdi-check-box-outline::before {
  content: "\f0c52";
}

.mdi-check-circle::before {
  content: "\f05e0";
}

.mdi-check-circle-outline::before {
  content: "\f05e1";
}

.mdi-check-decagram::before {
  content: "\f0791";
}

.mdi-check-network::before {
  content: "\f0c53";
}

.mdi-check-network-outline::before {
  content: "\f0c54";
}

.mdi-check-outline::before {
  content: "\f0855";
}

.mdi-check-underline::before {
  content: "\f0e1f";
}

.mdi-check-underline-circle::before {
  content: "\f0e20";
}

.mdi-check-underline-circle-outline::before {
  content: "\f0e21";
}

.mdi-checkbook::before {
  content: "\f0a9d";
}

.mdi-checkbox-blank::before {
  content: "\f012e";
}

.mdi-checkbox-blank-circle::before {
  content: "\f012f";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f0130";
}

.mdi-checkbox-blank-off::before {
  content: "\f12ec";
}

.mdi-checkbox-blank-off-outline::before {
  content: "\f12ed";
}

.mdi-checkbox-blank-outline::before {
  content: "\f0131";
}

.mdi-checkbox-intermediate::before {
  content: "\f0856";
}

.mdi-checkbox-marked::before {
  content: "\f0132";
}

.mdi-checkbox-marked-circle::before {
  content: "\f0133";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f0134";
}

.mdi-checkbox-marked-outline::before {
  content: "\f0135";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f0136";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "\f063b";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\f063c";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f0137";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f0138";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "\f063d";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\f063e";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f0139";
}

.mdi-checkerboard::before {
  content: "\f013a";
}

.mdi-checkerboard-minus::before {
  content: "\f1202";
}

.mdi-checkerboard-plus::before {
  content: "\f1201";
}

.mdi-checkerboard-remove::before {
  content: "\f1203";
}

.mdi-cheese::before {
  content: "\f12b9";
}

.mdi-cheese-off::before {
  content: "\f13ee";
}

.mdi-chef-hat::before {
  content: "\f0b7c";
}

.mdi-chemical-weapon::before {
  content: "\f013b";
}

.mdi-chess-bishop::before {
  content: "\f085c";
}

.mdi-chess-king::before {
  content: "\f0857";
}

.mdi-chess-knight::before {
  content: "\f0858";
}

.mdi-chess-pawn::before {
  content: "\f0859";
}

.mdi-chess-queen::before {
  content: "\f085a";
}

.mdi-chess-rook::before {
  content: "\f085b";
}

.mdi-chevron-double-down::before {
  content: "\f013c";
}

.mdi-chevron-double-left::before {
  content: "\f013d";
}

.mdi-chevron-double-right::before {
  content: "\f013e";
}

.mdi-chevron-double-up::before {
  content: "\f013f";
}

.mdi-chevron-down::before {
  content: "\f0140";
}

.mdi-chevron-down-box::before {
  content: "\f09d6";
}

.mdi-chevron-down-box-outline::before {
  content: "\f09d7";
}

.mdi-chevron-down-circle::before {
  content: "\f0b26";
}

.mdi-chevron-down-circle-outline::before {
  content: "\f0b27";
}

.mdi-chevron-left::before {
  content: "\f0141";
}

.mdi-chevron-left-box::before {
  content: "\f09d8";
}

.mdi-chevron-left-box-outline::before {
  content: "\f09d9";
}

.mdi-chevron-left-circle::before {
  content: "\f0b28";
}

.mdi-chevron-left-circle-outline::before {
  content: "\f0b29";
}

.mdi-chevron-right::before {
  content: "\f0142";
}

.mdi-chevron-right-box::before {
  content: "\f09da";
}

.mdi-chevron-right-box-outline::before {
  content: "\f09db";
}

.mdi-chevron-right-circle::before {
  content: "\f0b2a";
}

.mdi-chevron-right-circle-outline::before {
  content: "\f0b2b";
}

.mdi-chevron-triple-down::before {
  content: "\f0db9";
}

.mdi-chevron-triple-left::before {
  content: "\f0dba";
}

.mdi-chevron-triple-right::before {
  content: "\f0dbb";
}

.mdi-chevron-triple-up::before {
  content: "\f0dbc";
}

.mdi-chevron-up::before {
  content: "\f0143";
}

.mdi-chevron-up-box::before {
  content: "\f09dc";
}

.mdi-chevron-up-box-outline::before {
  content: "\f09dd";
}

.mdi-chevron-up-circle::before {
  content: "\f0b2c";
}

.mdi-chevron-up-circle-outline::before {
  content: "\f0b2d";
}

.mdi-chili-hot::before {
  content: "\f07b2";
}

.mdi-chili-medium::before {
  content: "\f07b3";
}

.mdi-chili-mild::before {
  content: "\f07b4";
}

.mdi-chili-off::before {
  content: "\f1467";
}

.mdi-chip::before {
  content: "\f061a";
}

.mdi-christianity::before {
  content: "\f0953";
}

.mdi-christianity-outline::before {
  content: "\f0cf6";
}

.mdi-church::before {
  content: "\f0144";
}

.mdi-cigar::before {
  content: "\f1189";
}

.mdi-cigar-off::before {
  content: "\f141b";
}

.mdi-circle::before {
  content: "\f0765";
}

.mdi-circle-box::before {
  content: "\f15dc";
}

.mdi-circle-box-outline::before {
  content: "\f15dd";
}

.mdi-circle-double::before {
  content: "\f0e95";
}

.mdi-circle-edit-outline::before {
  content: "\f08d5";
}

.mdi-circle-expand::before {
  content: "\f0e96";
}

.mdi-circle-half::before {
  content: "\f1395";
}

.mdi-circle-half-full::before {
  content: "\f1396";
}

.mdi-circle-medium::before {
  content: "\f09de";
}

.mdi-circle-multiple::before {
  content: "\f0b38";
}

.mdi-circle-multiple-outline::before {
  content: "\f0695";
}

.mdi-circle-off-outline::before {
  content: "\f10d3";
}

.mdi-circle-outline::before {
  content: "\f0766";
}

.mdi-circle-slice-1::before {
  content: "\f0a9e";
}

.mdi-circle-slice-2::before {
  content: "\f0a9f";
}

.mdi-circle-slice-3::before {
  content: "\f0aa0";
}

.mdi-circle-slice-4::before {
  content: "\f0aa1";
}

.mdi-circle-slice-5::before {
  content: "\f0aa2";
}

.mdi-circle-slice-6::before {
  content: "\f0aa3";
}

.mdi-circle-slice-7::before {
  content: "\f0aa4";
}

.mdi-circle-slice-8::before {
  content: "\f0aa5";
}

.mdi-circle-small::before {
  content: "\f09df";
}

.mdi-circular-saw::before {
  content: "\f0e22";
}

.mdi-city::before {
  content: "\f0146";
}

.mdi-city-variant::before {
  content: "\f0a36";
}

.mdi-city-variant-outline::before {
  content: "\f0a37";
}

.mdi-clipboard::before {
  content: "\f0147";
}

.mdi-clipboard-account::before {
  content: "\f0148";
}

.mdi-clipboard-account-outline::before {
  content: "\f0c55";
}

.mdi-clipboard-alert::before {
  content: "\f0149";
}

.mdi-clipboard-alert-outline::before {
  content: "\f0cf7";
}

.mdi-clipboard-arrow-down::before {
  content: "\f014a";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "\f0c56";
}

.mdi-clipboard-arrow-left::before {
  content: "\f014b";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "\f0cf8";
}

.mdi-clipboard-arrow-right::before {
  content: "\f0cf9";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "\f0cfa";
}

.mdi-clipboard-arrow-up::before {
  content: "\f0c57";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "\f0c58";
}

.mdi-clipboard-check::before {
  content: "\f014e";
}

.mdi-clipboard-check-multiple::before {
  content: "\f1263";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "\f1264";
}

.mdi-clipboard-check-outline::before {
  content: "\f08a8";
}

.mdi-clipboard-edit::before {
  content: "\f14e5";
}

.mdi-clipboard-edit-outline::before {
  content: "\f14e6";
}

.mdi-clipboard-file::before {
  content: "\f1265";
}

.mdi-clipboard-file-outline::before {
  content: "\f1266";
}

.mdi-clipboard-flow::before {
  content: "\f06c8";
}

.mdi-clipboard-flow-outline::before {
  content: "\f1117";
}

.mdi-clipboard-list::before {
  content: "\f10d4";
}

.mdi-clipboard-list-outline::before {
  content: "\f10d5";
}

.mdi-clipboard-minus::before {
  content: "\f1618";
}

.mdi-clipboard-minus-outline::before {
  content: "\f1619";
}

.mdi-clipboard-multiple::before {
  content: "\f1267";
}

.mdi-clipboard-multiple-outline::before {
  content: "\f1268";
}

.mdi-clipboard-off::before {
  content: "\f161a";
}

.mdi-clipboard-off-outline::before {
  content: "\f161b";
}

.mdi-clipboard-outline::before {
  content: "\f014c";
}

.mdi-clipboard-play::before {
  content: "\f0c59";
}

.mdi-clipboard-play-multiple::before {
  content: "\f1269";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "\f126a";
}

.mdi-clipboard-play-outline::before {
  content: "\f0c5a";
}

.mdi-clipboard-plus::before {
  content: "\f0751";
}

.mdi-clipboard-plus-outline::before {
  content: "\f131f";
}

.mdi-clipboard-pulse::before {
  content: "\f085d";
}

.mdi-clipboard-pulse-outline::before {
  content: "\f085e";
}

.mdi-clipboard-remove::before {
  content: "\f161c";
}

.mdi-clipboard-remove-outline::before {
  content: "\f161d";
}

.mdi-clipboard-search::before {
  content: "\f161e";
}

.mdi-clipboard-search-outline::before {
  content: "\f161f";
}

.mdi-clipboard-text::before {
  content: "\f014d";
}

.mdi-clipboard-text-multiple::before {
  content: "\f126b";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "\f126c";
}

.mdi-clipboard-text-off::before {
  content: "\f1620";
}

.mdi-clipboard-text-off-outline::before {
  content: "\f1621";
}

.mdi-clipboard-text-outline::before {
  content: "\f0a38";
}

.mdi-clipboard-text-play::before {
  content: "\f0c5b";
}

.mdi-clipboard-text-play-outline::before {
  content: "\f0c5c";
}

.mdi-clipboard-text-search::before {
  content: "\f1622";
}

.mdi-clipboard-text-search-outline::before {
  content: "\f1623";
}

.mdi-clippy::before {
  content: "\f014f";
}

.mdi-clock::before {
  content: "\f0954";
}

.mdi-clock-alert::before {
  content: "\f0955";
}

.mdi-clock-alert-outline::before {
  content: "\f05ce";
}

.mdi-clock-check::before {
  content: "\f0fa8";
}

.mdi-clock-check-outline::before {
  content: "\f0fa9";
}

.mdi-clock-digital::before {
  content: "\f0e97";
}

.mdi-clock-end::before {
  content: "\f0151";
}

.mdi-clock-fast::before {
  content: "\f0152";
}

.mdi-clock-in::before {
  content: "\f0153";
}

.mdi-clock-out::before {
  content: "\f0154";
}

.mdi-clock-outline::before {
  content: "\f0150";
}

.mdi-clock-start::before {
  content: "\f0155";
}

.mdi-clock-time-eight::before {
  content: "\f1446";
}

.mdi-clock-time-eight-outline::before {
  content: "\f1452";
}

.mdi-clock-time-eleven::before {
  content: "\f1449";
}

.mdi-clock-time-eleven-outline::before {
  content: "\f1455";
}

.mdi-clock-time-five::before {
  content: "\f1443";
}

.mdi-clock-time-five-outline::before {
  content: "\f144f";
}

.mdi-clock-time-four::before {
  content: "\f1442";
}

.mdi-clock-time-four-outline::before {
  content: "\f144e";
}

.mdi-clock-time-nine::before {
  content: "\f1447";
}

.mdi-clock-time-nine-outline::before {
  content: "\f1453";
}

.mdi-clock-time-one::before {
  content: "\f143f";
}

.mdi-clock-time-one-outline::before {
  content: "\f144b";
}

.mdi-clock-time-seven::before {
  content: "\f1445";
}

.mdi-clock-time-seven-outline::before {
  content: "\f1451";
}

.mdi-clock-time-six::before {
  content: "\f1444";
}

.mdi-clock-time-six-outline::before {
  content: "\f1450";
}

.mdi-clock-time-ten::before {
  content: "\f1448";
}

.mdi-clock-time-ten-outline::before {
  content: "\f1454";
}

.mdi-clock-time-three::before {
  content: "\f1441";
}

.mdi-clock-time-three-outline::before {
  content: "\f144d";
}

.mdi-clock-time-twelve::before {
  content: "\f144a";
}

.mdi-clock-time-twelve-outline::before {
  content: "\f1456";
}

.mdi-clock-time-two::before {
  content: "\f1440";
}

.mdi-clock-time-two-outline::before {
  content: "\f144c";
}

.mdi-close::before {
  content: "\f0156";
}

.mdi-close-box::before {
  content: "\f0157";
}

.mdi-close-box-multiple::before {
  content: "\f0c5d";
}

.mdi-close-box-multiple-outline::before {
  content: "\f0c5e";
}

.mdi-close-box-outline::before {
  content: "\f0158";
}

.mdi-close-circle::before {
  content: "\f0159";
}

.mdi-close-circle-multiple::before {
  content: "\f062a";
}

.mdi-close-circle-multiple-outline::before {
  content: "\f0883";
}

.mdi-close-circle-outline::before {
  content: "\f015a";
}

.mdi-close-network::before {
  content: "\f015b";
}

.mdi-close-network-outline::before {
  content: "\f0c5f";
}

.mdi-close-octagon::before {
  content: "\f015c";
}

.mdi-close-octagon-outline::before {
  content: "\f015d";
}

.mdi-close-outline::before {
  content: "\f06c9";
}

.mdi-close-thick::before {
  content: "\f1398";
}

.mdi-closed-caption::before {
  content: "\f015e";
}

.mdi-closed-caption-outline::before {
  content: "\f0dbd";
}

.mdi-cloud::before {
  content: "\f015f";
}

.mdi-cloud-alert::before {
  content: "\f09e0";
}

.mdi-cloud-braces::before {
  content: "\f07b5";
}

.mdi-cloud-check::before {
  content: "\f0160";
}

.mdi-cloud-check-outline::before {
  content: "\f12cc";
}

.mdi-cloud-circle::before {
  content: "\f0161";
}

.mdi-cloud-download::before {
  content: "\f0162";
}

.mdi-cloud-download-outline::before {
  content: "\f0b7d";
}

.mdi-cloud-lock::before {
  content: "\f11f1";
}

.mdi-cloud-lock-outline::before {
  content: "\f11f2";
}

.mdi-cloud-off-outline::before {
  content: "\f0164";
}

.mdi-cloud-outline::before {
  content: "\f0163";
}

.mdi-cloud-print::before {
  content: "\f0165";
}

.mdi-cloud-print-outline::before {
  content: "\f0166";
}

.mdi-cloud-question::before {
  content: "\f0a39";
}

.mdi-cloud-refresh::before {
  content: "\f052a";
}

.mdi-cloud-search::before {
  content: "\f0956";
}

.mdi-cloud-search-outline::before {
  content: "\f0957";
}

.mdi-cloud-sync::before {
  content: "\f063f";
}

.mdi-cloud-sync-outline::before {
  content: "\f12d6";
}

.mdi-cloud-tags::before {
  content: "\f07b6";
}

.mdi-cloud-upload::before {
  content: "\f0167";
}

.mdi-cloud-upload-outline::before {
  content: "\f0b7e";
}

.mdi-clover::before {
  content: "\f0816";
}

.mdi-coach-lamp::before {
  content: "\f1020";
}

.mdi-coat-rack::before {
  content: "\f109e";
}

.mdi-code-array::before {
  content: "\f0168";
}

.mdi-code-braces::before {
  content: "\f0169";
}

.mdi-code-braces-box::before {
  content: "\f10d6";
}

.mdi-code-brackets::before {
  content: "\f016a";
}

.mdi-code-equal::before {
  content: "\f016b";
}

.mdi-code-greater-than::before {
  content: "\f016c";
}

.mdi-code-greater-than-or-equal::before {
  content: "\f016d";
}

.mdi-code-json::before {
  content: "\f0626";
}

.mdi-code-less-than::before {
  content: "\f016e";
}

.mdi-code-less-than-or-equal::before {
  content: "\f016f";
}

.mdi-code-not-equal::before {
  content: "\f0170";
}

.mdi-code-not-equal-variant::before {
  content: "\f0171";
}

.mdi-code-parentheses::before {
  content: "\f0172";
}

.mdi-code-parentheses-box::before {
  content: "\f10d7";
}

.mdi-code-string::before {
  content: "\f0173";
}

.mdi-code-tags::before {
  content: "\f0174";
}

.mdi-code-tags-check::before {
  content: "\f0694";
}

.mdi-codepen::before {
  content: "\f0175";
}

.mdi-coffee::before {
  content: "\f0176";
}

.mdi-coffee-maker::before {
  content: "\f109f";
}

.mdi-coffee-off::before {
  content: "\f0faa";
}

.mdi-coffee-off-outline::before {
  content: "\f0fab";
}

.mdi-coffee-outline::before {
  content: "\f06ca";
}

.mdi-coffee-to-go::before {
  content: "\f0177";
}

.mdi-coffee-to-go-outline::before {
  content: "\f130e";
}

.mdi-coffin::before {
  content: "\f0b7f";
}

.mdi-cog::before {
  content: "\f0493";
}

.mdi-cog-box::before {
  content: "\f0494";
}

.mdi-cog-clockwise::before {
  content: "\f11dd";
}

.mdi-cog-counterclockwise::before {
  content: "\f11de";
}

.mdi-cog-off::before {
  content: "\f13ce";
}

.mdi-cog-off-outline::before {
  content: "\f13cf";
}

.mdi-cog-outline::before {
  content: "\f08bb";
}

.mdi-cog-refresh::before {
  content: "\f145e";
}

.mdi-cog-refresh-outline::before {
  content: "\f145f";
}

.mdi-cog-sync::before {
  content: "\f1460";
}

.mdi-cog-sync-outline::before {
  content: "\f1461";
}

.mdi-cog-transfer::before {
  content: "\f105b";
}

.mdi-cog-transfer-outline::before {
  content: "\f105c";
}

.mdi-cogs::before {
  content: "\f08d6";
}

.mdi-collage::before {
  content: "\f0640";
}

.mdi-collapse-all::before {
  content: "\f0aa6";
}

.mdi-collapse-all-outline::before {
  content: "\f0aa7";
}

.mdi-color-helper::before {
  content: "\f0179";
}

.mdi-comma::before {
  content: "\f0e23";
}

.mdi-comma-box::before {
  content: "\f0e2b";
}

.mdi-comma-box-outline::before {
  content: "\f0e24";
}

.mdi-comma-circle::before {
  content: "\f0e25";
}

.mdi-comma-circle-outline::before {
  content: "\f0e26";
}

.mdi-comment::before {
  content: "\f017a";
}

.mdi-comment-account::before {
  content: "\f017b";
}

.mdi-comment-account-outline::before {
  content: "\f017c";
}

.mdi-comment-alert::before {
  content: "\f017d";
}

.mdi-comment-alert-outline::before {
  content: "\f017e";
}

.mdi-comment-arrow-left::before {
  content: "\f09e1";
}

.mdi-comment-arrow-left-outline::before {
  content: "\f09e2";
}

.mdi-comment-arrow-right::before {
  content: "\f09e3";
}

.mdi-comment-arrow-right-outline::before {
  content: "\f09e4";
}

.mdi-comment-bookmark::before {
  content: "\f15ae";
}

.mdi-comment-bookmark-outline::before {
  content: "\f15af";
}

.mdi-comment-check::before {
  content: "\f017f";
}

.mdi-comment-check-outline::before {
  content: "\f0180";
}

.mdi-comment-edit::before {
  content: "\f11bf";
}

.mdi-comment-edit-outline::before {
  content: "\f12c4";
}

.mdi-comment-eye::before {
  content: "\f0a3a";
}

.mdi-comment-eye-outline::before {
  content: "\f0a3b";
}

.mdi-comment-flash::before {
  content: "\f15b0";
}

.mdi-comment-flash-outline::before {
  content: "\f15b1";
}

.mdi-comment-minus::before {
  content: "\f15df";
}

.mdi-comment-minus-outline::before {
  content: "\f15e0";
}

.mdi-comment-multiple::before {
  content: "\f085f";
}

.mdi-comment-multiple-outline::before {
  content: "\f0181";
}

.mdi-comment-off::before {
  content: "\f15e1";
}

.mdi-comment-off-outline::before {
  content: "\f15e2";
}

.mdi-comment-outline::before {
  content: "\f0182";
}

.mdi-comment-plus::before {
  content: "\f09e5";
}

.mdi-comment-plus-outline::before {
  content: "\f0183";
}

.mdi-comment-processing::before {
  content: "\f0184";
}

.mdi-comment-processing-outline::before {
  content: "\f0185";
}

.mdi-comment-question::before {
  content: "\f0817";
}

.mdi-comment-question-outline::before {
  content: "\f0186";
}

.mdi-comment-quote::before {
  content: "\f1021";
}

.mdi-comment-quote-outline::before {
  content: "\f1022";
}

.mdi-comment-remove::before {
  content: "\f05de";
}

.mdi-comment-remove-outline::before {
  content: "\f0187";
}

.mdi-comment-search::before {
  content: "\f0a3c";
}

.mdi-comment-search-outline::before {
  content: "\f0a3d";
}

.mdi-comment-text::before {
  content: "\f0188";
}

.mdi-comment-text-multiple::before {
  content: "\f0860";
}

.mdi-comment-text-multiple-outline::before {
  content: "\f0861";
}

.mdi-comment-text-outline::before {
  content: "\f0189";
}

.mdi-compare::before {
  content: "\f018a";
}

.mdi-compare-horizontal::before {
  content: "\f1492";
}

.mdi-compare-vertical::before {
  content: "\f1493";
}

.mdi-compass::before {
  content: "\f018b";
}

.mdi-compass-off::before {
  content: "\f0b80";
}

.mdi-compass-off-outline::before {
  content: "\f0b81";
}

.mdi-compass-outline::before {
  content: "\f018c";
}

.mdi-compass-rose::before {
  content: "\f1382";
}

.mdi-concourse-ci::before {
  content: "\f10a0";
}

.mdi-connection::before {
  content: "\f1616";
}

.mdi-console::before {
  content: "\f018d";
}

.mdi-console-line::before {
  content: "\f07b7";
}

.mdi-console-network::before {
  content: "\f08a9";
}

.mdi-console-network-outline::before {
  content: "\f0c60";
}

.mdi-consolidate::before {
  content: "\f10d8";
}

.mdi-contactless-payment::before {
  content: "\f0d6a";
}

.mdi-contactless-payment-circle::before {
  content: "\f0321";
}

.mdi-contactless-payment-circle-outline::before {
  content: "\f0408";
}

.mdi-contacts::before {
  content: "\f06cb";
}

.mdi-contacts-outline::before {
  content: "\f05b8";
}

.mdi-contain::before {
  content: "\f0a3e";
}

.mdi-contain-end::before {
  content: "\f0a3f";
}

.mdi-contain-start::before {
  content: "\f0a40";
}

.mdi-content-copy::before {
  content: "\f018f";
}

.mdi-content-cut::before {
  content: "\f0190";
}

.mdi-content-duplicate::before {
  content: "\f0191";
}

.mdi-content-paste::before {
  content: "\f0192";
}

.mdi-content-save::before {
  content: "\f0193";
}

.mdi-content-save-alert::before {
  content: "\f0f42";
}

.mdi-content-save-alert-outline::before {
  content: "\f0f43";
}

.mdi-content-save-all::before {
  content: "\f0194";
}

.mdi-content-save-all-outline::before {
  content: "\f0f44";
}

.mdi-content-save-cog::before {
  content: "\f145b";
}

.mdi-content-save-cog-outline::before {
  content: "\f145c";
}

.mdi-content-save-edit::before {
  content: "\f0cfb";
}

.mdi-content-save-edit-outline::before {
  content: "\f0cfc";
}

.mdi-content-save-move::before {
  content: "\f0e27";
}

.mdi-content-save-move-outline::before {
  content: "\f0e28";
}

.mdi-content-save-off::before {
  content: "\f1643";
}

.mdi-content-save-off-outline::before {
  content: "\f1644";
}

.mdi-content-save-outline::before {
  content: "\f0818";
}

.mdi-content-save-settings::before {
  content: "\f061b";
}

.mdi-content-save-settings-outline::before {
  content: "\f0b2e";
}

.mdi-contrast::before {
  content: "\f0195";
}

.mdi-contrast-box::before {
  content: "\f0196";
}

.mdi-contrast-circle::before {
  content: "\f0197";
}

.mdi-controller-classic::before {
  content: "\f0b82";
}

.mdi-controller-classic-outline::before {
  content: "\f0b83";
}

.mdi-cookie::before {
  content: "\f0198";
}

.mdi-cookie-alert::before {
  content: "\f16d0";
}

.mdi-cookie-alert-outline::before {
  content: "\f16d1";
}

.mdi-cookie-check::before {
  content: "\f16d2";
}

.mdi-cookie-check-outline::before {
  content: "\f16d3";
}

.mdi-cookie-cog::before {
  content: "\f16d4";
}

.mdi-cookie-cog-outline::before {
  content: "\f16d5";
}

.mdi-cookie-minus::before {
  content: "\f16da";
}

.mdi-cookie-minus-outline::before {
  content: "\f16db";
}

.mdi-cookie-outline::before {
  content: "\f16de";
}

.mdi-cookie-plus::before {
  content: "\f16d6";
}

.mdi-cookie-plus-outline::before {
  content: "\f16d7";
}

.mdi-cookie-remove::before {
  content: "\f16d8";
}

.mdi-cookie-remove-outline::before {
  content: "\f16d9";
}

.mdi-cookie-settings::before {
  content: "\f16dc";
}

.mdi-cookie-settings-outline::before {
  content: "\f16dd";
}

.mdi-coolant-temperature::before {
  content: "\f03c8";
}

.mdi-copyright::before {
  content: "\f05e6";
}

.mdi-cordova::before {
  content: "\f0958";
}

.mdi-corn::before {
  content: "\f07b8";
}

.mdi-corn-off::before {
  content: "\f13ef";
}

.mdi-cosine-wave::before {
  content: "\f1479";
}

.mdi-counter::before {
  content: "\f0199";
}

.mdi-cow::before {
  content: "\f019a";
}

.mdi-cpu-32-bit::before {
  content: "\f0edf";
}

.mdi-cpu-64-bit::before {
  content: "\f0ee0";
}

.mdi-crane::before {
  content: "\f0862";
}

.mdi-creation::before {
  content: "\f0674";
}

.mdi-creative-commons::before {
  content: "\f0d6b";
}

.mdi-credit-card::before {
  content: "\f0fef";
}

.mdi-credit-card-check::before {
  content: "\f13d0";
}

.mdi-credit-card-check-outline::before {
  content: "\f13d1";
}

.mdi-credit-card-clock::before {
  content: "\f0ee1";
}

.mdi-credit-card-clock-outline::before {
  content: "\f0ee2";
}

.mdi-credit-card-marker::before {
  content: "\f06a8";
}

.mdi-credit-card-marker-outline::before {
  content: "\f0dbe";
}

.mdi-credit-card-minus::before {
  content: "\f0fac";
}

.mdi-credit-card-minus-outline::before {
  content: "\f0fad";
}

.mdi-credit-card-multiple::before {
  content: "\f0ff0";
}

.mdi-credit-card-multiple-outline::before {
  content: "\f019c";
}

.mdi-credit-card-off::before {
  content: "\f0ff1";
}

.mdi-credit-card-off-outline::before {
  content: "\f05e4";
}

.mdi-credit-card-outline::before {
  content: "\f019b";
}

.mdi-credit-card-plus::before {
  content: "\f0ff2";
}

.mdi-credit-card-plus-outline::before {
  content: "\f0676";
}

.mdi-credit-card-refresh::before {
  content: "\f1645";
}

.mdi-credit-card-refresh-outline::before {
  content: "\f1646";
}

.mdi-credit-card-refund::before {
  content: "\f0ff3";
}

.mdi-credit-card-refund-outline::before {
  content: "\f0aa8";
}

.mdi-credit-card-remove::before {
  content: "\f0fae";
}

.mdi-credit-card-remove-outline::before {
  content: "\f0faf";
}

.mdi-credit-card-scan::before {
  content: "\f0ff4";
}

.mdi-credit-card-scan-outline::before {
  content: "\f019d";
}

.mdi-credit-card-search::before {
  content: "\f1647";
}

.mdi-credit-card-search-outline::before {
  content: "\f1648";
}

.mdi-credit-card-settings::before {
  content: "\f0ff5";
}

.mdi-credit-card-settings-outline::before {
  content: "\f08d7";
}

.mdi-credit-card-sync::before {
  content: "\f1649";
}

.mdi-credit-card-sync-outline::before {
  content: "\f164a";
}

.mdi-credit-card-wireless::before {
  content: "\f0802";
}

.mdi-credit-card-wireless-off::before {
  content: "\f057a";
}

.mdi-credit-card-wireless-off-outline::before {
  content: "\f057b";
}

.mdi-credit-card-wireless-outline::before {
  content: "\f0d6c";
}

.mdi-cricket::before {
  content: "\f0d6d";
}

.mdi-crop::before {
  content: "\f019e";
}

.mdi-crop-free::before {
  content: "\f019f";
}

.mdi-crop-landscape::before {
  content: "\f01a0";
}

.mdi-crop-portrait::before {
  content: "\f01a1";
}

.mdi-crop-rotate::before {
  content: "\f0696";
}

.mdi-crop-square::before {
  content: "\f01a2";
}

.mdi-crosshairs::before {
  content: "\f01a3";
}

.mdi-crosshairs-gps::before {
  content: "\f01a4";
}

.mdi-crosshairs-off::before {
  content: "\f0f45";
}

.mdi-crosshairs-question::before {
  content: "\f1136";
}

.mdi-crown::before {
  content: "\f01a5";
}

.mdi-crown-outline::before {
  content: "\f11d0";
}

.mdi-cryengine::before {
  content: "\f0959";
}

.mdi-crystal-ball::before {
  content: "\f0b2f";
}

.mdi-cube::before {
  content: "\f01a6";
}

.mdi-cube-off::before {
  content: "\f141c";
}

.mdi-cube-off-outline::before {
  content: "\f141d";
}

.mdi-cube-outline::before {
  content: "\f01a7";
}

.mdi-cube-scan::before {
  content: "\f0b84";
}

.mdi-cube-send::before {
  content: "\f01a8";
}

.mdi-cube-unfolded::before {
  content: "\f01a9";
}

.mdi-cup::before {
  content: "\f01aa";
}

.mdi-cup-off::before {
  content: "\f05e5";
}

.mdi-cup-off-outline::before {
  content: "\f137d";
}

.mdi-cup-outline::before {
  content: "\f130f";
}

.mdi-cup-water::before {
  content: "\f01ab";
}

.mdi-cupboard::before {
  content: "\f0f46";
}

.mdi-cupboard-outline::before {
  content: "\f0f47";
}

.mdi-cupcake::before {
  content: "\f095a";
}

.mdi-curling::before {
  content: "\f0863";
}

.mdi-currency-bdt::before {
  content: "\f0864";
}

.mdi-currency-brl::before {
  content: "\f0b85";
}

.mdi-currency-btc::before {
  content: "\f01ac";
}

.mdi-currency-cny::before {
  content: "\f07ba";
}

.mdi-currency-eth::before {
  content: "\f07bb";
}

.mdi-currency-eur::before {
  content: "\f01ad";
}

.mdi-currency-eur-off::before {
  content: "\f1315";
}

.mdi-currency-gbp::before {
  content: "\f01ae";
}

.mdi-currency-ils::before {
  content: "\f0c61";
}

.mdi-currency-inr::before {
  content: "\f01af";
}

.mdi-currency-jpy::before {
  content: "\f07bc";
}

.mdi-currency-krw::before {
  content: "\f07bd";
}

.mdi-currency-kzt::before {
  content: "\f0865";
}

.mdi-currency-mnt::before {
  content: "\f1512";
}

.mdi-currency-ngn::before {
  content: "\f01b0";
}

.mdi-currency-php::before {
  content: "\f09e6";
}

.mdi-currency-rial::before {
  content: "\f0e9c";
}

.mdi-currency-rub::before {
  content: "\f01b1";
}

.mdi-currency-sign::before {
  content: "\f07be";
}

.mdi-currency-try::before {
  content: "\f01b2";
}

.mdi-currency-twd::before {
  content: "\f07bf";
}

.mdi-currency-usd::before {
  content: "\f01c1";
}

.mdi-currency-usd-circle::before {
  content: "\f116b";
}

.mdi-currency-usd-circle-outline::before {
  content: "\f0178";
}

.mdi-currency-usd-off::before {
  content: "\f067a";
}

.mdi-current-ac::before {
  content: "\f1480";
}

.mdi-current-dc::before {
  content: "\f095c";
}

.mdi-cursor-default::before {
  content: "\f01c0";
}

.mdi-cursor-default-click::before {
  content: "\f0cfd";
}

.mdi-cursor-default-click-outline::before {
  content: "\f0cfe";
}

.mdi-cursor-default-gesture::before {
  content: "\f1127";
}

.mdi-cursor-default-gesture-outline::before {
  content: "\f1128";
}

.mdi-cursor-default-outline::before {
  content: "\f01bf";
}

.mdi-cursor-move::before {
  content: "\f01be";
}

.mdi-cursor-pointer::before {
  content: "\f01bd";
}

.mdi-cursor-text::before {
  content: "\f05e7";
}

.mdi-dance-ballroom::before {
  content: "\f15fb";
}

.mdi-dance-pole::before {
  content: "\f1578";
}

.mdi-data-matrix::before {
  content: "\f153c";
}

.mdi-data-matrix-edit::before {
  content: "\f153d";
}

.mdi-data-matrix-minus::before {
  content: "\f153e";
}

.mdi-data-matrix-plus::before {
  content: "\f153f";
}

.mdi-data-matrix-remove::before {
  content: "\f1540";
}

.mdi-data-matrix-scan::before {
  content: "\f1541";
}

.mdi-database::before {
  content: "\f01bc";
}

.mdi-database-alert::before {
  content: "\f163a";
}

.mdi-database-alert-outline::before {
  content: "\f1624";
}

.mdi-database-arrow-down::before {
  content: "\f163b";
}

.mdi-database-arrow-down-outline::before {
  content: "\f1625";
}

.mdi-database-arrow-left::before {
  content: "\f163c";
}

.mdi-database-arrow-left-outline::before {
  content: "\f1626";
}

.mdi-database-arrow-right::before {
  content: "\f163d";
}

.mdi-database-arrow-right-outline::before {
  content: "\f1627";
}

.mdi-database-arrow-up::before {
  content: "\f163e";
}

.mdi-database-arrow-up-outline::before {
  content: "\f1628";
}

.mdi-database-check::before {
  content: "\f0aa9";
}

.mdi-database-check-outline::before {
  content: "\f1629";
}

.mdi-database-clock::before {
  content: "\f163f";
}

.mdi-database-clock-outline::before {
  content: "\f162a";
}

.mdi-database-cog::before {
  content: "\f164b";
}

.mdi-database-cog-outline::before {
  content: "\f164c";
}

.mdi-database-edit::before {
  content: "\f0b86";
}

.mdi-database-edit-outline::before {
  content: "\f162b";
}

.mdi-database-export::before {
  content: "\f095e";
}

.mdi-database-export-outline::before {
  content: "\f162c";
}

.mdi-database-import::before {
  content: "\f095d";
}

.mdi-database-import-outline::before {
  content: "\f162d";
}

.mdi-database-lock::before {
  content: "\f0aaa";
}

.mdi-database-lock-outline::before {
  content: "\f162e";
}

.mdi-database-marker::before {
  content: "\f12f6";
}

.mdi-database-marker-outline::before {
  content: "\f162f";
}

.mdi-database-minus::before {
  content: "\f01bb";
}

.mdi-database-minus-outline::before {
  content: "\f1630";
}

.mdi-database-off::before {
  content: "\f1640";
}

.mdi-database-off-outline::before {
  content: "\f1631";
}

.mdi-database-outline::before {
  content: "\f1632";
}

.mdi-database-plus::before {
  content: "\f01ba";
}

.mdi-database-plus-outline::before {
  content: "\f1633";
}

.mdi-database-refresh::before {
  content: "\f05c2";
}

.mdi-database-refresh-outline::before {
  content: "\f1634";
}

.mdi-database-remove::before {
  content: "\f0d00";
}

.mdi-database-remove-outline::before {
  content: "\f1635";
}

.mdi-database-search::before {
  content: "\f0866";
}

.mdi-database-search-outline::before {
  content: "\f1636";
}

.mdi-database-settings::before {
  content: "\f0d01";
}

.mdi-database-settings-outline::before {
  content: "\f1637";
}

.mdi-database-sync::before {
  content: "\f0cff";
}

.mdi-database-sync-outline::before {
  content: "\f1638";
}

.mdi-death-star::before {
  content: "\f08d8";
}

.mdi-death-star-variant::before {
  content: "\f08d9";
}

.mdi-deathly-hallows::before {
  content: "\f0b87";
}

.mdi-debian::before {
  content: "\f08da";
}

.mdi-debug-step-into::before {
  content: "\f01b9";
}

.mdi-debug-step-out::before {
  content: "\f01b8";
}

.mdi-debug-step-over::before {
  content: "\f01b7";
}

.mdi-decagram::before {
  content: "\f076c";
}

.mdi-decagram-outline::before {
  content: "\f076d";
}

.mdi-decimal::before {
  content: "\f10a1";
}

.mdi-decimal-comma::before {
  content: "\f10a2";
}

.mdi-decimal-comma-decrease::before {
  content: "\f10a3";
}

.mdi-decimal-comma-increase::before {
  content: "\f10a4";
}

.mdi-decimal-decrease::before {
  content: "\f01b6";
}

.mdi-decimal-increase::before {
  content: "\f01b5";
}

.mdi-delete::before {
  content: "\f01b4";
}

.mdi-delete-alert::before {
  content: "\f10a5";
}

.mdi-delete-alert-outline::before {
  content: "\f10a6";
}

.mdi-delete-circle::before {
  content: "\f0683";
}

.mdi-delete-circle-outline::before {
  content: "\f0b88";
}

.mdi-delete-clock::before {
  content: "\f1556";
}

.mdi-delete-clock-outline::before {
  content: "\f1557";
}

.mdi-delete-empty::before {
  content: "\f06cc";
}

.mdi-delete-empty-outline::before {
  content: "\f0e9d";
}

.mdi-delete-forever::before {
  content: "\f05e8";
}

.mdi-delete-forever-outline::before {
  content: "\f0b89";
}

.mdi-delete-off::before {
  content: "\f10a7";
}

.mdi-delete-off-outline::before {
  content: "\f10a8";
}

.mdi-delete-outline::before {
  content: "\f09e7";
}

.mdi-delete-restore::before {
  content: "\f0819";
}

.mdi-delete-sweep::before {
  content: "\f05e9";
}

.mdi-delete-sweep-outline::before {
  content: "\f0c62";
}

.mdi-delete-variant::before {
  content: "\f01b3";
}

.mdi-delta::before {
  content: "\f01c2";
}

.mdi-desk::before {
  content: "\f1239";
}

.mdi-desk-lamp::before {
  content: "\f095f";
}

.mdi-deskphone::before {
  content: "\f01c3";
}

.mdi-desktop-classic::before {
  content: "\f07c0";
}

.mdi-desktop-mac::before {
  content: "\f01c4";
}

.mdi-desktop-mac-dashboard::before {
  content: "\f09e8";
}

.mdi-desktop-tower::before {
  content: "\f01c5";
}

.mdi-desktop-tower-monitor::before {
  content: "\f0aab";
}

.mdi-details::before {
  content: "\f01c6";
}

.mdi-dev-to::before {
  content: "\f0d6e";
}

.mdi-developer-board::before {
  content: "\f0697";
}

.mdi-deviantart::before {
  content: "\f01c7";
}

.mdi-devices::before {
  content: "\f0fb0";
}

.mdi-diabetes::before {
  content: "\f1126";
}

.mdi-dialpad::before {
  content: "\f061c";
}

.mdi-diameter::before {
  content: "\f0c63";
}

.mdi-diameter-outline::before {
  content: "\f0c64";
}

.mdi-diameter-variant::before {
  content: "\f0c65";
}

.mdi-diamond::before {
  content: "\f0b8a";
}

.mdi-diamond-outline::before {
  content: "\f0b8b";
}

.mdi-diamond-stone::before {
  content: "\f01c8";
}

.mdi-dice-1::before {
  content: "\f01ca";
}

.mdi-dice-1-outline::before {
  content: "\f114a";
}

.mdi-dice-2::before {
  content: "\f01cb";
}

.mdi-dice-2-outline::before {
  content: "\f114b";
}

.mdi-dice-3::before {
  content: "\f01cc";
}

.mdi-dice-3-outline::before {
  content: "\f114c";
}

.mdi-dice-4::before {
  content: "\f01cd";
}

.mdi-dice-4-outline::before {
  content: "\f114d";
}

.mdi-dice-5::before {
  content: "\f01ce";
}

.mdi-dice-5-outline::before {
  content: "\f114e";
}

.mdi-dice-6::before {
  content: "\f01cf";
}

.mdi-dice-6-outline::before {
  content: "\f114f";
}

.mdi-dice-d10::before {
  content: "\f1153";
}

.mdi-dice-d10-outline::before {
  content: "\f076f";
}

.mdi-dice-d12::before {
  content: "\f1154";
}

.mdi-dice-d12-outline::before {
  content: "\f0867";
}

.mdi-dice-d20::before {
  content: "\f1155";
}

.mdi-dice-d20-outline::before {
  content: "\f05ea";
}

.mdi-dice-d4::before {
  content: "\f1150";
}

.mdi-dice-d4-outline::before {
  content: "\f05eb";
}

.mdi-dice-d6::before {
  content: "\f1151";
}

.mdi-dice-d6-outline::before {
  content: "\f05ed";
}

.mdi-dice-d8::before {
  content: "\f1152";
}

.mdi-dice-d8-outline::before {
  content: "\f05ec";
}

.mdi-dice-multiple::before {
  content: "\f076e";
}

.mdi-dice-multiple-outline::before {
  content: "\f1156";
}

.mdi-digital-ocean::before {
  content: "\f1237";
}

.mdi-dip-switch::before {
  content: "\f07c1";
}

.mdi-directions::before {
  content: "\f01d0";
}

.mdi-directions-fork::before {
  content: "\f0641";
}

.mdi-disc::before {
  content: "\f05ee";
}

.mdi-disc-alert::before {
  content: "\f01d1";
}

.mdi-disc-player::before {
  content: "\f0960";
}

.mdi-discord::before {
  content: "\f066f";
}

.mdi-dishwasher::before {
  content: "\f0aac";
}

.mdi-dishwasher-alert::before {
  content: "\f11b8";
}

.mdi-dishwasher-off::before {
  content: "\f11b9";
}

.mdi-disqus::before {
  content: "\f01d2";
}

.mdi-distribute-horizontal-center::before {
  content: "\f11c9";
}

.mdi-distribute-horizontal-left::before {
  content: "\f11c8";
}

.mdi-distribute-horizontal-right::before {
  content: "\f11ca";
}

.mdi-distribute-vertical-bottom::before {
  content: "\f11cb";
}

.mdi-distribute-vertical-center::before {
  content: "\f11cc";
}

.mdi-distribute-vertical-top::before {
  content: "\f11cd";
}

.mdi-diving-flippers::before {
  content: "\f0dbf";
}

.mdi-diving-helmet::before {
  content: "\f0dc0";
}

.mdi-diving-scuba::before {
  content: "\f0dc1";
}

.mdi-diving-scuba-flag::before {
  content: "\f0dc2";
}

.mdi-diving-scuba-tank::before {
  content: "\f0dc3";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "\f0dc4";
}

.mdi-diving-snorkel::before {
  content: "\f0dc5";
}

.mdi-division::before {
  content: "\f01d4";
}

.mdi-division-box::before {
  content: "\f01d5";
}

.mdi-dlna::before {
  content: "\f0a41";
}

.mdi-dna::before {
  content: "\f0684";
}

.mdi-dns::before {
  content: "\f01d6";
}

.mdi-dns-outline::before {
  content: "\f0b8c";
}

.mdi-do-not-disturb::before {
  content: "\f0698";
}

.mdi-do-not-disturb-off::before {
  content: "\f0699";
}

.mdi-dock-bottom::before {
  content: "\f10a9";
}

.mdi-dock-left::before {
  content: "\f10aa";
}

.mdi-dock-right::before {
  content: "\f10ab";
}

.mdi-dock-top::before {
  content: "\f1513";
}

.mdi-dock-window::before {
  content: "\f10ac";
}

.mdi-docker::before {
  content: "\f0868";
}

.mdi-doctor::before {
  content: "\f0a42";
}

.mdi-dog::before {
  content: "\f0a43";
}

.mdi-dog-service::before {
  content: "\f0aad";
}

.mdi-dog-side::before {
  content: "\f0a44";
}

.mdi-dolby::before {
  content: "\f06b3";
}

.mdi-dolly::before {
  content: "\f0e9e";
}

.mdi-domain::before {
  content: "\f01d7";
}

.mdi-domain-off::before {
  content: "\f0d6f";
}

.mdi-domain-plus::before {
  content: "\f10ad";
}

.mdi-domain-remove::before {
  content: "\f10ae";
}

.mdi-dome-light::before {
  content: "\f141e";
}

.mdi-domino-mask::before {
  content: "\f1023";
}

.mdi-donkey::before {
  content: "\f07c2";
}

.mdi-door::before {
  content: "\f081a";
}

.mdi-door-closed::before {
  content: "\f081b";
}

.mdi-door-closed-lock::before {
  content: "\f10af";
}

.mdi-door-open::before {
  content: "\f081c";
}

.mdi-doorbell::before {
  content: "\f12e6";
}

.mdi-doorbell-video::before {
  content: "\f0869";
}

.mdi-dot-net::before {
  content: "\f0aae";
}

.mdi-dots-grid::before {
  content: "\f15fc";
}

.mdi-dots-hexagon::before {
  content: "\f15ff";
}

.mdi-dots-horizontal::before {
  content: "\f01d8";
}

.mdi-dots-horizontal-circle::before {
  content: "\f07c3";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "\f0b8d";
}

.mdi-dots-square::before {
  content: "\f15fd";
}

.mdi-dots-triangle::before {
  content: "\f15fe";
}

.mdi-dots-vertical::before {
  content: "\f01d9";
}

.mdi-dots-vertical-circle::before {
  content: "\f07c4";
}

.mdi-dots-vertical-circle-outline::before {
  content: "\f0b8e";
}

.mdi-douban::before {
  content: "\f069a";
}

.mdi-download::before {
  content: "\f01da";
}

.mdi-download-box::before {
  content: "\f1462";
}

.mdi-download-box-outline::before {
  content: "\f1463";
}

.mdi-download-circle::before {
  content: "\f1464";
}

.mdi-download-circle-outline::before {
  content: "\f1465";
}

.mdi-download-lock::before {
  content: "\f1320";
}

.mdi-download-lock-outline::before {
  content: "\f1321";
}

.mdi-download-multiple::before {
  content: "\f09e9";
}

.mdi-download-network::before {
  content: "\f06f4";
}

.mdi-download-network-outline::before {
  content: "\f0c66";
}

.mdi-download-off::before {
  content: "\f10b0";
}

.mdi-download-off-outline::before {
  content: "\f10b1";
}

.mdi-download-outline::before {
  content: "\f0b8f";
}

.mdi-drag::before {
  content: "\f01db";
}

.mdi-drag-horizontal::before {
  content: "\f01dc";
}

.mdi-drag-horizontal-variant::before {
  content: "\f12f0";
}

.mdi-drag-variant::before {
  content: "\f0b90";
}

.mdi-drag-vertical::before {
  content: "\f01dd";
}

.mdi-drag-vertical-variant::before {
  content: "\f12f1";
}

.mdi-drama-masks::before {
  content: "\f0d02";
}

.mdi-draw::before {
  content: "\f0f49";
}

.mdi-drawing::before {
  content: "\f01de";
}

.mdi-drawing-box::before {
  content: "\f01df";
}

.mdi-dresser::before {
  content: "\f0f4a";
}

.mdi-dresser-outline::before {
  content: "\f0f4b";
}

.mdi-drone::before {
  content: "\f01e2";
}

.mdi-dropbox::before {
  content: "\f01e3";
}

.mdi-drupal::before {
  content: "\f01e4";
}

.mdi-duck::before {
  content: "\f01e5";
}

.mdi-dumbbell::before {
  content: "\f01e6";
}

.mdi-dump-truck::before {
  content: "\f0c67";
}

.mdi-ear-hearing::before {
  content: "\f07c5";
}

.mdi-ear-hearing-off::before {
  content: "\f0a45";
}

.mdi-earth::before {
  content: "\f01e7";
}

.mdi-earth-arrow-right::before {
  content: "\f1311";
}

.mdi-earth-box::before {
  content: "\f06cd";
}

.mdi-earth-box-minus::before {
  content: "\f1407";
}

.mdi-earth-box-off::before {
  content: "\f06ce";
}

.mdi-earth-box-plus::before {
  content: "\f1406";
}

.mdi-earth-box-remove::before {
  content: "\f1408";
}

.mdi-earth-minus::before {
  content: "\f1404";
}

.mdi-earth-off::before {
  content: "\f01e8";
}

.mdi-earth-plus::before {
  content: "\f1403";
}

.mdi-earth-remove::before {
  content: "\f1405";
}

.mdi-egg::before {
  content: "\f0aaf";
}

.mdi-egg-easter::before {
  content: "\f0ab0";
}

.mdi-egg-off::before {
  content: "\f13f0";
}

.mdi-egg-off-outline::before {
  content: "\f13f1";
}

.mdi-egg-outline::before {
  content: "\f13f2";
}

.mdi-eiffel-tower::before {
  content: "\f156b";
}

.mdi-eight-track::before {
  content: "\f09ea";
}

.mdi-eject::before {
  content: "\f01ea";
}

.mdi-eject-outline::before {
  content: "\f0b91";
}

.mdi-electric-switch::before {
  content: "\f0e9f";
}

.mdi-electric-switch-closed::before {
  content: "\f10d9";
}

.mdi-electron-framework::before {
  content: "\f1024";
}

.mdi-elephant::before {
  content: "\f07c6";
}

.mdi-elevation-decline::before {
  content: "\f01eb";
}

.mdi-elevation-rise::before {
  content: "\f01ec";
}

.mdi-elevator::before {
  content: "\f01ed";
}

.mdi-elevator-down::before {
  content: "\f12c2";
}

.mdi-elevator-passenger::before {
  content: "\f1381";
}

.mdi-elevator-up::before {
  content: "\f12c1";
}

.mdi-ellipse::before {
  content: "\f0ea0";
}

.mdi-ellipse-outline::before {
  content: "\f0ea1";
}

.mdi-email::before {
  content: "\f01ee";
}

.mdi-email-alert::before {
  content: "\f06cf";
}

.mdi-email-alert-outline::before {
  content: "\f0d42";
}

.mdi-email-box::before {
  content: "\f0d03";
}

.mdi-email-check::before {
  content: "\f0ab1";
}

.mdi-email-check-outline::before {
  content: "\f0ab2";
}

.mdi-email-edit::before {
  content: "\f0ee3";
}

.mdi-email-edit-outline::before {
  content: "\f0ee4";
}

.mdi-email-lock::before {
  content: "\f01f1";
}

.mdi-email-mark-as-unread::before {
  content: "\f0b92";
}

.mdi-email-minus::before {
  content: "\f0ee5";
}

.mdi-email-minus-outline::before {
  content: "\f0ee6";
}

.mdi-email-multiple::before {
  content: "\f0ee7";
}

.mdi-email-multiple-outline::before {
  content: "\f0ee8";
}

.mdi-email-newsletter::before {
  content: "\f0fb1";
}

.mdi-email-off::before {
  content: "\f13e3";
}

.mdi-email-off-outline::before {
  content: "\f13e4";
}

.mdi-email-open::before {
  content: "\f01ef";
}

.mdi-email-open-multiple::before {
  content: "\f0ee9";
}

.mdi-email-open-multiple-outline::before {
  content: "\f0eea";
}

.mdi-email-open-outline::before {
  content: "\f05ef";
}

.mdi-email-outline::before {
  content: "\f01f0";
}

.mdi-email-plus::before {
  content: "\f09eb";
}

.mdi-email-plus-outline::before {
  content: "\f09ec";
}

.mdi-email-receive::before {
  content: "\f10da";
}

.mdi-email-receive-outline::before {
  content: "\f10db";
}

.mdi-email-remove::before {
  content: "\f1661";
}

.mdi-email-remove-outline::before {
  content: "\f1662";
}

.mdi-email-search::before {
  content: "\f0961";
}

.mdi-email-search-outline::before {
  content: "\f0962";
}

.mdi-email-send::before {
  content: "\f10dc";
}

.mdi-email-send-outline::before {
  content: "\f10dd";
}

.mdi-email-sync::before {
  content: "\f12c7";
}

.mdi-email-sync-outline::before {
  content: "\f12c8";
}

.mdi-email-variant::before {
  content: "\f05f0";
}

.mdi-ember::before {
  content: "\f0b30";
}

.mdi-emby::before {
  content: "\f06b4";
}

.mdi-emoticon::before {
  content: "\f0c68";
}

.mdi-emoticon-angry::before {
  content: "\f0c69";
}

.mdi-emoticon-angry-outline::before {
  content: "\f0c6a";
}

.mdi-emoticon-confused::before {
  content: "\f10de";
}

.mdi-emoticon-confused-outline::before {
  content: "\f10df";
}

.mdi-emoticon-cool::before {
  content: "\f0c6b";
}

.mdi-emoticon-cool-outline::before {
  content: "\f01f3";
}

.mdi-emoticon-cry::before {
  content: "\f0c6c";
}

.mdi-emoticon-cry-outline::before {
  content: "\f0c6d";
}

.mdi-emoticon-dead::before {
  content: "\f0c6e";
}

.mdi-emoticon-dead-outline::before {
  content: "\f069b";
}

.mdi-emoticon-devil::before {
  content: "\f0c6f";
}

.mdi-emoticon-devil-outline::before {
  content: "\f01f4";
}

.mdi-emoticon-excited::before {
  content: "\f0c70";
}

.mdi-emoticon-excited-outline::before {
  content: "\f069c";
}

.mdi-emoticon-frown::before {
  content: "\f0f4c";
}

.mdi-emoticon-frown-outline::before {
  content: "\f0f4d";
}

.mdi-emoticon-happy::before {
  content: "\f0c71";
}

.mdi-emoticon-happy-outline::before {
  content: "\f01f5";
}

.mdi-emoticon-kiss::before {
  content: "\f0c72";
}

.mdi-emoticon-kiss-outline::before {
  content: "\f0c73";
}

.mdi-emoticon-lol::before {
  content: "\f1214";
}

.mdi-emoticon-lol-outline::before {
  content: "\f1215";
}

.mdi-emoticon-neutral::before {
  content: "\f0c74";
}

.mdi-emoticon-neutral-outline::before {
  content: "\f01f6";
}

.mdi-emoticon-outline::before {
  content: "\f01f2";
}

.mdi-emoticon-poop::before {
  content: "\f01f7";
}

.mdi-emoticon-poop-outline::before {
  content: "\f0c75";
}

.mdi-emoticon-sad::before {
  content: "\f0c76";
}

.mdi-emoticon-sad-outline::before {
  content: "\f01f8";
}

.mdi-emoticon-sick::before {
  content: "\f157c";
}

.mdi-emoticon-sick-outline::before {
  content: "\f157d";
}

.mdi-emoticon-tongue::before {
  content: "\f01f9";
}

.mdi-emoticon-tongue-outline::before {
  content: "\f0c77";
}

.mdi-emoticon-wink::before {
  content: "\f0c78";
}

.mdi-emoticon-wink-outline::before {
  content: "\f0c79";
}

.mdi-engine::before {
  content: "\f01fa";
}

.mdi-engine-off::before {
  content: "\f0a46";
}

.mdi-engine-off-outline::before {
  content: "\f0a47";
}

.mdi-engine-outline::before {
  content: "\f01fb";
}

.mdi-epsilon::before {
  content: "\f10e0";
}

.mdi-equal::before {
  content: "\f01fc";
}

.mdi-equal-box::before {
  content: "\f01fd";
}

.mdi-equalizer::before {
  content: "\f0ea2";
}

.mdi-equalizer-outline::before {
  content: "\f0ea3";
}

.mdi-eraser::before {
  content: "\f01fe";
}

.mdi-eraser-variant::before {
  content: "\f0642";
}

.mdi-escalator::before {
  content: "\f01ff";
}

.mdi-escalator-box::before {
  content: "\f1399";
}

.mdi-escalator-down::before {
  content: "\f12c0";
}

.mdi-escalator-up::before {
  content: "\f12bf";
}

.mdi-eslint::before {
  content: "\f0c7a";
}

.mdi-et::before {
  content: "\f0ab3";
}

.mdi-ethereum::before {
  content: "\f086a";
}

.mdi-ethernet::before {
  content: "\f0200";
}

.mdi-ethernet-cable::before {
  content: "\f0201";
}

.mdi-ethernet-cable-off::before {
  content: "\f0202";
}

.mdi-ev-plug-ccs1::before {
  content: "\f1519";
}

.mdi-ev-plug-ccs2::before {
  content: "\f151a";
}

.mdi-ev-plug-chademo::before {
  content: "\f151b";
}

.mdi-ev-plug-tesla::before {
  content: "\f151c";
}

.mdi-ev-plug-type1::before {
  content: "\f151d";
}

.mdi-ev-plug-type2::before {
  content: "\f151e";
}

.mdi-ev-station::before {
  content: "\f05f1";
}

.mdi-evernote::before {
  content: "\f0204";
}

.mdi-excavator::before {
  content: "\f1025";
}

.mdi-exclamation::before {
  content: "\f0205";
}

.mdi-exclamation-thick::before {
  content: "\f1238";
}

.mdi-exit-run::before {
  content: "\f0a48";
}

.mdi-exit-to-app::before {
  content: "\f0206";
}

.mdi-expand-all::before {
  content: "\f0ab4";
}

.mdi-expand-all-outline::before {
  content: "\f0ab5";
}

.mdi-expansion-card::before {
  content: "\f08ae";
}

.mdi-expansion-card-variant::before {
  content: "\f0fb2";
}

.mdi-exponent::before {
  content: "\f0963";
}

.mdi-exponent-box::before {
  content: "\f0964";
}

.mdi-export::before {
  content: "\f0207";
}

.mdi-export-variant::before {
  content: "\f0b93";
}

.mdi-eye::before {
  content: "\f0208";
}

.mdi-eye-check::before {
  content: "\f0d04";
}

.mdi-eye-check-outline::before {
  content: "\f0d05";
}

.mdi-eye-circle::before {
  content: "\f0b94";
}

.mdi-eye-circle-outline::before {
  content: "\f0b95";
}

.mdi-eye-minus::before {
  content: "\f1026";
}

.mdi-eye-minus-outline::before {
  content: "\f1027";
}

.mdi-eye-off::before {
  content: "\f0209";
}

.mdi-eye-off-outline::before {
  content: "\f06d1";
}

.mdi-eye-outline::before {
  content: "\f06d0";
}

.mdi-eye-plus::before {
  content: "\f086b";
}

.mdi-eye-plus-outline::before {
  content: "\f086c";
}

.mdi-eye-remove::before {
  content: "\f15e3";
}

.mdi-eye-remove-outline::before {
  content: "\f15e4";
}

.mdi-eye-settings::before {
  content: "\f086d";
}

.mdi-eye-settings-outline::before {
  content: "\f086e";
}

.mdi-eyedropper::before {
  content: "\f020a";
}

.mdi-eyedropper-minus::before {
  content: "\f13dd";
}

.mdi-eyedropper-off::before {
  content: "\f13df";
}

.mdi-eyedropper-plus::before {
  content: "\f13dc";
}

.mdi-eyedropper-remove::before {
  content: "\f13de";
}

.mdi-eyedropper-variant::before {
  content: "\f020b";
}

.mdi-face::before {
  content: "\f0643";
}

.mdi-face-agent::before {
  content: "\f0d70";
}

.mdi-face-mask::before {
  content: "\f1586";
}

.mdi-face-mask-outline::before {
  content: "\f1587";
}

.mdi-face-outline::before {
  content: "\f0b96";
}

.mdi-face-profile::before {
  content: "\f0644";
}

.mdi-face-profile-woman::before {
  content: "\f1076";
}

.mdi-face-recognition::before {
  content: "\f0c7b";
}

.mdi-face-shimmer::before {
  content: "\f15cc";
}

.mdi-face-shimmer-outline::before {
  content: "\f15cd";
}

.mdi-face-woman::before {
  content: "\f1077";
}

.mdi-face-woman-outline::before {
  content: "\f1078";
}

.mdi-face-woman-shimmer::before {
  content: "\f15ce";
}

.mdi-face-woman-shimmer-outline::before {
  content: "\f15cf";
}

.mdi-facebook::before {
  content: "\f020c";
}

.mdi-facebook-gaming::before {
  content: "\f07dd";
}

.mdi-facebook-messenger::before {
  content: "\f020e";
}

.mdi-facebook-workplace::before {
  content: "\f0b31";
}

.mdi-factory::before {
  content: "\f020f";
}

.mdi-family-tree::before {
  content: "\f160e";
}

.mdi-fan::before {
  content: "\f0210";
}

.mdi-fan-alert::before {
  content: "\f146c";
}

.mdi-fan-chevron-down::before {
  content: "\f146d";
}

.mdi-fan-chevron-up::before {
  content: "\f146e";
}

.mdi-fan-minus::before {
  content: "\f1470";
}

.mdi-fan-off::before {
  content: "\f081d";
}

.mdi-fan-plus::before {
  content: "\f146f";
}

.mdi-fan-remove::before {
  content: "\f1471";
}

.mdi-fan-speed-1::before {
  content: "\f1472";
}

.mdi-fan-speed-2::before {
  content: "\f1473";
}

.mdi-fan-speed-3::before {
  content: "\f1474";
}

.mdi-fast-forward::before {
  content: "\f0211";
}

.mdi-fast-forward-10::before {
  content: "\f0d71";
}

.mdi-fast-forward-30::before {
  content: "\f0d06";
}

.mdi-fast-forward-5::before {
  content: "\f11f8";
}

.mdi-fast-forward-60::before {
  content: "\f160b";
}

.mdi-fast-forward-outline::before {
  content: "\f06d2";
}

.mdi-fax::before {
  content: "\f0212";
}

.mdi-feather::before {
  content: "\f06d3";
}

.mdi-feature-search::before {
  content: "\f0a49";
}

.mdi-feature-search-outline::before {
  content: "\f0a4a";
}

.mdi-fedora::before {
  content: "\f08db";
}

.mdi-fencing::before {
  content: "\f14c1";
}

.mdi-ferris-wheel::before {
  content: "\f0ea4";
}

.mdi-ferry::before {
  content: "\f0213";
}

.mdi-file::before {
  content: "\f0214";
}

.mdi-file-account::before {
  content: "\f073b";
}

.mdi-file-account-outline::before {
  content: "\f1028";
}

.mdi-file-alert::before {
  content: "\f0a4b";
}

.mdi-file-alert-outline::before {
  content: "\f0a4c";
}

.mdi-file-cabinet::before {
  content: "\f0ab6";
}

.mdi-file-cad::before {
  content: "\f0eeb";
}

.mdi-file-cad-box::before {
  content: "\f0eec";
}

.mdi-file-cancel::before {
  content: "\f0dc6";
}

.mdi-file-cancel-outline::before {
  content: "\f0dc7";
}

.mdi-file-certificate::before {
  content: "\f1186";
}

.mdi-file-certificate-outline::before {
  content: "\f1187";
}

.mdi-file-chart::before {
  content: "\f0215";
}

.mdi-file-chart-outline::before {
  content: "\f1029";
}

.mdi-file-check::before {
  content: "\f0216";
}

.mdi-file-check-outline::before {
  content: "\f0e29";
}

.mdi-file-clock::before {
  content: "\f12e1";
}

.mdi-file-clock-outline::before {
  content: "\f12e2";
}

.mdi-file-cloud::before {
  content: "\f0217";
}

.mdi-file-cloud-outline::before {
  content: "\f102a";
}

.mdi-file-code::before {
  content: "\f022e";
}

.mdi-file-code-outline::before {
  content: "\f102b";
}

.mdi-file-cog::before {
  content: "\f107b";
}

.mdi-file-cog-outline::before {
  content: "\f107c";
}

.mdi-file-compare::before {
  content: "\f08aa";
}

.mdi-file-delimited::before {
  content: "\f0218";
}

.mdi-file-delimited-outline::before {
  content: "\f0ea5";
}

.mdi-file-document::before {
  content: "\f0219";
}

.mdi-file-document-edit::before {
  content: "\f0dc8";
}

.mdi-file-document-edit-outline::before {
  content: "\f0dc9";
}

.mdi-file-document-multiple::before {
  content: "\f1517";
}

.mdi-file-document-multiple-outline::before {
  content: "\f1518";
}

.mdi-file-document-outline::before {
  content: "\f09ee";
}

.mdi-file-download::before {
  content: "\f0965";
}

.mdi-file-download-outline::before {
  content: "\f0966";
}

.mdi-file-edit::before {
  content: "\f11e7";
}

.mdi-file-edit-outline::before {
  content: "\f11e8";
}

.mdi-file-excel::before {
  content: "\f021b";
}

.mdi-file-excel-box::before {
  content: "\f021c";
}

.mdi-file-excel-box-outline::before {
  content: "\f102c";
}

.mdi-file-excel-outline::before {
  content: "\f102d";
}

.mdi-file-export::before {
  content: "\f021d";
}

.mdi-file-export-outline::before {
  content: "\f102e";
}

.mdi-file-eye::before {
  content: "\f0dca";
}

.mdi-file-eye-outline::before {
  content: "\f0dcb";
}

.mdi-file-find::before {
  content: "\f021e";
}

.mdi-file-find-outline::before {
  content: "\f0b97";
}

.mdi-file-hidden::before {
  content: "\f0613";
}

.mdi-file-image::before {
  content: "\f021f";
}

.mdi-file-image-outline::before {
  content: "\f0eb0";
}

.mdi-file-import::before {
  content: "\f0220";
}

.mdi-file-import-outline::before {
  content: "\f102f";
}

.mdi-file-key::before {
  content: "\f1184";
}

.mdi-file-key-outline::before {
  content: "\f1185";
}

.mdi-file-link::before {
  content: "\f1177";
}

.mdi-file-link-outline::before {
  content: "\f1178";
}

.mdi-file-lock::before {
  content: "\f0221";
}

.mdi-file-lock-outline::before {
  content: "\f1030";
}

.mdi-file-move::before {
  content: "\f0ab9";
}

.mdi-file-move-outline::before {
  content: "\f1031";
}

.mdi-file-multiple::before {
  content: "\f0222";
}

.mdi-file-multiple-outline::before {
  content: "\f1032";
}

.mdi-file-music::before {
  content: "\f0223";
}

.mdi-file-music-outline::before {
  content: "\f0e2a";
}

.mdi-file-outline::before {
  content: "\f0224";
}

.mdi-file-pdf::before {
  content: "\f0225";
}

.mdi-file-pdf-box::before {
  content: "\f0226";
}

.mdi-file-pdf-box-outline::before {
  content: "\f0fb3";
}

.mdi-file-pdf-outline::before {
  content: "\f0e2d";
}

.mdi-file-percent::before {
  content: "\f081e";
}

.mdi-file-percent-outline::before {
  content: "\f1033";
}

.mdi-file-phone::before {
  content: "\f1179";
}

.mdi-file-phone-outline::before {
  content: "\f117a";
}

.mdi-file-plus::before {
  content: "\f0752";
}

.mdi-file-plus-outline::before {
  content: "\f0eed";
}

.mdi-file-powerpoint::before {
  content: "\f0227";
}

.mdi-file-powerpoint-box::before {
  content: "\f0228";
}

.mdi-file-powerpoint-box-outline::before {
  content: "\f1034";
}

.mdi-file-powerpoint-outline::before {
  content: "\f1035";
}

.mdi-file-presentation-box::before {
  content: "\f0229";
}

.mdi-file-question::before {
  content: "\f086f";
}

.mdi-file-question-outline::before {
  content: "\f1036";
}

.mdi-file-refresh::before {
  content: "\f0918";
}

.mdi-file-refresh-outline::before {
  content: "\f0541";
}

.mdi-file-remove::before {
  content: "\f0b98";
}

.mdi-file-remove-outline::before {
  content: "\f1037";
}

.mdi-file-replace::before {
  content: "\f0b32";
}

.mdi-file-replace-outline::before {
  content: "\f0b33";
}

.mdi-file-restore::before {
  content: "\f0670";
}

.mdi-file-restore-outline::before {
  content: "\f1038";
}

.mdi-file-search::before {
  content: "\f0c7c";
}

.mdi-file-search-outline::before {
  content: "\f0c7d";
}

.mdi-file-send::before {
  content: "\f022a";
}

.mdi-file-send-outline::before {
  content: "\f1039";
}

.mdi-file-settings::before {
  content: "\f1079";
}

.mdi-file-settings-outline::before {
  content: "\f107a";
}

.mdi-file-star::before {
  content: "\f103a";
}

.mdi-file-star-outline::before {
  content: "\f103b";
}

.mdi-file-swap::before {
  content: "\f0fb4";
}

.mdi-file-swap-outline::before {
  content: "\f0fb5";
}

.mdi-file-sync::before {
  content: "\f1216";
}

.mdi-file-sync-outline::before {
  content: "\f1217";
}

.mdi-file-table::before {
  content: "\f0c7e";
}

.mdi-file-table-box::before {
  content: "\f10e1";
}

.mdi-file-table-box-multiple::before {
  content: "\f10e2";
}

.mdi-file-table-box-multiple-outline::before {
  content: "\f10e3";
}

.mdi-file-table-box-outline::before {
  content: "\f10e4";
}

.mdi-file-table-outline::before {
  content: "\f0c7f";
}

.mdi-file-tree::before {
  content: "\f0645";
}

.mdi-file-tree-outline::before {
  content: "\f13d2";
}

.mdi-file-undo::before {
  content: "\f08dc";
}

.mdi-file-undo-outline::before {
  content: "\f103c";
}

.mdi-file-upload::before {
  content: "\f0a4d";
}

.mdi-file-upload-outline::before {
  content: "\f0a4e";
}

.mdi-file-video::before {
  content: "\f022b";
}

.mdi-file-video-outline::before {
  content: "\f0e2c";
}

.mdi-file-word::before {
  content: "\f022c";
}

.mdi-file-word-box::before {
  content: "\f022d";
}

.mdi-file-word-box-outline::before {
  content: "\f103d";
}

.mdi-file-word-outline::before {
  content: "\f103e";
}

.mdi-film::before {
  content: "\f022f";
}

.mdi-filmstrip::before {
  content: "\f0230";
}

.mdi-filmstrip-box::before {
  content: "\f0332";
}

.mdi-filmstrip-box-multiple::before {
  content: "\f0d18";
}

.mdi-filmstrip-off::before {
  content: "\f0231";
}

.mdi-filter::before {
  content: "\f0232";
}

.mdi-filter-menu::before {
  content: "\f10e5";
}

.mdi-filter-menu-outline::before {
  content: "\f10e6";
}

.mdi-filter-minus::before {
  content: "\f0eee";
}

.mdi-filter-minus-outline::before {
  content: "\f0eef";
}

.mdi-filter-off::before {
  content: "\f14ef";
}

.mdi-filter-off-outline::before {
  content: "\f14f0";
}

.mdi-filter-outline::before {
  content: "\f0233";
}

.mdi-filter-plus::before {
  content: "\f0ef0";
}

.mdi-filter-plus-outline::before {
  content: "\f0ef1";
}

.mdi-filter-remove::before {
  content: "\f0234";
}

.mdi-filter-remove-outline::before {
  content: "\f0235";
}

.mdi-filter-variant::before {
  content: "\f0236";
}

.mdi-filter-variant-minus::before {
  content: "\f1112";
}

.mdi-filter-variant-plus::before {
  content: "\f1113";
}

.mdi-filter-variant-remove::before {
  content: "\f103f";
}

.mdi-finance::before {
  content: "\f081f";
}

.mdi-find-replace::before {
  content: "\f06d4";
}

.mdi-fingerprint::before {
  content: "\f0237";
}

.mdi-fingerprint-off::before {
  content: "\f0eb1";
}

.mdi-fire::before {
  content: "\f0238";
}

.mdi-fire-alert::before {
  content: "\f15d7";
}

.mdi-fire-extinguisher::before {
  content: "\f0ef2";
}

.mdi-fire-hydrant::before {
  content: "\f1137";
}

.mdi-fire-hydrant-alert::before {
  content: "\f1138";
}

.mdi-fire-hydrant-off::before {
  content: "\f1139";
}

.mdi-fire-truck::before {
  content: "\f08ab";
}

.mdi-firebase::before {
  content: "\f0967";
}

.mdi-firefox::before {
  content: "\f0239";
}

.mdi-fireplace::before {
  content: "\f0e2e";
}

.mdi-fireplace-off::before {
  content: "\f0e2f";
}

.mdi-firework::before {
  content: "\f0e30";
}

.mdi-fish::before {
  content: "\f023a";
}

.mdi-fish-off::before {
  content: "\f13f3";
}

.mdi-fishbowl::before {
  content: "\f0ef3";
}

.mdi-fishbowl-outline::before {
  content: "\f0ef4";
}

.mdi-fit-to-page::before {
  content: "\f0ef5";
}

.mdi-fit-to-page-outline::before {
  content: "\f0ef6";
}

.mdi-flag::before {
  content: "\f023b";
}

.mdi-flag-checkered::before {
  content: "\f023c";
}

.mdi-flag-minus::before {
  content: "\f0b99";
}

.mdi-flag-minus-outline::before {
  content: "\f10b2";
}

.mdi-flag-outline::before {
  content: "\f023d";
}

.mdi-flag-plus::before {
  content: "\f0b9a";
}

.mdi-flag-plus-outline::before {
  content: "\f10b3";
}

.mdi-flag-remove::before {
  content: "\f0b9b";
}

.mdi-flag-remove-outline::before {
  content: "\f10b4";
}

.mdi-flag-triangle::before {
  content: "\f023f";
}

.mdi-flag-variant::before {
  content: "\f0240";
}

.mdi-flag-variant-outline::before {
  content: "\f023e";
}

.mdi-flare::before {
  content: "\f0d72";
}

.mdi-flash::before {
  content: "\f0241";
}

.mdi-flash-alert::before {
  content: "\f0ef7";
}

.mdi-flash-alert-outline::before {
  content: "\f0ef8";
}

.mdi-flash-auto::before {
  content: "\f0242";
}

.mdi-flash-circle::before {
  content: "\f0820";
}

.mdi-flash-off::before {
  content: "\f0243";
}

.mdi-flash-outline::before {
  content: "\f06d5";
}

.mdi-flash-red-eye::before {
  content: "\f067b";
}

.mdi-flashlight::before {
  content: "\f0244";
}

.mdi-flashlight-off::before {
  content: "\f0245";
}

.mdi-flask::before {
  content: "\f0093";
}

.mdi-flask-empty::before {
  content: "\f0094";
}

.mdi-flask-empty-minus::before {
  content: "\f123a";
}

.mdi-flask-empty-minus-outline::before {
  content: "\f123b";
}

.mdi-flask-empty-off::before {
  content: "\f13f4";
}

.mdi-flask-empty-off-outline::before {
  content: "\f13f5";
}

.mdi-flask-empty-outline::before {
  content: "\f0095";
}

.mdi-flask-empty-plus::before {
  content: "\f123c";
}

.mdi-flask-empty-plus-outline::before {
  content: "\f123d";
}

.mdi-flask-empty-remove::before {
  content: "\f123e";
}

.mdi-flask-empty-remove-outline::before {
  content: "\f123f";
}

.mdi-flask-minus::before {
  content: "\f1240";
}

.mdi-flask-minus-outline::before {
  content: "\f1241";
}

.mdi-flask-off::before {
  content: "\f13f6";
}

.mdi-flask-off-outline::before {
  content: "\f13f7";
}

.mdi-flask-outline::before {
  content: "\f0096";
}

.mdi-flask-plus::before {
  content: "\f1242";
}

.mdi-flask-plus-outline::before {
  content: "\f1243";
}

.mdi-flask-remove::before {
  content: "\f1244";
}

.mdi-flask-remove-outline::before {
  content: "\f1245";
}

.mdi-flask-round-bottom::before {
  content: "\f124b";
}

.mdi-flask-round-bottom-empty::before {
  content: "\f124c";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "\f124d";
}

.mdi-flask-round-bottom-outline::before {
  content: "\f124e";
}

.mdi-fleur-de-lis::before {
  content: "\f1303";
}

.mdi-flip-horizontal::before {
  content: "\f10e7";
}

.mdi-flip-to-back::before {
  content: "\f0247";
}

.mdi-flip-to-front::before {
  content: "\f0248";
}

.mdi-flip-vertical::before {
  content: "\f10e8";
}

.mdi-floor-lamp::before {
  content: "\f08dd";
}

.mdi-floor-lamp-dual::before {
  content: "\f1040";
}

.mdi-floor-lamp-variant::before {
  content: "\f1041";
}

.mdi-floor-plan::before {
  content: "\f0821";
}

.mdi-floppy::before {
  content: "\f0249";
}

.mdi-floppy-variant::before {
  content: "\f09ef";
}

.mdi-flower::before {
  content: "\f024a";
}

.mdi-flower-outline::before {
  content: "\f09f0";
}

.mdi-flower-poppy::before {
  content: "\f0d08";
}

.mdi-flower-tulip::before {
  content: "\f09f1";
}

.mdi-flower-tulip-outline::before {
  content: "\f09f2";
}

.mdi-focus-auto::before {
  content: "\f0f4e";
}

.mdi-focus-field::before {
  content: "\f0f4f";
}

.mdi-focus-field-horizontal::before {
  content: "\f0f50";
}

.mdi-focus-field-vertical::before {
  content: "\f0f51";
}

.mdi-folder::before {
  content: "\f024b";
}

.mdi-folder-account::before {
  content: "\f024c";
}

.mdi-folder-account-outline::before {
  content: "\f0b9c";
}

.mdi-folder-alert::before {
  content: "\f0dcc";
}

.mdi-folder-alert-outline::before {
  content: "\f0dcd";
}

.mdi-folder-clock::before {
  content: "\f0aba";
}

.mdi-folder-clock-outline::before {
  content: "\f0abb";
}

.mdi-folder-cog::before {
  content: "\f107f";
}

.mdi-folder-cog-outline::before {
  content: "\f1080";
}

.mdi-folder-download::before {
  content: "\f024d";
}

.mdi-folder-download-outline::before {
  content: "\f10e9";
}

.mdi-folder-edit::before {
  content: "\f08de";
}

.mdi-folder-edit-outline::before {
  content: "\f0dce";
}

.mdi-folder-google-drive::before {
  content: "\f024e";
}

.mdi-folder-heart::before {
  content: "\f10ea";
}

.mdi-folder-heart-outline::before {
  content: "\f10eb";
}

.mdi-folder-home::before {
  content: "\f10b5";
}

.mdi-folder-home-outline::before {
  content: "\f10b6";
}

.mdi-folder-image::before {
  content: "\f024f";
}

.mdi-folder-information::before {
  content: "\f10b7";
}

.mdi-folder-information-outline::before {
  content: "\f10b8";
}

.mdi-folder-key::before {
  content: "\f08ac";
}

.mdi-folder-key-network::before {
  content: "\f08ad";
}

.mdi-folder-key-network-outline::before {
  content: "\f0c80";
}

.mdi-folder-key-outline::before {
  content: "\f10ec";
}

.mdi-folder-lock::before {
  content: "\f0250";
}

.mdi-folder-lock-open::before {
  content: "\f0251";
}

.mdi-folder-marker::before {
  content: "\f126d";
}

.mdi-folder-marker-outline::before {
  content: "\f126e";
}

.mdi-folder-move::before {
  content: "\f0252";
}

.mdi-folder-move-outline::before {
  content: "\f1246";
}

.mdi-folder-multiple::before {
  content: "\f0253";
}

.mdi-folder-multiple-image::before {
  content: "\f0254";
}

.mdi-folder-multiple-outline::before {
  content: "\f0255";
}

.mdi-folder-multiple-plus::before {
  content: "\f147e";
}

.mdi-folder-multiple-plus-outline::before {
  content: "\f147f";
}

.mdi-folder-music::before {
  content: "\f1359";
}

.mdi-folder-music-outline::before {
  content: "\f135a";
}

.mdi-folder-network::before {
  content: "\f0870";
}

.mdi-folder-network-outline::before {
  content: "\f0c81";
}

.mdi-folder-open::before {
  content: "\f0770";
}

.mdi-folder-open-outline::before {
  content: "\f0dcf";
}

.mdi-folder-outline::before {
  content: "\f0256";
}

.mdi-folder-plus::before {
  content: "\f0257";
}

.mdi-folder-plus-outline::before {
  content: "\f0b9d";
}

.mdi-folder-pound::before {
  content: "\f0d09";
}

.mdi-folder-pound-outline::before {
  content: "\f0d0a";
}

.mdi-folder-refresh::before {
  content: "\f0749";
}

.mdi-folder-refresh-outline::before {
  content: "\f0542";
}

.mdi-folder-remove::before {
  content: "\f0258";
}

.mdi-folder-remove-outline::before {
  content: "\f0b9e";
}

.mdi-folder-search::before {
  content: "\f0968";
}

.mdi-folder-search-outline::before {
  content: "\f0969";
}

.mdi-folder-settings::before {
  content: "\f107d";
}

.mdi-folder-settings-outline::before {
  content: "\f107e";
}

.mdi-folder-star::before {
  content: "\f069d";
}

.mdi-folder-star-multiple::before {
  content: "\f13d3";
}

.mdi-folder-star-multiple-outline::before {
  content: "\f13d4";
}

.mdi-folder-star-outline::before {
  content: "\f0b9f";
}

.mdi-folder-swap::before {
  content: "\f0fb6";
}

.mdi-folder-swap-outline::before {
  content: "\f0fb7";
}

.mdi-folder-sync::before {
  content: "\f0d0b";
}

.mdi-folder-sync-outline::before {
  content: "\f0d0c";
}

.mdi-folder-table::before {
  content: "\f12e3";
}

.mdi-folder-table-outline::before {
  content: "\f12e4";
}

.mdi-folder-text::before {
  content: "\f0c82";
}

.mdi-folder-text-outline::before {
  content: "\f0c83";
}

.mdi-folder-upload::before {
  content: "\f0259";
}

.mdi-folder-upload-outline::before {
  content: "\f10ed";
}

.mdi-folder-zip::before {
  content: "\f06eb";
}

.mdi-folder-zip-outline::before {
  content: "\f07b9";
}

.mdi-font-awesome::before {
  content: "\f003a";
}

.mdi-food::before {
  content: "\f025a";
}

.mdi-food-apple::before {
  content: "\f025b";
}

.mdi-food-apple-outline::before {
  content: "\f0c84";
}

.mdi-food-croissant::before {
  content: "\f07c8";
}

.mdi-food-drumstick::before {
  content: "\f141f";
}

.mdi-food-drumstick-off::before {
  content: "\f1468";
}

.mdi-food-drumstick-off-outline::before {
  content: "\f1469";
}

.mdi-food-drumstick-outline::before {
  content: "\f1420";
}

.mdi-food-fork-drink::before {
  content: "\f05f2";
}

.mdi-food-halal::before {
  content: "\f1572";
}

.mdi-food-kosher::before {
  content: "\f1573";
}

.mdi-food-off::before {
  content: "\f05f3";
}

.mdi-food-steak::before {
  content: "\f146a";
}

.mdi-food-steak-off::before {
  content: "\f146b";
}

.mdi-food-variant::before {
  content: "\f025c";
}

.mdi-food-variant-off::before {
  content: "\f13e5";
}

.mdi-foot-print::before {
  content: "\f0f52";
}

.mdi-football::before {
  content: "\f025d";
}

.mdi-football-australian::before {
  content: "\f025e";
}

.mdi-football-helmet::before {
  content: "\f025f";
}

.mdi-forklift::before {
  content: "\f07c9";
}

.mdi-form-dropdown::before {
  content: "\f1400";
}

.mdi-form-select::before {
  content: "\f1401";
}

.mdi-form-textarea::before {
  content: "\f1095";
}

.mdi-form-textbox::before {
  content: "\f060e";
}

.mdi-form-textbox-lock::before {
  content: "\f135d";
}

.mdi-form-textbox-password::before {
  content: "\f07f5";
}

.mdi-format-align-bottom::before {
  content: "\f0753";
}

.mdi-format-align-center::before {
  content: "\f0260";
}

.mdi-format-align-justify::before {
  content: "\f0261";
}

.mdi-format-align-left::before {
  content: "\f0262";
}

.mdi-format-align-middle::before {
  content: "\f0754";
}

.mdi-format-align-right::before {
  content: "\f0263";
}

.mdi-format-align-top::before {
  content: "\f0755";
}

.mdi-format-annotation-minus::before {
  content: "\f0abc";
}

.mdi-format-annotation-plus::before {
  content: "\f0646";
}

.mdi-format-bold::before {
  content: "\f0264";
}

.mdi-format-clear::before {
  content: "\f0265";
}

.mdi-format-color-fill::before {
  content: "\f0266";
}

.mdi-format-color-highlight::before {
  content: "\f0e31";
}

.mdi-format-color-marker-cancel::before {
  content: "\f1313";
}

.mdi-format-color-text::before {
  content: "\f069e";
}

.mdi-format-columns::before {
  content: "\f08df";
}

.mdi-format-float-center::before {
  content: "\f0267";
}

.mdi-format-float-left::before {
  content: "\f0268";
}

.mdi-format-float-none::before {
  content: "\f0269";
}

.mdi-format-float-right::before {
  content: "\f026a";
}

.mdi-format-font::before {
  content: "\f06d6";
}

.mdi-format-font-size-decrease::before {
  content: "\f09f3";
}

.mdi-format-font-size-increase::before {
  content: "\f09f4";
}

.mdi-format-header-1::before {
  content: "\f026b";
}

.mdi-format-header-2::before {
  content: "\f026c";
}

.mdi-format-header-3::before {
  content: "\f026d";
}

.mdi-format-header-4::before {
  content: "\f026e";
}

.mdi-format-header-5::before {
  content: "\f026f";
}

.mdi-format-header-6::before {
  content: "\f0270";
}

.mdi-format-header-decrease::before {
  content: "\f0271";
}

.mdi-format-header-equal::before {
  content: "\f0272";
}

.mdi-format-header-increase::before {
  content: "\f0273";
}

.mdi-format-header-pound::before {
  content: "\f0274";
}

.mdi-format-horizontal-align-center::before {
  content: "\f061e";
}

.mdi-format-horizontal-align-left::before {
  content: "\f061f";
}

.mdi-format-horizontal-align-right::before {
  content: "\f0620";
}

.mdi-format-indent-decrease::before {
  content: "\f0275";
}

.mdi-format-indent-increase::before {
  content: "\f0276";
}

.mdi-format-italic::before {
  content: "\f0277";
}

.mdi-format-letter-case::before {
  content: "\f0b34";
}

.mdi-format-letter-case-lower::before {
  content: "\f0b35";
}

.mdi-format-letter-case-upper::before {
  content: "\f0b36";
}

.mdi-format-letter-ends-with::before {
  content: "\f0fb8";
}

.mdi-format-letter-matches::before {
  content: "\f0fb9";
}

.mdi-format-letter-starts-with::before {
  content: "\f0fba";
}

.mdi-format-line-spacing::before {
  content: "\f0278";
}

.mdi-format-line-style::before {
  content: "\f05c8";
}

.mdi-format-line-weight::before {
  content: "\f05c9";
}

.mdi-format-list-bulleted::before {
  content: "\f0279";
}

.mdi-format-list-bulleted-square::before {
  content: "\f0dd0";
}

.mdi-format-list-bulleted-triangle::before {
  content: "\f0eb2";
}

.mdi-format-list-bulleted-type::before {
  content: "\f027a";
}

.mdi-format-list-checkbox::before {
  content: "\f096a";
}

.mdi-format-list-checks::before {
  content: "\f0756";
}

.mdi-format-list-numbered::before {
  content: "\f027b";
}

.mdi-format-list-numbered-rtl::before {
  content: "\f0d0d";
}

.mdi-format-list-text::before {
  content: "\f126f";
}

.mdi-format-overline::before {
  content: "\f0eb3";
}

.mdi-format-page-break::before {
  content: "\f06d7";
}

.mdi-format-paint::before {
  content: "\f027c";
}

.mdi-format-paragraph::before {
  content: "\f027d";
}

.mdi-format-pilcrow::before {
  content: "\f06d8";
}

.mdi-format-quote-close::before {
  content: "\f027e";
}

.mdi-format-quote-close-outline::before {
  content: "\f11a8";
}

.mdi-format-quote-open::before {
  content: "\f0757";
}

.mdi-format-quote-open-outline::before {
  content: "\f11a7";
}

.mdi-format-rotate-90::before {
  content: "\f06aa";
}

.mdi-format-section::before {
  content: "\f069f";
}

.mdi-format-size::before {
  content: "\f027f";
}

.mdi-format-strikethrough::before {
  content: "\f0280";
}

.mdi-format-strikethrough-variant::before {
  content: "\f0281";
}

.mdi-format-subscript::before {
  content: "\f0282";
}

.mdi-format-superscript::before {
  content: "\f0283";
}

.mdi-format-text::before {
  content: "\f0284";
}

.mdi-format-text-rotation-angle-down::before {
  content: "\f0fbb";
}

.mdi-format-text-rotation-angle-up::before {
  content: "\f0fbc";
}

.mdi-format-text-rotation-down::before {
  content: "\f0d73";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "\f0fbd";
}

.mdi-format-text-rotation-none::before {
  content: "\f0d74";
}

.mdi-format-text-rotation-up::before {
  content: "\f0fbe";
}

.mdi-format-text-rotation-vertical::before {
  content: "\f0fbf";
}

.mdi-format-text-variant::before {
  content: "\f0e32";
}

.mdi-format-text-variant-outline::before {
  content: "\f150f";
}

.mdi-format-text-wrapping-clip::before {
  content: "\f0d0e";
}

.mdi-format-text-wrapping-overflow::before {
  content: "\f0d0f";
}

.mdi-format-text-wrapping-wrap::before {
  content: "\f0d10";
}

.mdi-format-textbox::before {
  content: "\f0d11";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f0285";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f0286";
}

.mdi-format-title::before {
  content: "\f05f4";
}

.mdi-format-underline::before {
  content: "\f0287";
}

.mdi-format-vertical-align-bottom::before {
  content: "\f0621";
}

.mdi-format-vertical-align-center::before {
  content: "\f0622";
}

.mdi-format-vertical-align-top::before {
  content: "\f0623";
}

.mdi-format-wrap-inline::before {
  content: "\f0288";
}

.mdi-format-wrap-square::before {
  content: "\f0289";
}

.mdi-format-wrap-tight::before {
  content: "\f028a";
}

.mdi-format-wrap-top-bottom::before {
  content: "\f028b";
}

.mdi-forum::before {
  content: "\f028c";
}

.mdi-forum-outline::before {
  content: "\f0822";
}

.mdi-forward::before {
  content: "\f028d";
}

.mdi-forwardburger::before {
  content: "\f0d75";
}

.mdi-fountain::before {
  content: "\f096b";
}

.mdi-fountain-pen::before {
  content: "\f0d12";
}

.mdi-fountain-pen-tip::before {
  content: "\f0d13";
}

.mdi-freebsd::before {
  content: "\f08e0";
}

.mdi-frequently-asked-questions::before {
  content: "\f0eb4";
}

.mdi-fridge::before {
  content: "\f0290";
}

.mdi-fridge-alert::before {
  content: "\f11b1";
}

.mdi-fridge-alert-outline::before {
  content: "\f11b2";
}

.mdi-fridge-bottom::before {
  content: "\f0292";
}

.mdi-fridge-industrial::before {
  content: "\f15ee";
}

.mdi-fridge-industrial-alert::before {
  content: "\f15ef";
}

.mdi-fridge-industrial-alert-outline::before {
  content: "\f15f0";
}

.mdi-fridge-industrial-off::before {
  content: "\f15f1";
}

.mdi-fridge-industrial-off-outline::before {
  content: "\f15f2";
}

.mdi-fridge-industrial-outline::before {
  content: "\f15f3";
}

.mdi-fridge-off::before {
  content: "\f11af";
}

.mdi-fridge-off-outline::before {
  content: "\f11b0";
}

.mdi-fridge-outline::before {
  content: "\f028f";
}

.mdi-fridge-top::before {
  content: "\f0291";
}

.mdi-fridge-variant::before {
  content: "\f15f4";
}

.mdi-fridge-variant-alert::before {
  content: "\f15f5";
}

.mdi-fridge-variant-alert-outline::before {
  content: "\f15f6";
}

.mdi-fridge-variant-off::before {
  content: "\f15f7";
}

.mdi-fridge-variant-off-outline::before {
  content: "\f15f8";
}

.mdi-fridge-variant-outline::before {
  content: "\f15f9";
}

.mdi-fruit-cherries::before {
  content: "\f1042";
}

.mdi-fruit-cherries-off::before {
  content: "\f13f8";
}

.mdi-fruit-citrus::before {
  content: "\f1043";
}

.mdi-fruit-citrus-off::before {
  content: "\f13f9";
}

.mdi-fruit-grapes::before {
  content: "\f1044";
}

.mdi-fruit-grapes-outline::before {
  content: "\f1045";
}

.mdi-fruit-pineapple::before {
  content: "\f1046";
}

.mdi-fruit-watermelon::before {
  content: "\f1047";
}

.mdi-fuel::before {
  content: "\f07ca";
}

.mdi-fullscreen::before {
  content: "\f0293";
}

.mdi-fullscreen-exit::before {
  content: "\f0294";
}

.mdi-function::before {
  content: "\f0295";
}

.mdi-function-variant::before {
  content: "\f0871";
}

.mdi-furigana-horizontal::before {
  content: "\f1081";
}

.mdi-furigana-vertical::before {
  content: "\f1082";
}

.mdi-fuse::before {
  content: "\f0c85";
}

.mdi-fuse-alert::before {
  content: "\f142d";
}

.mdi-fuse-blade::before {
  content: "\f0c86";
}

.mdi-fuse-off::before {
  content: "\f142c";
}

.mdi-gamepad::before {
  content: "\f0296";
}

.mdi-gamepad-circle::before {
  content: "\f0e33";
}

.mdi-gamepad-circle-down::before {
  content: "\f0e34";
}

.mdi-gamepad-circle-left::before {
  content: "\f0e35";
}

.mdi-gamepad-circle-outline::before {
  content: "\f0e36";
}

.mdi-gamepad-circle-right::before {
  content: "\f0e37";
}

.mdi-gamepad-circle-up::before {
  content: "\f0e38";
}

.mdi-gamepad-down::before {
  content: "\f0e39";
}

.mdi-gamepad-left::before {
  content: "\f0e3a";
}

.mdi-gamepad-right::before {
  content: "\f0e3b";
}

.mdi-gamepad-round::before {
  content: "\f0e3c";
}

.mdi-gamepad-round-down::before {
  content: "\f0e3d";
}

.mdi-gamepad-round-left::before {
  content: "\f0e3e";
}

.mdi-gamepad-round-outline::before {
  content: "\f0e3f";
}

.mdi-gamepad-round-right::before {
  content: "\f0e40";
}

.mdi-gamepad-round-up::before {
  content: "\f0e41";
}

.mdi-gamepad-square::before {
  content: "\f0eb5";
}

.mdi-gamepad-square-outline::before {
  content: "\f0eb6";
}

.mdi-gamepad-up::before {
  content: "\f0e42";
}

.mdi-gamepad-variant::before {
  content: "\f0297";
}

.mdi-gamepad-variant-outline::before {
  content: "\f0eb7";
}

.mdi-gamma::before {
  content: "\f10ee";
}

.mdi-gantry-crane::before {
  content: "\f0dd1";
}

.mdi-garage::before {
  content: "\f06d9";
}

.mdi-garage-alert::before {
  content: "\f0872";
}

.mdi-garage-alert-variant::before {
  content: "\f12d5";
}

.mdi-garage-open::before {
  content: "\f06da";
}

.mdi-garage-open-variant::before {
  content: "\f12d4";
}

.mdi-garage-variant::before {
  content: "\f12d3";
}

.mdi-gas-cylinder::before {
  content: "\f0647";
}

.mdi-gas-station::before {
  content: "\f0298";
}

.mdi-gas-station-off::before {
  content: "\f1409";
}

.mdi-gas-station-off-outline::before {
  content: "\f140a";
}

.mdi-gas-station-outline::before {
  content: "\f0eb8";
}

.mdi-gate::before {
  content: "\f0299";
}

.mdi-gate-and::before {
  content: "\f08e1";
}

.mdi-gate-arrow-right::before {
  content: "\f1169";
}

.mdi-gate-nand::before {
  content: "\f08e2";
}

.mdi-gate-nor::before {
  content: "\f08e3";
}

.mdi-gate-not::before {
  content: "\f08e4";
}

.mdi-gate-open::before {
  content: "\f116a";
}

.mdi-gate-or::before {
  content: "\f08e5";
}

.mdi-gate-xnor::before {
  content: "\f08e6";
}

.mdi-gate-xor::before {
  content: "\f08e7";
}

.mdi-gatsby::before {
  content: "\f0e43";
}

.mdi-gauge::before {
  content: "\f029a";
}

.mdi-gauge-empty::before {
  content: "\f0873";
}

.mdi-gauge-full::before {
  content: "\f0874";
}

.mdi-gauge-low::before {
  content: "\f0875";
}

.mdi-gavel::before {
  content: "\f029b";
}

.mdi-gender-female::before {
  content: "\f029c";
}

.mdi-gender-male::before {
  content: "\f029d";
}

.mdi-gender-male-female::before {
  content: "\f029e";
}

.mdi-gender-male-female-variant::before {
  content: "\f113f";
}

.mdi-gender-non-binary::before {
  content: "\f1140";
}

.mdi-gender-transgender::before {
  content: "\f029f";
}

.mdi-gentoo::before {
  content: "\f08e8";
}

.mdi-gesture::before {
  content: "\f07cb";
}

.mdi-gesture-double-tap::before {
  content: "\f073c";
}

.mdi-gesture-pinch::before {
  content: "\f0abd";
}

.mdi-gesture-spread::before {
  content: "\f0abe";
}

.mdi-gesture-swipe::before {
  content: "\f0d76";
}

.mdi-gesture-swipe-down::before {
  content: "\f073d";
}

.mdi-gesture-swipe-horizontal::before {
  content: "\f0abf";
}

.mdi-gesture-swipe-left::before {
  content: "\f073e";
}

.mdi-gesture-swipe-right::before {
  content: "\f073f";
}

.mdi-gesture-swipe-up::before {
  content: "\f0740";
}

.mdi-gesture-swipe-vertical::before {
  content: "\f0ac0";
}

.mdi-gesture-tap::before {
  content: "\f0741";
}

.mdi-gesture-tap-box::before {
  content: "\f12a9";
}

.mdi-gesture-tap-button::before {
  content: "\f12a8";
}

.mdi-gesture-tap-hold::before {
  content: "\f0d77";
}

.mdi-gesture-two-double-tap::before {
  content: "\f0742";
}

.mdi-gesture-two-tap::before {
  content: "\f0743";
}

.mdi-ghost::before {
  content: "\f02a0";
}

.mdi-ghost-off::before {
  content: "\f09f5";
}

.mdi-ghost-off-outline::before {
  content: "\f165c";
}

.mdi-ghost-outline::before {
  content: "\f165d";
}

.mdi-gif::before {
  content: "\f0d78";
}

.mdi-gift::before {
  content: "\f0e44";
}

.mdi-gift-outline::before {
  content: "\f02a1";
}

.mdi-git::before {
  content: "\f02a2";
}

.mdi-github::before {
  content: "\f02a4";
}

.mdi-gitlab::before {
  content: "\f0ba0";
}

.mdi-glass-cocktail::before {
  content: "\f0356";
}

.mdi-glass-cocktail-off::before {
  content: "\f15e6";
}

.mdi-glass-flute::before {
  content: "\f02a5";
}

.mdi-glass-mug::before {
  content: "\f02a6";
}

.mdi-glass-mug-off::before {
  content: "\f15e7";
}

.mdi-glass-mug-variant::before {
  content: "\f1116";
}

.mdi-glass-mug-variant-off::before {
  content: "\f15e8";
}

.mdi-glass-pint-outline::before {
  content: "\f130d";
}

.mdi-glass-stange::before {
  content: "\f02a7";
}

.mdi-glass-tulip::before {
  content: "\f02a8";
}

.mdi-glass-wine::before {
  content: "\f0876";
}

.mdi-glasses::before {
  content: "\f02aa";
}

.mdi-globe-light::before {
  content: "\f12d7";
}

.mdi-globe-model::before {
  content: "\f08e9";
}

.mdi-gmail::before {
  content: "\f02ab";
}

.mdi-gnome::before {
  content: "\f02ac";
}

.mdi-go-kart::before {
  content: "\f0d79";
}

.mdi-go-kart-track::before {
  content: "\f0d7a";
}

.mdi-gog::before {
  content: "\f0ba1";
}

.mdi-gold::before {
  content: "\f124f";
}

.mdi-golf::before {
  content: "\f0823";
}

.mdi-golf-cart::before {
  content: "\f11a4";
}

.mdi-golf-tee::before {
  content: "\f1083";
}

.mdi-gondola::before {
  content: "\f0686";
}

.mdi-goodreads::before {
  content: "\f0d7b";
}

.mdi-google::before {
  content: "\f02ad";
}

.mdi-google-ads::before {
  content: "\f0c87";
}

.mdi-google-analytics::before {
  content: "\f07cc";
}

.mdi-google-assistant::before {
  content: "\f07cd";
}

.mdi-google-cardboard::before {
  content: "\f02ae";
}

.mdi-google-chrome::before {
  content: "\f02af";
}

.mdi-google-circles::before {
  content: "\f02b0";
}

.mdi-google-circles-communities::before {
  content: "\f02b1";
}

.mdi-google-circles-extended::before {
  content: "\f02b2";
}

.mdi-google-circles-group::before {
  content: "\f02b3";
}

.mdi-google-classroom::before {
  content: "\f02c0";
}

.mdi-google-cloud::before {
  content: "\f11f6";
}

.mdi-google-controller::before {
  content: "\f02b4";
}

.mdi-google-controller-off::before {
  content: "\f02b5";
}

.mdi-google-downasaur::before {
  content: "\f1362";
}

.mdi-google-drive::before {
  content: "\f02b6";
}

.mdi-google-earth::before {
  content: "\f02b7";
}

.mdi-google-fit::before {
  content: "\f096c";
}

.mdi-google-glass::before {
  content: "\f02b8";
}

.mdi-google-hangouts::before {
  content: "\f02c9";
}

.mdi-google-home::before {
  content: "\f0824";
}

.mdi-google-keep::before {
  content: "\f06dc";
}

.mdi-google-lens::before {
  content: "\f09f6";
}

.mdi-google-maps::before {
  content: "\f05f5";
}

.mdi-google-my-business::before {
  content: "\f1048";
}

.mdi-google-nearby::before {
  content: "\f02b9";
}

.mdi-google-photos::before {
  content: "\f06dd";
}

.mdi-google-play::before {
  content: "\f02bc";
}

.mdi-google-plus::before {
  content: "\f02bd";
}

.mdi-google-podcast::before {
  content: "\f0eb9";
}

.mdi-google-spreadsheet::before {
  content: "\f09f7";
}

.mdi-google-street-view::before {
  content: "\f0c88";
}

.mdi-google-translate::before {
  content: "\f02bf";
}

.mdi-gradient::before {
  content: "\f06a0";
}

.mdi-grain::before {
  content: "\f0d7c";
}

.mdi-graph::before {
  content: "\f1049";
}

.mdi-graph-outline::before {
  content: "\f104a";
}

.mdi-graphql::before {
  content: "\f0877";
}

.mdi-grass::before {
  content: "\f1510";
}

.mdi-grave-stone::before {
  content: "\f0ba2";
}

.mdi-grease-pencil::before {
  content: "\f0648";
}

.mdi-greater-than::before {
  content: "\f096d";
}

.mdi-greater-than-or-equal::before {
  content: "\f096e";
}

.mdi-grid::before {
  content: "\f02c1";
}

.mdi-grid-large::before {
  content: "\f0758";
}

.mdi-grid-off::before {
  content: "\f02c2";
}

.mdi-grill::before {
  content: "\f0e45";
}

.mdi-grill-outline::before {
  content: "\f118a";
}

.mdi-group::before {
  content: "\f02c3";
}

.mdi-guitar-acoustic::before {
  content: "\f0771";
}

.mdi-guitar-electric::before {
  content: "\f02c4";
}

.mdi-guitar-pick::before {
  content: "\f02c5";
}

.mdi-guitar-pick-outline::before {
  content: "\f02c6";
}

.mdi-guy-fawkes-mask::before {
  content: "\f0825";
}

.mdi-hail::before {
  content: "\f0ac1";
}

.mdi-hair-dryer::before {
  content: "\f10ef";
}

.mdi-hair-dryer-outline::before {
  content: "\f10f0";
}

.mdi-halloween::before {
  content: "\f0ba3";
}

.mdi-hamburger::before {
  content: "\f0685";
}

.mdi-hammer::before {
  content: "\f08ea";
}

.mdi-hammer-screwdriver::before {
  content: "\f1322";
}

.mdi-hammer-wrench::before {
  content: "\f1323";
}

.mdi-hand::before {
  content: "\f0a4f";
}

.mdi-hand-heart::before {
  content: "\f10f1";
}

.mdi-hand-heart-outline::before {
  content: "\f157e";
}

.mdi-hand-left::before {
  content: "\f0e46";
}

.mdi-hand-okay::before {
  content: "\f0a50";
}

.mdi-hand-peace::before {
  content: "\f0a51";
}

.mdi-hand-peace-variant::before {
  content: "\f0a52";
}

.mdi-hand-pointing-down::before {
  content: "\f0a53";
}

.mdi-hand-pointing-left::before {
  content: "\f0a54";
}

.mdi-hand-pointing-right::before {
  content: "\f02c7";
}

.mdi-hand-pointing-up::before {
  content: "\f0a55";
}

.mdi-hand-right::before {
  content: "\f0e47";
}

.mdi-hand-saw::before {
  content: "\f0e48";
}

.mdi-hand-wash::before {
  content: "\f157f";
}

.mdi-hand-wash-outline::before {
  content: "\f1580";
}

.mdi-hand-water::before {
  content: "\f139f";
}

.mdi-handball::before {
  content: "\f0f53";
}

.mdi-handcuffs::before {
  content: "\f113e";
}

.mdi-handshake::before {
  content: "\f1218";
}

.mdi-handshake-outline::before {
  content: "\f15a1";
}

.mdi-hanger::before {
  content: "\f02c8";
}

.mdi-hard-hat::before {
  content: "\f096f";
}

.mdi-harddisk::before {
  content: "\f02ca";
}

.mdi-harddisk-plus::before {
  content: "\f104b";
}

.mdi-harddisk-remove::before {
  content: "\f104c";
}

.mdi-hat-fedora::before {
  content: "\f0ba4";
}

.mdi-hazard-lights::before {
  content: "\f0c89";
}

.mdi-hdr::before {
  content: "\f0d7d";
}

.mdi-hdr-off::before {
  content: "\f0d7e";
}

.mdi-head::before {
  content: "\f135e";
}

.mdi-head-alert::before {
  content: "\f1338";
}

.mdi-head-alert-outline::before {
  content: "\f1339";
}

.mdi-head-check::before {
  content: "\f133a";
}

.mdi-head-check-outline::before {
  content: "\f133b";
}

.mdi-head-cog::before {
  content: "\f133c";
}

.mdi-head-cog-outline::before {
  content: "\f133d";
}

.mdi-head-dots-horizontal::before {
  content: "\f133e";
}

.mdi-head-dots-horizontal-outline::before {
  content: "\f133f";
}

.mdi-head-flash::before {
  content: "\f1340";
}

.mdi-head-flash-outline::before {
  content: "\f1341";
}

.mdi-head-heart::before {
  content: "\f1342";
}

.mdi-head-heart-outline::before {
  content: "\f1343";
}

.mdi-head-lightbulb::before {
  content: "\f1344";
}

.mdi-head-lightbulb-outline::before {
  content: "\f1345";
}

.mdi-head-minus::before {
  content: "\f1346";
}

.mdi-head-minus-outline::before {
  content: "\f1347";
}

.mdi-head-outline::before {
  content: "\f135f";
}

.mdi-head-plus::before {
  content: "\f1348";
}

.mdi-head-plus-outline::before {
  content: "\f1349";
}

.mdi-head-question::before {
  content: "\f134a";
}

.mdi-head-question-outline::before {
  content: "\f134b";
}

.mdi-head-remove::before {
  content: "\f134c";
}

.mdi-head-remove-outline::before {
  content: "\f134d";
}

.mdi-head-snowflake::before {
  content: "\f134e";
}

.mdi-head-snowflake-outline::before {
  content: "\f134f";
}

.mdi-head-sync::before {
  content: "\f1350";
}

.mdi-head-sync-outline::before {
  content: "\f1351";
}

.mdi-headphones::before {
  content: "\f02cb";
}

.mdi-headphones-bluetooth::before {
  content: "\f0970";
}

.mdi-headphones-box::before {
  content: "\f02cc";
}

.mdi-headphones-off::before {
  content: "\f07ce";
}

.mdi-headphones-settings::before {
  content: "\f02cd";
}

.mdi-headset::before {
  content: "\f02ce";
}

.mdi-headset-dock::before {
  content: "\f02cf";
}

.mdi-headset-off::before {
  content: "\f02d0";
}

.mdi-heart::before {
  content: "\f02d1";
}

.mdi-heart-box::before {
  content: "\f02d2";
}

.mdi-heart-box-outline::before {
  content: "\f02d3";
}

.mdi-heart-broken::before {
  content: "\f02d4";
}

.mdi-heart-broken-outline::before {
  content: "\f0d14";
}

.mdi-heart-circle::before {
  content: "\f0971";
}

.mdi-heart-circle-outline::before {
  content: "\f0972";
}

.mdi-heart-cog::before {
  content: "\f1663";
}

.mdi-heart-cog-outline::before {
  content: "\f1664";
}

.mdi-heart-flash::before {
  content: "\f0ef9";
}

.mdi-heart-half::before {
  content: "\f06df";
}

.mdi-heart-half-full::before {
  content: "\f06de";
}

.mdi-heart-half-outline::before {
  content: "\f06e0";
}

.mdi-heart-minus::before {
  content: "\f142f";
}

.mdi-heart-minus-outline::before {
  content: "\f1432";
}

.mdi-heart-multiple::before {
  content: "\f0a56";
}

.mdi-heart-multiple-outline::before {
  content: "\f0a57";
}

.mdi-heart-off::before {
  content: "\f0759";
}

.mdi-heart-off-outline::before {
  content: "\f1434";
}

.mdi-heart-outline::before {
  content: "\f02d5";
}

.mdi-heart-plus::before {
  content: "\f142e";
}

.mdi-heart-plus-outline::before {
  content: "\f1431";
}

.mdi-heart-pulse::before {
  content: "\f05f6";
}

.mdi-heart-remove::before {
  content: "\f1430";
}

.mdi-heart-remove-outline::before {
  content: "\f1433";
}

.mdi-heart-settings::before {
  content: "\f1665";
}

.mdi-heart-settings-outline::before {
  content: "\f1666";
}

.mdi-helicopter::before {
  content: "\f0ac2";
}

.mdi-help::before {
  content: "\f02d6";
}

.mdi-help-box::before {
  content: "\f078b";
}

.mdi-help-circle::before {
  content: "\f02d7";
}

.mdi-help-circle-outline::before {
  content: "\f0625";
}

.mdi-help-network::before {
  content: "\f06f5";
}

.mdi-help-network-outline::before {
  content: "\f0c8a";
}

.mdi-help-rhombus::before {
  content: "\f0ba5";
}

.mdi-help-rhombus-outline::before {
  content: "\f0ba6";
}

.mdi-hexadecimal::before {
  content: "\f12a7";
}

.mdi-hexagon::before {
  content: "\f02d8";
}

.mdi-hexagon-multiple::before {
  content: "\f06e1";
}

.mdi-hexagon-multiple-outline::before {
  content: "\f10f2";
}

.mdi-hexagon-outline::before {
  content: "\f02d9";
}

.mdi-hexagon-slice-1::before {
  content: "\f0ac3";
}

.mdi-hexagon-slice-2::before {
  content: "\f0ac4";
}

.mdi-hexagon-slice-3::before {
  content: "\f0ac5";
}

.mdi-hexagon-slice-4::before {
  content: "\f0ac6";
}

.mdi-hexagon-slice-5::before {
  content: "\f0ac7";
}

.mdi-hexagon-slice-6::before {
  content: "\f0ac8";
}

.mdi-hexagram::before {
  content: "\f0ac9";
}

.mdi-hexagram-outline::before {
  content: "\f0aca";
}

.mdi-high-definition::before {
  content: "\f07cf";
}

.mdi-high-definition-box::before {
  content: "\f0878";
}

.mdi-highway::before {
  content: "\f05f7";
}

.mdi-hiking::before {
  content: "\f0d7f";
}

.mdi-hinduism::before {
  content: "\f0973";
}

.mdi-history::before {
  content: "\f02da";
}

.mdi-hockey-puck::before {
  content: "\f0879";
}

.mdi-hockey-sticks::before {
  content: "\f087a";
}

.mdi-hololens::before {
  content: "\f02db";
}

.mdi-home::before {
  content: "\f02dc";
}

.mdi-home-account::before {
  content: "\f0826";
}

.mdi-home-alert::before {
  content: "\f087b";
}

.mdi-home-alert-outline::before {
  content: "\f15d0";
}

.mdi-home-analytics::before {
  content: "\f0eba";
}

.mdi-home-assistant::before {
  content: "\f07d0";
}

.mdi-home-automation::before {
  content: "\f07d1";
}

.mdi-home-circle::before {
  content: "\f07d2";
}

.mdi-home-circle-outline::before {
  content: "\f104d";
}

.mdi-home-city::before {
  content: "\f0d15";
}

.mdi-home-city-outline::before {
  content: "\f0d16";
}

.mdi-home-currency-usd::before {
  content: "\f08af";
}

.mdi-home-edit::before {
  content: "\f1159";
}

.mdi-home-edit-outline::before {
  content: "\f115a";
}

.mdi-home-export-outline::before {
  content: "\f0f9b";
}

.mdi-home-flood::before {
  content: "\f0efa";
}

.mdi-home-floor-0::before {
  content: "\f0dd2";
}

.mdi-home-floor-1::before {
  content: "\f0d80";
}

.mdi-home-floor-2::before {
  content: "\f0d81";
}

.mdi-home-floor-3::before {
  content: "\f0d82";
}

.mdi-home-floor-a::before {
  content: "\f0d83";
}

.mdi-home-floor-b::before {
  content: "\f0d84";
}

.mdi-home-floor-g::before {
  content: "\f0d85";
}

.mdi-home-floor-l::before {
  content: "\f0d86";
}

.mdi-home-floor-negative-1::before {
  content: "\f0dd3";
}

.mdi-home-group::before {
  content: "\f0dd4";
}

.mdi-home-heart::before {
  content: "\f0827";
}

.mdi-home-import-outline::before {
  content: "\f0f9c";
}

.mdi-home-lightbulb::before {
  content: "\f1251";
}

.mdi-home-lightbulb-outline::before {
  content: "\f1252";
}

.mdi-home-lock::before {
  content: "\f08eb";
}

.mdi-home-lock-open::before {
  content: "\f08ec";
}

.mdi-home-map-marker::before {
  content: "\f05f8";
}

.mdi-home-minus::before {
  content: "\f0974";
}

.mdi-home-minus-outline::before {
  content: "\f13d5";
}

.mdi-home-modern::before {
  content: "\f02dd";
}

.mdi-home-outline::before {
  content: "\f06a1";
}

.mdi-home-plus::before {
  content: "\f0975";
}

.mdi-home-plus-outline::before {
  content: "\f13d6";
}

.mdi-home-remove::before {
  content: "\f1247";
}

.mdi-home-remove-outline::before {
  content: "\f13d7";
}

.mdi-home-roof::before {
  content: "\f112b";
}

.mdi-home-search::before {
  content: "\f13b0";
}

.mdi-home-search-outline::before {
  content: "\f13b1";
}

.mdi-home-thermometer::before {
  content: "\f0f54";
}

.mdi-home-thermometer-outline::before {
  content: "\f0f55";
}

.mdi-home-variant::before {
  content: "\f02de";
}

.mdi-home-variant-outline::before {
  content: "\f0ba7";
}

.mdi-hook::before {
  content: "\f06e2";
}

.mdi-hook-off::before {
  content: "\f06e3";
}

.mdi-hops::before {
  content: "\f02df";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "\f10f3";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\f10f4";
}

.mdi-horse::before {
  content: "\f15bf";
}

.mdi-horse-human::before {
  content: "\f15c0";
}

.mdi-horse-variant::before {
  content: "\f15c1";
}

.mdi-horseshoe::before {
  content: "\f0a58";
}

.mdi-hospital::before {
  content: "\f0ff6";
}

.mdi-hospital-box::before {
  content: "\f02e0";
}

.mdi-hospital-box-outline::before {
  content: "\f0ff7";
}

.mdi-hospital-building::before {
  content: "\f02e1";
}

.mdi-hospital-marker::before {
  content: "\f02e2";
}

.mdi-hot-tub::before {
  content: "\f0828";
}

.mdi-hours-24::before {
  content: "\f1478";
}

.mdi-hubspot::before {
  content: "\f0d17";
}

.mdi-hulu::before {
  content: "\f0829";
}

.mdi-human::before {
  content: "\f02e6";
}

.mdi-human-baby-changing-table::before {
  content: "\f138b";
}

.mdi-human-cane::before {
  content: "\f1581";
}

.mdi-human-capacity-decrease::before {
  content: "\f159b";
}

.mdi-human-capacity-increase::before {
  content: "\f159c";
}

.mdi-human-child::before {
  content: "\f02e7";
}

.mdi-human-edit::before {
  content: "\f14e8";
}

.mdi-human-female::before {
  content: "\f0649";
}

.mdi-human-female-boy::before {
  content: "\f0a59";
}

.mdi-human-female-dance::before {
  content: "\f15c9";
}

.mdi-human-female-female::before {
  content: "\f0a5a";
}

.mdi-human-female-girl::before {
  content: "\f0a5b";
}

.mdi-human-greeting::before {
  content: "\f064a";
}

.mdi-human-greeting-proximity::before {
  content: "\f159d";
}

.mdi-human-handsdown::before {
  content: "\f064b";
}

.mdi-human-handsup::before {
  content: "\f064c";
}

.mdi-human-male::before {
  content: "\f064d";
}

.mdi-human-male-boy::before {
  content: "\f0a5c";
}

.mdi-human-male-child::before {
  content: "\f138c";
}

.mdi-human-male-female::before {
  content: "\f02e8";
}

.mdi-human-male-girl::before {
  content: "\f0a5d";
}

.mdi-human-male-height::before {
  content: "\f0efb";
}

.mdi-human-male-height-variant::before {
  content: "\f0efc";
}

.mdi-human-male-male::before {
  content: "\f0a5e";
}

.mdi-human-pregnant::before {
  content: "\f05cf";
}

.mdi-human-queue::before {
  content: "\f1571";
}

.mdi-human-scooter::before {
  content: "\f11e9";
}

.mdi-human-wheelchair::before {
  content: "\f138d";
}

.mdi-humble-bundle::before {
  content: "\f0744";
}

.mdi-hvac::before {
  content: "\f1352";
}

.mdi-hvac-off::before {
  content: "\f159e";
}

.mdi-hydraulic-oil-level::before {
  content: "\f1324";
}

.mdi-hydraulic-oil-temperature::before {
  content: "\f1325";
}

.mdi-hydro-power::before {
  content: "\f12e5";
}

.mdi-ice-cream::before {
  content: "\f082a";
}

.mdi-ice-cream-off::before {
  content: "\f0e52";
}

.mdi-ice-pop::before {
  content: "\f0efd";
}

.mdi-id-card::before {
  content: "\f0fc0";
}

.mdi-identifier::before {
  content: "\f0efe";
}

.mdi-ideogram-cjk::before {
  content: "\f1331";
}

.mdi-ideogram-cjk-variant::before {
  content: "\f1332";
}

.mdi-iframe::before {
  content: "\f0c8b";
}

.mdi-iframe-array::before {
  content: "\f10f5";
}

.mdi-iframe-array-outline::before {
  content: "\f10f6";
}

.mdi-iframe-braces::before {
  content: "\f10f7";
}

.mdi-iframe-braces-outline::before {
  content: "\f10f8";
}

.mdi-iframe-outline::before {
  content: "\f0c8c";
}

.mdi-iframe-parentheses::before {
  content: "\f10f9";
}

.mdi-iframe-parentheses-outline::before {
  content: "\f10fa";
}

.mdi-iframe-variable::before {
  content: "\f10fb";
}

.mdi-iframe-variable-outline::before {
  content: "\f10fc";
}

.mdi-image::before {
  content: "\f02e9";
}

.mdi-image-album::before {
  content: "\f02ea";
}

.mdi-image-area::before {
  content: "\f02eb";
}

.mdi-image-area-close::before {
  content: "\f02ec";
}

.mdi-image-auto-adjust::before {
  content: "\f0fc1";
}

.mdi-image-broken::before {
  content: "\f02ed";
}

.mdi-image-broken-variant::before {
  content: "\f02ee";
}

.mdi-image-edit::before {
  content: "\f11e3";
}

.mdi-image-edit-outline::before {
  content: "\f11e4";
}

.mdi-image-filter-black-white::before {
  content: "\f02f0";
}

.mdi-image-filter-center-focus::before {
  content: "\f02f1";
}

.mdi-image-filter-center-focus-strong::before {
  content: "\f0eff";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\f0f00";
}

.mdi-image-filter-center-focus-weak::before {
  content: "\f02f2";
}

.mdi-image-filter-drama::before {
  content: "\f02f3";
}

.mdi-image-filter-frames::before {
  content: "\f02f4";
}

.mdi-image-filter-hdr::before {
  content: "\f02f5";
}

.mdi-image-filter-none::before {
  content: "\f02f6";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f02f7";
}

.mdi-image-filter-vintage::before {
  content: "\f02f8";
}

.mdi-image-frame::before {
  content: "\f0e49";
}

.mdi-image-minus::before {
  content: "\f1419";
}

.mdi-image-move::before {
  content: "\f09f8";
}

.mdi-image-multiple::before {
  content: "\f02f9";
}

.mdi-image-multiple-outline::before {
  content: "\f02ef";
}

.mdi-image-off::before {
  content: "\f082b";
}

.mdi-image-off-outline::before {
  content: "\f11d1";
}

.mdi-image-outline::before {
  content: "\f0976";
}

.mdi-image-plus::before {
  content: "\f087c";
}

.mdi-image-remove::before {
  content: "\f1418";
}

.mdi-image-search::before {
  content: "\f0977";
}

.mdi-image-search-outline::before {
  content: "\f0978";
}

.mdi-image-size-select-actual::before {
  content: "\f0c8d";
}

.mdi-image-size-select-large::before {
  content: "\f0c8e";
}

.mdi-image-size-select-small::before {
  content: "\f0c8f";
}

.mdi-image-text::before {
  content: "\f160d";
}

.mdi-import::before {
  content: "\f02fa";
}

.mdi-inbox::before {
  content: "\f0687";
}

.mdi-inbox-arrow-down::before {
  content: "\f02fb";
}

.mdi-inbox-arrow-down-outline::before {
  content: "\f1270";
}

.mdi-inbox-arrow-up::before {
  content: "\f03d1";
}

.mdi-inbox-arrow-up-outline::before {
  content: "\f1271";
}

.mdi-inbox-full::before {
  content: "\f1272";
}

.mdi-inbox-full-outline::before {
  content: "\f1273";
}

.mdi-inbox-multiple::before {
  content: "\f08b0";
}

.mdi-inbox-multiple-outline::before {
  content: "\f0ba8";
}

.mdi-inbox-outline::before {
  content: "\f1274";
}

.mdi-inbox-remove::before {
  content: "\f159f";
}

.mdi-inbox-remove-outline::before {
  content: "\f15a0";
}

.mdi-incognito::before {
  content: "\f05f9";
}

.mdi-incognito-circle::before {
  content: "\f1421";
}

.mdi-incognito-circle-off::before {
  content: "\f1422";
}

.mdi-incognito-off::before {
  content: "\f0075";
}

.mdi-infinity::before {
  content: "\f06e4";
}

.mdi-information::before {
  content: "\f02fc";
}

.mdi-information-outline::before {
  content: "\f02fd";
}

.mdi-information-variant::before {
  content: "\f064e";
}

.mdi-instagram::before {
  content: "\f02fe";
}

.mdi-instrument-triangle::before {
  content: "\f104e";
}

.mdi-invert-colors::before {
  content: "\f0301";
}

.mdi-invert-colors-off::before {
  content: "\f0e4a";
}

.mdi-iobroker::before {
  content: "\f12e8";
}

.mdi-ip::before {
  content: "\f0a5f";
}

.mdi-ip-network::before {
  content: "\f0a60";
}

.mdi-ip-network-outline::before {
  content: "\f0c90";
}

.mdi-ipod::before {
  content: "\f0c91";
}

.mdi-islam::before {
  content: "\f0979";
}

.mdi-island::before {
  content: "\f104f";
}

.mdi-iv-bag::before {
  content: "\f10b9";
}

.mdi-jabber::before {
  content: "\f0dd5";
}

.mdi-jeepney::before {
  content: "\f0302";
}

.mdi-jellyfish::before {
  content: "\f0f01";
}

.mdi-jellyfish-outline::before {
  content: "\f0f02";
}

.mdi-jira::before {
  content: "\f0303";
}

.mdi-jquery::before {
  content: "\f087d";
}

.mdi-jsfiddle::before {
  content: "\f0304";
}

.mdi-judaism::before {
  content: "\f097a";
}

.mdi-jump-rope::before {
  content: "\f12ff";
}

.mdi-kabaddi::before {
  content: "\f0d87";
}

.mdi-kangaroo::before {
  content: "\f1558";
}

.mdi-karate::before {
  content: "\f082c";
}

.mdi-keg::before {
  content: "\f0305";
}

.mdi-kettle::before {
  content: "\f05fa";
}

.mdi-kettle-alert::before {
  content: "\f1317";
}

.mdi-kettle-alert-outline::before {
  content: "\f1318";
}

.mdi-kettle-off::before {
  content: "\f131b";
}

.mdi-kettle-off-outline::before {
  content: "\f131c";
}

.mdi-kettle-outline::before {
  content: "\f0f56";
}

.mdi-kettle-steam::before {
  content: "\f1319";
}

.mdi-kettle-steam-outline::before {
  content: "\f131a";
}

.mdi-kettlebell::before {
  content: "\f1300";
}

.mdi-key::before {
  content: "\f0306";
}

.mdi-key-arrow-right::before {
  content: "\f1312";
}

.mdi-key-chain::before {
  content: "\f1574";
}

.mdi-key-chain-variant::before {
  content: "\f1575";
}

.mdi-key-change::before {
  content: "\f0307";
}

.mdi-key-link::before {
  content: "\f119f";
}

.mdi-key-minus::before {
  content: "\f0308";
}

.mdi-key-outline::before {
  content: "\f0dd6";
}

.mdi-key-plus::before {
  content: "\f0309";
}

.mdi-key-remove::before {
  content: "\f030a";
}

.mdi-key-star::before {
  content: "\f119e";
}

.mdi-key-variant::before {
  content: "\f030b";
}

.mdi-key-wireless::before {
  content: "\f0fc2";
}

.mdi-keyboard::before {
  content: "\f030c";
}

.mdi-keyboard-backspace::before {
  content: "\f030d";
}

.mdi-keyboard-caps::before {
  content: "\f030e";
}

.mdi-keyboard-close::before {
  content: "\f030f";
}

.mdi-keyboard-esc::before {
  content: "\f12b7";
}

.mdi-keyboard-f1::before {
  content: "\f12ab";
}

.mdi-keyboard-f10::before {
  content: "\f12b4";
}

.mdi-keyboard-f11::before {
  content: "\f12b5";
}

.mdi-keyboard-f12::before {
  content: "\f12b6";
}

.mdi-keyboard-f2::before {
  content: "\f12ac";
}

.mdi-keyboard-f3::before {
  content: "\f12ad";
}

.mdi-keyboard-f4::before {
  content: "\f12ae";
}

.mdi-keyboard-f5::before {
  content: "\f12af";
}

.mdi-keyboard-f6::before {
  content: "\f12b0";
}

.mdi-keyboard-f7::before {
  content: "\f12b1";
}

.mdi-keyboard-f8::before {
  content: "\f12b2";
}

.mdi-keyboard-f9::before {
  content: "\f12b3";
}

.mdi-keyboard-off::before {
  content: "\f0310";
}

.mdi-keyboard-off-outline::before {
  content: "\f0e4b";
}

.mdi-keyboard-outline::before {
  content: "\f097b";
}

.mdi-keyboard-return::before {
  content: "\f0311";
}

.mdi-keyboard-settings::before {
  content: "\f09f9";
}

.mdi-keyboard-settings-outline::before {
  content: "\f09fa";
}

.mdi-keyboard-space::before {
  content: "\f1050";
}

.mdi-keyboard-tab::before {
  content: "\f0312";
}

.mdi-keyboard-variant::before {
  content: "\f0313";
}

.mdi-khanda::before {
  content: "\f10fd";
}

.mdi-kickstarter::before {
  content: "\f0745";
}

.mdi-klingon::before {
  content: "\f135b";
}

.mdi-knife::before {
  content: "\f09fb";
}

.mdi-knife-military::before {
  content: "\f09fc";
}

.mdi-kodi::before {
  content: "\f0314";
}

.mdi-kubernetes::before {
  content: "\f10fe";
}

.mdi-label::before {
  content: "\f0315";
}

.mdi-label-multiple::before {
  content: "\f1375";
}

.mdi-label-multiple-outline::before {
  content: "\f1376";
}

.mdi-label-off::before {
  content: "\f0acb";
}

.mdi-label-off-outline::before {
  content: "\f0acc";
}

.mdi-label-outline::before {
  content: "\f0316";
}

.mdi-label-percent::before {
  content: "\f12ea";
}

.mdi-label-percent-outline::before {
  content: "\f12eb";
}

.mdi-label-variant::before {
  content: "\f0acd";
}

.mdi-label-variant-outline::before {
  content: "\f0ace";
}

.mdi-ladder::before {
  content: "\f15a2";
}

.mdi-ladybug::before {
  content: "\f082d";
}

.mdi-lambda::before {
  content: "\f0627";
}

.mdi-lamp::before {
  content: "\f06b5";
}

.mdi-lamps::before {
  content: "\f1576";
}

.mdi-lan::before {
  content: "\f0317";
}

.mdi-lan-check::before {
  content: "\f12aa";
}

.mdi-lan-connect::before {
  content: "\f0318";
}

.mdi-lan-disconnect::before {
  content: "\f0319";
}

.mdi-lan-pending::before {
  content: "\f031a";
}

.mdi-language-c::before {
  content: "\f0671";
}

.mdi-language-cpp::before {
  content: "\f0672";
}

.mdi-language-csharp::before {
  content: "\f031b";
}

.mdi-language-css3::before {
  content: "\f031c";
}

.mdi-language-fortran::before {
  content: "\f121a";
}

.mdi-language-go::before {
  content: "\f07d3";
}

.mdi-language-haskell::before {
  content: "\f0c92";
}

.mdi-language-html5::before {
  content: "\f031d";
}

.mdi-language-java::before {
  content: "\f0b37";
}

.mdi-language-javascript::before {
  content: "\f031e";
}

.mdi-language-kotlin::before {
  content: "\f1219";
}

.mdi-language-lua::before {
  content: "\f08b1";
}

.mdi-language-markdown::before {
  content: "\f0354";
}

.mdi-language-markdown-outline::before {
  content: "\f0f5b";
}

.mdi-language-php::before {
  content: "\f031f";
}

.mdi-language-python::before {
  content: "\f0320";
}

.mdi-language-r::before {
  content: "\f07d4";
}

.mdi-language-ruby::before {
  content: "\f0d2d";
}

.mdi-language-ruby-on-rails::before {
  content: "\f0acf";
}

.mdi-language-rust::before {
  content: "\f1617";
}

.mdi-language-swift::before {
  content: "\f06e5";
}

.mdi-language-typescript::before {
  content: "\f06e6";
}

.mdi-language-xaml::before {
  content: "\f0673";
}

.mdi-laptop::before {
  content: "\f0322";
}

.mdi-laptop-chromebook::before {
  content: "\f0323";
}

.mdi-laptop-mac::before {
  content: "\f0324";
}

.mdi-laptop-off::before {
  content: "\f06e7";
}

.mdi-laptop-windows::before {
  content: "\f0325";
}

.mdi-laravel::before {
  content: "\f0ad0";
}

.mdi-laser-pointer::before {
  content: "\f1484";
}

.mdi-lasso::before {
  content: "\f0f03";
}

.mdi-lastpass::before {
  content: "\f0446";
}

.mdi-latitude::before {
  content: "\f0f57";
}

.mdi-launch::before {
  content: "\f0327";
}

.mdi-lava-lamp::before {
  content: "\f07d5";
}

.mdi-layers::before {
  content: "\f0328";
}

.mdi-layers-minus::before {
  content: "\f0e4c";
}

.mdi-layers-off::before {
  content: "\f0329";
}

.mdi-layers-off-outline::before {
  content: "\f09fd";
}

.mdi-layers-outline::before {
  content: "\f09fe";
}

.mdi-layers-plus::before {
  content: "\f0e4d";
}

.mdi-layers-remove::before {
  content: "\f0e4e";
}

.mdi-layers-search::before {
  content: "\f1206";
}

.mdi-layers-search-outline::before {
  content: "\f1207";
}

.mdi-layers-triple::before {
  content: "\f0f58";
}

.mdi-layers-triple-outline::before {
  content: "\f0f59";
}

.mdi-lead-pencil::before {
  content: "\f064f";
}

.mdi-leaf::before {
  content: "\f032a";
}

.mdi-leaf-maple::before {
  content: "\f0c93";
}

.mdi-leaf-maple-off::before {
  content: "\f12da";
}

.mdi-leaf-off::before {
  content: "\f12d9";
}

.mdi-leak::before {
  content: "\f0dd7";
}

.mdi-leak-off::before {
  content: "\f0dd8";
}

.mdi-led-off::before {
  content: "\f032b";
}

.mdi-led-on::before {
  content: "\f032c";
}

.mdi-led-outline::before {
  content: "\f032d";
}

.mdi-led-strip::before {
  content: "\f07d6";
}

.mdi-led-strip-variant::before {
  content: "\f1051";
}

.mdi-led-variant-off::before {
  content: "\f032e";
}

.mdi-led-variant-on::before {
  content: "\f032f";
}

.mdi-led-variant-outline::before {
  content: "\f0330";
}

.mdi-leek::before {
  content: "\f117d";
}

.mdi-less-than::before {
  content: "\f097c";
}

.mdi-less-than-or-equal::before {
  content: "\f097d";
}

.mdi-library::before {
  content: "\f0331";
}

.mdi-library-shelves::before {
  content: "\f0ba9";
}

.mdi-license::before {
  content: "\f0fc3";
}

.mdi-lifebuoy::before {
  content: "\f087e";
}

.mdi-light-switch::before {
  content: "\f097e";
}

.mdi-lightbulb::before {
  content: "\f0335";
}

.mdi-lightbulb-cfl::before {
  content: "\f1208";
}

.mdi-lightbulb-cfl-off::before {
  content: "\f1209";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "\f1275";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\f12c3";
}

.mdi-lightbulb-group::before {
  content: "\f1253";
}

.mdi-lightbulb-group-off::before {
  content: "\f12cd";
}

.mdi-lightbulb-group-off-outline::before {
  content: "\f12ce";
}

.mdi-lightbulb-group-outline::before {
  content: "\f1254";
}

.mdi-lightbulb-multiple::before {
  content: "\f1255";
}

.mdi-lightbulb-multiple-off::before {
  content: "\f12cf";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "\f12d0";
}

.mdi-lightbulb-multiple-outline::before {
  content: "\f1256";
}

.mdi-lightbulb-off::before {
  content: "\f0e4f";
}

.mdi-lightbulb-off-outline::before {
  content: "\f0e50";
}

.mdi-lightbulb-on::before {
  content: "\f06e8";
}

.mdi-lightbulb-on-outline::before {
  content: "\f06e9";
}

.mdi-lightbulb-outline::before {
  content: "\f0336";
}

.mdi-lighthouse::before {
  content: "\f09ff";
}

.mdi-lighthouse-on::before {
  content: "\f0a00";
}

.mdi-lightning-bolt::before {
  content: "\f140b";
}

.mdi-lightning-bolt-outline::before {
  content: "\f140c";
}

.mdi-lingerie::before {
  content: "\f1476";
}

.mdi-link::before {
  content: "\f0337";
}

.mdi-link-box::before {
  content: "\f0d1a";
}

.mdi-link-box-outline::before {
  content: "\f0d1b";
}

.mdi-link-box-variant::before {
  content: "\f0d1c";
}

.mdi-link-box-variant-outline::before {
  content: "\f0d1d";
}

.mdi-link-lock::before {
  content: "\f10ba";
}

.mdi-link-off::before {
  content: "\f0338";
}

.mdi-link-plus::before {
  content: "\f0c94";
}

.mdi-link-variant::before {
  content: "\f0339";
}

.mdi-link-variant-minus::before {
  content: "\f10ff";
}

.mdi-link-variant-off::before {
  content: "\f033a";
}

.mdi-link-variant-plus::before {
  content: "\f1100";
}

.mdi-link-variant-remove::before {
  content: "\f1101";
}

.mdi-linkedin::before {
  content: "\f033b";
}

.mdi-linux::before {
  content: "\f033d";
}

.mdi-linux-mint::before {
  content: "\f08ed";
}

.mdi-lipstick::before {
  content: "\f13b5";
}

.mdi-list-status::before {
  content: "\f15ab";
}

.mdi-litecoin::before {
  content: "\f0a61";
}

.mdi-loading::before {
  content: "\f0772";
}

.mdi-location-enter::before {
  content: "\f0fc4";
}

.mdi-location-exit::before {
  content: "\f0fc5";
}

.mdi-lock::before {
  content: "\f033e";
}

.mdi-lock-alert::before {
  content: "\f08ee";
}

.mdi-lock-alert-outline::before {
  content: "\f15d1";
}

.mdi-lock-check::before {
  content: "\f139a";
}

.mdi-lock-check-outline::before {
  content: "\f16a8";
}

.mdi-lock-clock::before {
  content: "\f097f";
}

.mdi-lock-minus::before {
  content: "\f16a9";
}

.mdi-lock-minus-outline::before {
  content: "\f16aa";
}

.mdi-lock-off::before {
  content: "\f1671";
}

.mdi-lock-off-outline::before {
  content: "\f1672";
}

.mdi-lock-open::before {
  content: "\f033f";
}

.mdi-lock-open-alert::before {
  content: "\f139b";
}

.mdi-lock-open-alert-outline::before {
  content: "\f15d2";
}

.mdi-lock-open-check::before {
  content: "\f139c";
}

.mdi-lock-open-check-outline::before {
  content: "\f16ab";
}

.mdi-lock-open-minus::before {
  content: "\f16ac";
}

.mdi-lock-open-minus-outline::before {
  content: "\f16ad";
}

.mdi-lock-open-outline::before {
  content: "\f0340";
}

.mdi-lock-open-plus::before {
  content: "\f16ae";
}

.mdi-lock-open-plus-outline::before {
  content: "\f16af";
}

.mdi-lock-open-remove::before {
  content: "\f16b0";
}

.mdi-lock-open-remove-outline::before {
  content: "\f16b1";
}

.mdi-lock-open-variant::before {
  content: "\f0fc6";
}

.mdi-lock-open-variant-outline::before {
  content: "\f0fc7";
}

.mdi-lock-outline::before {
  content: "\f0341";
}

.mdi-lock-pattern::before {
  content: "\f06ea";
}

.mdi-lock-plus::before {
  content: "\f05fb";
}

.mdi-lock-plus-outline::before {
  content: "\f16b2";
}

.mdi-lock-question::before {
  content: "\f08ef";
}

.mdi-lock-remove::before {
  content: "\f16b3";
}

.mdi-lock-remove-outline::before {
  content: "\f16b4";
}

.mdi-lock-reset::before {
  content: "\f0773";
}

.mdi-lock-smart::before {
  content: "\f08b2";
}

.mdi-locker::before {
  content: "\f07d7";
}

.mdi-locker-multiple::before {
  content: "\f07d8";
}

.mdi-login::before {
  content: "\f0342";
}

.mdi-login-variant::before {
  content: "\f05fc";
}

.mdi-logout::before {
  content: "\f0343";
}

.mdi-logout-variant::before {
  content: "\f05fd";
}

.mdi-longitude::before {
  content: "\f0f5a";
}

.mdi-looks::before {
  content: "\f0344";
}

.mdi-lotion::before {
  content: "\f1582";
}

.mdi-lotion-outline::before {
  content: "\f1583";
}

.mdi-lotion-plus::before {
  content: "\f1584";
}

.mdi-lotion-plus-outline::before {
  content: "\f1585";
}

.mdi-loupe::before {
  content: "\f0345";
}

.mdi-lumx::before {
  content: "\f0346";
}

.mdi-lungs::before {
  content: "\f1084";
}

.mdi-magnet::before {
  content: "\f0347";
}

.mdi-magnet-on::before {
  content: "\f0348";
}

.mdi-magnify::before {
  content: "\f0349";
}

.mdi-magnify-close::before {
  content: "\f0980";
}

.mdi-magnify-minus::before {
  content: "\f034a";
}

.mdi-magnify-minus-cursor::before {
  content: "\f0a62";
}

.mdi-magnify-minus-outline::before {
  content: "\f06ec";
}

.mdi-magnify-plus::before {
  content: "\f034b";
}

.mdi-magnify-plus-cursor::before {
  content: "\f0a63";
}

.mdi-magnify-plus-outline::before {
  content: "\f06ed";
}

.mdi-magnify-remove-cursor::before {
  content: "\f120c";
}

.mdi-magnify-remove-outline::before {
  content: "\f120d";
}

.mdi-magnify-scan::before {
  content: "\f1276";
}

.mdi-mail::before {
  content: "\f0ebb";
}

.mdi-mailbox::before {
  content: "\f06ee";
}

.mdi-mailbox-open::before {
  content: "\f0d88";
}

.mdi-mailbox-open-outline::before {
  content: "\f0d89";
}

.mdi-mailbox-open-up::before {
  content: "\f0d8a";
}

.mdi-mailbox-open-up-outline::before {
  content: "\f0d8b";
}

.mdi-mailbox-outline::before {
  content: "\f0d8c";
}

.mdi-mailbox-up::before {
  content: "\f0d8d";
}

.mdi-mailbox-up-outline::before {
  content: "\f0d8e";
}

.mdi-manjaro::before {
  content: "\f160a";
}

.mdi-map::before {
  content: "\f034d";
}

.mdi-map-check::before {
  content: "\f0ebc";
}

.mdi-map-check-outline::before {
  content: "\f0ebd";
}

.mdi-map-clock::before {
  content: "\f0d1e";
}

.mdi-map-clock-outline::before {
  content: "\f0d1f";
}

.mdi-map-legend::before {
  content: "\f0a01";
}

.mdi-map-marker::before {
  content: "\f034e";
}

.mdi-map-marker-alert::before {
  content: "\f0f05";
}

.mdi-map-marker-alert-outline::before {
  content: "\f0f06";
}

.mdi-map-marker-check::before {
  content: "\f0c95";
}

.mdi-map-marker-check-outline::before {
  content: "\f12fb";
}

.mdi-map-marker-circle::before {
  content: "\f034f";
}

.mdi-map-marker-distance::before {
  content: "\f08f0";
}

.mdi-map-marker-down::before {
  content: "\f1102";
}

.mdi-map-marker-left::before {
  content: "\f12db";
}

.mdi-map-marker-left-outline::before {
  content: "\f12dd";
}

.mdi-map-marker-minus::before {
  content: "\f0650";
}

.mdi-map-marker-minus-outline::before {
  content: "\f12f9";
}

.mdi-map-marker-multiple::before {
  content: "\f0350";
}

.mdi-map-marker-multiple-outline::before {
  content: "\f1277";
}

.mdi-map-marker-off::before {
  content: "\f0351";
}

.mdi-map-marker-off-outline::before {
  content: "\f12fd";
}

.mdi-map-marker-outline::before {
  content: "\f07d9";
}

.mdi-map-marker-path::before {
  content: "\f0d20";
}

.mdi-map-marker-plus::before {
  content: "\f0651";
}

.mdi-map-marker-plus-outline::before {
  content: "\f12f8";
}

.mdi-map-marker-question::before {
  content: "\f0f07";
}

.mdi-map-marker-question-outline::before {
  content: "\f0f08";
}

.mdi-map-marker-radius::before {
  content: "\f0352";
}

.mdi-map-marker-radius-outline::before {
  content: "\f12fc";
}

.mdi-map-marker-remove::before {
  content: "\f0f09";
}

.mdi-map-marker-remove-outline::before {
  content: "\f12fa";
}

.mdi-map-marker-remove-variant::before {
  content: "\f0f0a";
}

.mdi-map-marker-right::before {
  content: "\f12dc";
}

.mdi-map-marker-right-outline::before {
  content: "\f12de";
}

.mdi-map-marker-star::before {
  content: "\f1608";
}

.mdi-map-marker-star-outline::before {
  content: "\f1609";
}

.mdi-map-marker-up::before {
  content: "\f1103";
}

.mdi-map-minus::before {
  content: "\f0981";
}

.mdi-map-outline::before {
  content: "\f0982";
}

.mdi-map-plus::before {
  content: "\f0983";
}

.mdi-map-search::before {
  content: "\f0984";
}

.mdi-map-search-outline::before {
  content: "\f0985";
}

.mdi-mapbox::before {
  content: "\f0baa";
}

.mdi-margin::before {
  content: "\f0353";
}

.mdi-marker::before {
  content: "\f0652";
}

.mdi-marker-cancel::before {
  content: "\f0dd9";
}

.mdi-marker-check::before {
  content: "\f0355";
}

.mdi-mastodon::before {
  content: "\f0ad1";
}

.mdi-material-design::before {
  content: "\f0986";
}

.mdi-material-ui::before {
  content: "\f0357";
}

.mdi-math-compass::before {
  content: "\f0358";
}

.mdi-math-cos::before {
  content: "\f0c96";
}

.mdi-math-integral::before {
  content: "\f0fc8";
}

.mdi-math-integral-box::before {
  content: "\f0fc9";
}

.mdi-math-log::before {
  content: "\f1085";
}

.mdi-math-norm::before {
  content: "\f0fca";
}

.mdi-math-norm-box::before {
  content: "\f0fcb";
}

.mdi-math-sin::before {
  content: "\f0c97";
}

.mdi-math-tan::before {
  content: "\f0c98";
}

.mdi-matrix::before {
  content: "\f0628";
}

.mdi-medal::before {
  content: "\f0987";
}

.mdi-medal-outline::before {
  content: "\f1326";
}

.mdi-medical-bag::before {
  content: "\f06ef";
}

.mdi-meditation::before {
  content: "\f117b";
}

.mdi-memory::before {
  content: "\f035b";
}

.mdi-menu::before {
  content: "\f035c";
}

.mdi-menu-down::before {
  content: "\f035d";
}

.mdi-menu-down-outline::before {
  content: "\f06b6";
}

.mdi-menu-left::before {
  content: "\f035e";
}

.mdi-menu-left-outline::before {
  content: "\f0a02";
}

.mdi-menu-open::before {
  content: "\f0bab";
}

.mdi-menu-right::before {
  content: "\f035f";
}

.mdi-menu-right-outline::before {
  content: "\f0a03";
}

.mdi-menu-swap::before {
  content: "\f0a64";
}

.mdi-menu-swap-outline::before {
  content: "\f0a65";
}

.mdi-menu-up::before {
  content: "\f0360";
}

.mdi-menu-up-outline::before {
  content: "\f06b7";
}

.mdi-merge::before {
  content: "\f0f5c";
}

.mdi-message::before {
  content: "\f0361";
}

.mdi-message-alert::before {
  content: "\f0362";
}

.mdi-message-alert-outline::before {
  content: "\f0a04";
}

.mdi-message-arrow-left::before {
  content: "\f12f2";
}

.mdi-message-arrow-left-outline::before {
  content: "\f12f3";
}

.mdi-message-arrow-right::before {
  content: "\f12f4";
}

.mdi-message-arrow-right-outline::before {
  content: "\f12f5";
}

.mdi-message-bookmark::before {
  content: "\f15ac";
}

.mdi-message-bookmark-outline::before {
  content: "\f15ad";
}

.mdi-message-bulleted::before {
  content: "\f06a2";
}

.mdi-message-bulleted-off::before {
  content: "\f06a3";
}

.mdi-message-cog::before {
  content: "\f06f1";
}

.mdi-message-cog-outline::before {
  content: "\f1172";
}

.mdi-message-draw::before {
  content: "\f0363";
}

.mdi-message-flash::before {
  content: "\f15a9";
}

.mdi-message-flash-outline::before {
  content: "\f15aa";
}

.mdi-message-image::before {
  content: "\f0364";
}

.mdi-message-image-outline::before {
  content: "\f116c";
}

.mdi-message-lock::before {
  content: "\f0fcc";
}

.mdi-message-lock-outline::before {
  content: "\f116d";
}

.mdi-message-minus::before {
  content: "\f116e";
}

.mdi-message-minus-outline::before {
  content: "\f116f";
}

.mdi-message-off::before {
  content: "\f164d";
}

.mdi-message-off-outline::before {
  content: "\f164e";
}

.mdi-message-outline::before {
  content: "\f0365";
}

.mdi-message-plus::before {
  content: "\f0653";
}

.mdi-message-plus-outline::before {
  content: "\f10bb";
}

.mdi-message-processing::before {
  content: "\f0366";
}

.mdi-message-processing-outline::before {
  content: "\f1170";
}

.mdi-message-reply::before {
  content: "\f0367";
}

.mdi-message-reply-text::before {
  content: "\f0368";
}

.mdi-message-settings::before {
  content: "\f06f0";
}

.mdi-message-settings-outline::before {
  content: "\f1171";
}

.mdi-message-text::before {
  content: "\f0369";
}

.mdi-message-text-clock::before {
  content: "\f1173";
}

.mdi-message-text-clock-outline::before {
  content: "\f1174";
}

.mdi-message-text-lock::before {
  content: "\f0fcd";
}

.mdi-message-text-lock-outline::before {
  content: "\f1175";
}

.mdi-message-text-outline::before {
  content: "\f036a";
}

.mdi-message-video::before {
  content: "\f036b";
}

.mdi-meteor::before {
  content: "\f0629";
}

.mdi-metronome::before {
  content: "\f07da";
}

.mdi-metronome-tick::before {
  content: "\f07db";
}

.mdi-micro-sd::before {
  content: "\f07dc";
}

.mdi-microphone::before {
  content: "\f036c";
}

.mdi-microphone-minus::before {
  content: "\f08b3";
}

.mdi-microphone-off::before {
  content: "\f036d";
}

.mdi-microphone-outline::before {
  content: "\f036e";
}

.mdi-microphone-plus::before {
  content: "\f08b4";
}

.mdi-microphone-settings::before {
  content: "\f036f";
}

.mdi-microphone-variant::before {
  content: "\f0370";
}

.mdi-microphone-variant-off::before {
  content: "\f0371";
}

.mdi-microscope::before {
  content: "\f0654";
}

.mdi-microsoft::before {
  content: "\f0372";
}

.mdi-microsoft-access::before {
  content: "\f138e";
}

.mdi-microsoft-azure::before {
  content: "\f0805";
}

.mdi-microsoft-azure-devops::before {
  content: "\f0fd5";
}

.mdi-microsoft-bing::before {
  content: "\f00a4";
}

.mdi-microsoft-dynamics-365::before {
  content: "\f0988";
}

.mdi-microsoft-edge::before {
  content: "\f01e9";
}

.mdi-microsoft-edge-legacy::before {
  content: "\f1250";
}

.mdi-microsoft-excel::before {
  content: "\f138f";
}

.mdi-microsoft-internet-explorer::before {
  content: "\f0300";
}

.mdi-microsoft-office::before {
  content: "\f03c6";
}

.mdi-microsoft-onedrive::before {
  content: "\f03ca";
}

.mdi-microsoft-onenote::before {
  content: "\f0747";
}

.mdi-microsoft-outlook::before {
  content: "\f0d22";
}

.mdi-microsoft-powerpoint::before {
  content: "\f1390";
}

.mdi-microsoft-sharepoint::before {
  content: "\f1391";
}

.mdi-microsoft-teams::before {
  content: "\f02bb";
}

.mdi-microsoft-visual-studio::before {
  content: "\f0610";
}

.mdi-microsoft-visual-studio-code::before {
  content: "\f0a1e";
}

.mdi-microsoft-windows::before {
  content: "\f05b3";
}

.mdi-microsoft-windows-classic::before {
  content: "\f0a21";
}

.mdi-microsoft-word::before {
  content: "\f1392";
}

.mdi-microsoft-xbox::before {
  content: "\f05b9";
}

.mdi-microsoft-xbox-controller::before {
  content: "\f05ba";
}

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\f074b";
}

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\f0a22";
}

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\f074c";
}

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\f074d";
}

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\f074e";
}

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\f074f";
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\f0750";
}

.mdi-microsoft-xbox-controller-menu::before {
  content: "\f0e6f";
}

.mdi-microsoft-xbox-controller-off::before {
  content: "\f05bb";
}

.mdi-microsoft-xbox-controller-view::before {
  content: "\f0e70";
}

.mdi-microsoft-yammer::before {
  content: "\f0789";
}

.mdi-microwave::before {
  content: "\f0c99";
}

.mdi-microwave-off::before {
  content: "\f1423";
}

.mdi-middleware::before {
  content: "\f0f5d";
}

.mdi-middleware-outline::before {
  content: "\f0f5e";
}

.mdi-midi::before {
  content: "\f08f1";
}

.mdi-midi-port::before {
  content: "\f08f2";
}

.mdi-mine::before {
  content: "\f0dda";
}

.mdi-minecraft::before {
  content: "\f0373";
}

.mdi-mini-sd::before {
  content: "\f0a05";
}

.mdi-minidisc::before {
  content: "\f0a06";
}

.mdi-minus::before {
  content: "\f0374";
}

.mdi-minus-box::before {
  content: "\f0375";
}

.mdi-minus-box-multiple::before {
  content: "\f1141";
}

.mdi-minus-box-multiple-outline::before {
  content: "\f1142";
}

.mdi-minus-box-outline::before {
  content: "\f06f2";
}

.mdi-minus-circle::before {
  content: "\f0376";
}

.mdi-minus-circle-multiple::before {
  content: "\f035a";
}

.mdi-minus-circle-multiple-outline::before {
  content: "\f0ad3";
}

.mdi-minus-circle-off::before {
  content: "\f1459";
}

.mdi-minus-circle-off-outline::before {
  content: "\f145a";
}

.mdi-minus-circle-outline::before {
  content: "\f0377";
}

.mdi-minus-network::before {
  content: "\f0378";
}

.mdi-minus-network-outline::before {
  content: "\f0c9a";
}

.mdi-minus-thick::before {
  content: "\f1639";
}

.mdi-mirror::before {
  content: "\f11fd";
}

.mdi-mixed-martial-arts::before {
  content: "\f0d8f";
}

.mdi-mixed-reality::before {
  content: "\f087f";
}

.mdi-molecule::before {
  content: "\f0bac";
}

.mdi-molecule-co::before {
  content: "\f12fe";
}

.mdi-molecule-co2::before {
  content: "\f07e4";
}

.mdi-monitor::before {
  content: "\f0379";
}

.mdi-monitor-cellphone::before {
  content: "\f0989";
}

.mdi-monitor-cellphone-star::before {
  content: "\f098a";
}

.mdi-monitor-clean::before {
  content: "\f1104";
}

.mdi-monitor-dashboard::before {
  content: "\f0a07";
}

.mdi-monitor-edit::before {
  content: "\f12c6";
}

.mdi-monitor-eye::before {
  content: "\f13b4";
}

.mdi-monitor-lock::before {
  content: "\f0ddb";
}

.mdi-monitor-multiple::before {
  content: "\f037a";
}

.mdi-monitor-off::before {
  content: "\f0d90";
}

.mdi-monitor-screenshot::before {
  content: "\f0e51";
}

.mdi-monitor-share::before {
  content: "\f1483";
}

.mdi-monitor-speaker::before {
  content: "\f0f5f";
}

.mdi-monitor-speaker-off::before {
  content: "\f0f60";
}

.mdi-monitor-star::before {
  content: "\f0ddc";
}

.mdi-moon-first-quarter::before {
  content: "\f0f61";
}

.mdi-moon-full::before {
  content: "\f0f62";
}

.mdi-moon-last-quarter::before {
  content: "\f0f63";
}

.mdi-moon-new::before {
  content: "\f0f64";
}

.mdi-moon-waning-crescent::before {
  content: "\f0f65";
}

.mdi-moon-waning-gibbous::before {
  content: "\f0f66";
}

.mdi-moon-waxing-crescent::before {
  content: "\f0f67";
}

.mdi-moon-waxing-gibbous::before {
  content: "\f0f68";
}

.mdi-moped::before {
  content: "\f1086";
}

.mdi-moped-electric::before {
  content: "\f15b7";
}

.mdi-moped-electric-outline::before {
  content: "\f15b8";
}

.mdi-moped-outline::before {
  content: "\f15b9";
}

.mdi-more::before {
  content: "\f037b";
}

.mdi-mother-heart::before {
  content: "\f1314";
}

.mdi-mother-nurse::before {
  content: "\f0d21";
}

.mdi-motion::before {
  content: "\f15b2";
}

.mdi-motion-outline::before {
  content: "\f15b3";
}

.mdi-motion-pause::before {
  content: "\f1590";
}

.mdi-motion-pause-outline::before {
  content: "\f1592";
}

.mdi-motion-play::before {
  content: "\f158f";
}

.mdi-motion-play-outline::before {
  content: "\f1591";
}

.mdi-motion-sensor::before {
  content: "\f0d91";
}

.mdi-motion-sensor-off::before {
  content: "\f1435";
}

.mdi-motorbike::before {
  content: "\f037c";
}

.mdi-motorbike-electric::before {
  content: "\f15ba";
}

.mdi-mouse::before {
  content: "\f037d";
}

.mdi-mouse-bluetooth::before {
  content: "\f098b";
}

.mdi-mouse-move-down::before {
  content: "\f1550";
}

.mdi-mouse-move-up::before {
  content: "\f1551";
}

.mdi-mouse-move-vertical::before {
  content: "\f1552";
}

.mdi-mouse-off::before {
  content: "\f037e";
}

.mdi-mouse-variant::before {
  content: "\f037f";
}

.mdi-mouse-variant-off::before {
  content: "\f0380";
}

.mdi-move-resize::before {
  content: "\f0655";
}

.mdi-move-resize-variant::before {
  content: "\f0656";
}

.mdi-movie::before {
  content: "\f0381";
}

.mdi-movie-edit::before {
  content: "\f1122";
}

.mdi-movie-edit-outline::before {
  content: "\f1123";
}

.mdi-movie-filter::before {
  content: "\f1124";
}

.mdi-movie-filter-outline::before {
  content: "\f1125";
}

.mdi-movie-open::before {
  content: "\f0fce";
}

.mdi-movie-open-outline::before {
  content: "\f0fcf";
}

.mdi-movie-outline::before {
  content: "\f0ddd";
}

.mdi-movie-roll::before {
  content: "\f07de";
}

.mdi-movie-search::before {
  content: "\f11d2";
}

.mdi-movie-search-outline::before {
  content: "\f11d3";
}

.mdi-mower::before {
  content: "\f166f";
}

.mdi-mower-bag::before {
  content: "\f1670";
}

.mdi-muffin::before {
  content: "\f098c";
}

.mdi-multiplication::before {
  content: "\f0382";
}

.mdi-multiplication-box::before {
  content: "\f0383";
}

.mdi-mushroom::before {
  content: "\f07df";
}

.mdi-mushroom-off::before {
  content: "\f13fa";
}

.mdi-mushroom-off-outline::before {
  content: "\f13fb";
}

.mdi-mushroom-outline::before {
  content: "\f07e0";
}

.mdi-music::before {
  content: "\f075a";
}

.mdi-music-accidental-double-flat::before {
  content: "\f0f69";
}

.mdi-music-accidental-double-sharp::before {
  content: "\f0f6a";
}

.mdi-music-accidental-flat::before {
  content: "\f0f6b";
}

.mdi-music-accidental-natural::before {
  content: "\f0f6c";
}

.mdi-music-accidental-sharp::before {
  content: "\f0f6d";
}

.mdi-music-box::before {
  content: "\f0384";
}

.mdi-music-box-multiple::before {
  content: "\f0333";
}

.mdi-music-box-multiple-outline::before {
  content: "\f0f04";
}

.mdi-music-box-outline::before {
  content: "\f0385";
}

.mdi-music-circle::before {
  content: "\f0386";
}

.mdi-music-circle-outline::before {
  content: "\f0ad4";
}

.mdi-music-clef-alto::before {
  content: "\f0f6e";
}

.mdi-music-clef-bass::before {
  content: "\f0f6f";
}

.mdi-music-clef-treble::before {
  content: "\f0f70";
}

.mdi-music-note::before {
  content: "\f0387";
}

.mdi-music-note-bluetooth::before {
  content: "\f05fe";
}

.mdi-music-note-bluetooth-off::before {
  content: "\f05ff";
}

.mdi-music-note-eighth::before {
  content: "\f0388";
}

.mdi-music-note-eighth-dotted::before {
  content: "\f0f71";
}

.mdi-music-note-half::before {
  content: "\f0389";
}

.mdi-music-note-half-dotted::before {
  content: "\f0f72";
}

.mdi-music-note-off::before {
  content: "\f038a";
}

.mdi-music-note-off-outline::before {
  content: "\f0f73";
}

.mdi-music-note-outline::before {
  content: "\f0f74";
}

.mdi-music-note-plus::before {
  content: "\f0dde";
}

.mdi-music-note-quarter::before {
  content: "\f038b";
}

.mdi-music-note-quarter-dotted::before {
  content: "\f0f75";
}

.mdi-music-note-sixteenth::before {
  content: "\f038c";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "\f0f76";
}

.mdi-music-note-whole::before {
  content: "\f038d";
}

.mdi-music-note-whole-dotted::before {
  content: "\f0f77";
}

.mdi-music-off::before {
  content: "\f075b";
}

.mdi-music-rest-eighth::before {
  content: "\f0f78";
}

.mdi-music-rest-half::before {
  content: "\f0f79";
}

.mdi-music-rest-quarter::before {
  content: "\f0f7a";
}

.mdi-music-rest-sixteenth::before {
  content: "\f0f7b";
}

.mdi-music-rest-whole::before {
  content: "\f0f7c";
}

.mdi-mustache::before {
  content: "\f15de";
}

.mdi-nail::before {
  content: "\f0ddf";
}

.mdi-nas::before {
  content: "\f08f3";
}

.mdi-nativescript::before {
  content: "\f0880";
}

.mdi-nature::before {
  content: "\f038e";
}

.mdi-nature-people::before {
  content: "\f038f";
}

.mdi-navigation::before {
  content: "\f0390";
}

.mdi-navigation-outline::before {
  content: "\f1607";
}

.mdi-near-me::before {
  content: "\f05cd";
}

.mdi-necklace::before {
  content: "\f0f0b";
}

.mdi-needle::before {
  content: "\f0391";
}

.mdi-netflix::before {
  content: "\f0746";
}

.mdi-network::before {
  content: "\f06f3";
}

.mdi-network-off::before {
  content: "\f0c9b";
}

.mdi-network-off-outline::before {
  content: "\f0c9c";
}

.mdi-network-outline::before {
  content: "\f0c9d";
}

.mdi-network-strength-1::before {
  content: "\f08f4";
}

.mdi-network-strength-1-alert::before {
  content: "\f08f5";
}

.mdi-network-strength-2::before {
  content: "\f08f6";
}

.mdi-network-strength-2-alert::before {
  content: "\f08f7";
}

.mdi-network-strength-3::before {
  content: "\f08f8";
}

.mdi-network-strength-3-alert::before {
  content: "\f08f9";
}

.mdi-network-strength-4::before {
  content: "\f08fa";
}

.mdi-network-strength-4-alert::before {
  content: "\f08fb";
}

.mdi-network-strength-off::before {
  content: "\f08fc";
}

.mdi-network-strength-off-outline::before {
  content: "\f08fd";
}

.mdi-network-strength-outline::before {
  content: "\f08fe";
}

.mdi-new-box::before {
  content: "\f0394";
}

.mdi-newspaper::before {
  content: "\f0395";
}

.mdi-newspaper-minus::before {
  content: "\f0f0c";
}

.mdi-newspaper-plus::before {
  content: "\f0f0d";
}

.mdi-newspaper-variant::before {
  content: "\f1001";
}

.mdi-newspaper-variant-multiple::before {
  content: "\f1002";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "\f1003";
}

.mdi-newspaper-variant-outline::before {
  content: "\f1004";
}

.mdi-nfc::before {
  content: "\f0396";
}

.mdi-nfc-search-variant::before {
  content: "\f0e53";
}

.mdi-nfc-tap::before {
  content: "\f0397";
}

.mdi-nfc-variant::before {
  content: "\f0398";
}

.mdi-nfc-variant-off::before {
  content: "\f0e54";
}

.mdi-ninja::before {
  content: "\f0774";
}

.mdi-nintendo-game-boy::before {
  content: "\f1393";
}

.mdi-nintendo-switch::before {
  content: "\f07e1";
}

.mdi-nintendo-wii::before {
  content: "\f05ab";
}

.mdi-nintendo-wiiu::before {
  content: "\f072d";
}

.mdi-nix::before {
  content: "\f1105";
}

.mdi-nodejs::before {
  content: "\f0399";
}

.mdi-noodles::before {
  content: "\f117e";
}

.mdi-not-equal::before {
  content: "\f098d";
}

.mdi-not-equal-variant::before {
  content: "\f098e";
}

.mdi-note::before {
  content: "\f039a";
}

.mdi-note-minus::before {
  content: "\f164f";
}

.mdi-note-minus-outline::before {
  content: "\f1650";
}

.mdi-note-multiple::before {
  content: "\f06b8";
}

.mdi-note-multiple-outline::before {
  content: "\f06b9";
}

.mdi-note-outline::before {
  content: "\f039b";
}

.mdi-note-plus::before {
  content: "\f039c";
}

.mdi-note-plus-outline::before {
  content: "\f039d";
}

.mdi-note-remove::before {
  content: "\f1651";
}

.mdi-note-remove-outline::before {
  content: "\f1652";
}

.mdi-note-search::before {
  content: "\f1653";
}

.mdi-note-search-outline::before {
  content: "\f1654";
}

.mdi-note-text::before {
  content: "\f039e";
}

.mdi-note-text-outline::before {
  content: "\f11d7";
}

.mdi-notebook::before {
  content: "\f082e";
}

.mdi-notebook-check::before {
  content: "\f14f5";
}

.mdi-notebook-check-outline::before {
  content: "\f14f6";
}

.mdi-notebook-edit::before {
  content: "\f14e7";
}

.mdi-notebook-edit-outline::before {
  content: "\f14e9";
}

.mdi-notebook-minus::before {
  content: "\f1610";
}

.mdi-notebook-minus-outline::before {
  content: "\f1611";
}

.mdi-notebook-multiple::before {
  content: "\f0e55";
}

.mdi-notebook-outline::before {
  content: "\f0ebf";
}

.mdi-notebook-plus::before {
  content: "\f1612";
}

.mdi-notebook-plus-outline::before {
  content: "\f1613";
}

.mdi-notebook-remove::before {
  content: "\f1614";
}

.mdi-notebook-remove-outline::before {
  content: "\f1615";
}

.mdi-notification-clear-all::before {
  content: "\f039f";
}

.mdi-npm::before {
  content: "\f06f7";
}

.mdi-nuke::before {
  content: "\f06a4";
}

.mdi-null::before {
  content: "\f07e2";
}

.mdi-numeric::before {
  content: "\f03a0";
}

.mdi-numeric-0::before {
  content: "\f0b39";
}

.mdi-numeric-0-box::before {
  content: "\f03a1";
}

.mdi-numeric-0-box-multiple::before {
  content: "\f0f0e";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f03a2";
}

.mdi-numeric-0-box-outline::before {
  content: "\f03a3";
}

.mdi-numeric-0-circle::before {
  content: "\f0c9e";
}

.mdi-numeric-0-circle-outline::before {
  content: "\f0c9f";
}

.mdi-numeric-1::before {
  content: "\f0b3a";
}

.mdi-numeric-1-box::before {
  content: "\f03a4";
}

.mdi-numeric-1-box-multiple::before {
  content: "\f0f0f";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f03a5";
}

.mdi-numeric-1-box-outline::before {
  content: "\f03a6";
}

.mdi-numeric-1-circle::before {
  content: "\f0ca0";
}

.mdi-numeric-1-circle-outline::before {
  content: "\f0ca1";
}

.mdi-numeric-10::before {
  content: "\f0fe9";
}

.mdi-numeric-10-box::before {
  content: "\f0f7d";
}

.mdi-numeric-10-box-multiple::before {
  content: "\f0fea";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "\f0feb";
}

.mdi-numeric-10-box-outline::before {
  content: "\f0f7e";
}

.mdi-numeric-10-circle::before {
  content: "\f0fec";
}

.mdi-numeric-10-circle-outline::before {
  content: "\f0fed";
}

.mdi-numeric-2::before {
  content: "\f0b3b";
}

.mdi-numeric-2-box::before {
  content: "\f03a7";
}

.mdi-numeric-2-box-multiple::before {
  content: "\f0f10";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f03a8";
}

.mdi-numeric-2-box-outline::before {
  content: "\f03a9";
}

.mdi-numeric-2-circle::before {
  content: "\f0ca2";
}

.mdi-numeric-2-circle-outline::before {
  content: "\f0ca3";
}

.mdi-numeric-3::before {
  content: "\f0b3c";
}

.mdi-numeric-3-box::before {
  content: "\f03aa";
}

.mdi-numeric-3-box-multiple::before {
  content: "\f0f11";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f03ab";
}

.mdi-numeric-3-box-outline::before {
  content: "\f03ac";
}

.mdi-numeric-3-circle::before {
  content: "\f0ca4";
}

.mdi-numeric-3-circle-outline::before {
  content: "\f0ca5";
}

.mdi-numeric-4::before {
  content: "\f0b3d";
}

.mdi-numeric-4-box::before {
  content: "\f03ad";
}

.mdi-numeric-4-box-multiple::before {
  content: "\f0f12";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f03b2";
}

.mdi-numeric-4-box-outline::before {
  content: "\f03ae";
}

.mdi-numeric-4-circle::before {
  content: "\f0ca6";
}

.mdi-numeric-4-circle-outline::before {
  content: "\f0ca7";
}

.mdi-numeric-5::before {
  content: "\f0b3e";
}

.mdi-numeric-5-box::before {
  content: "\f03b1";
}

.mdi-numeric-5-box-multiple::before {
  content: "\f0f13";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f03af";
}

.mdi-numeric-5-box-outline::before {
  content: "\f03b0";
}

.mdi-numeric-5-circle::before {
  content: "\f0ca8";
}

.mdi-numeric-5-circle-outline::before {
  content: "\f0ca9";
}

.mdi-numeric-6::before {
  content: "\f0b3f";
}

.mdi-numeric-6-box::before {
  content: "\f03b3";
}

.mdi-numeric-6-box-multiple::before {
  content: "\f0f14";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f03b4";
}

.mdi-numeric-6-box-outline::before {
  content: "\f03b5";
}

.mdi-numeric-6-circle::before {
  content: "\f0caa";
}

.mdi-numeric-6-circle-outline::before {
  content: "\f0cab";
}

.mdi-numeric-7::before {
  content: "\f0b40";
}

.mdi-numeric-7-box::before {
  content: "\f03b6";
}

.mdi-numeric-7-box-multiple::before {
  content: "\f0f15";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f03b7";
}

.mdi-numeric-7-box-outline::before {
  content: "\f03b8";
}

.mdi-numeric-7-circle::before {
  content: "\f0cac";
}

.mdi-numeric-7-circle-outline::before {
  content: "\f0cad";
}

.mdi-numeric-8::before {
  content: "\f0b41";
}

.mdi-numeric-8-box::before {
  content: "\f03b9";
}

.mdi-numeric-8-box-multiple::before {
  content: "\f0f16";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f03ba";
}

.mdi-numeric-8-box-outline::before {
  content: "\f03bb";
}

.mdi-numeric-8-circle::before {
  content: "\f0cae";
}

.mdi-numeric-8-circle-outline::before {
  content: "\f0caf";
}

.mdi-numeric-9::before {
  content: "\f0b42";
}

.mdi-numeric-9-box::before {
  content: "\f03bc";
}

.mdi-numeric-9-box-multiple::before {
  content: "\f0f17";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f03bd";
}

.mdi-numeric-9-box-outline::before {
  content: "\f03be";
}

.mdi-numeric-9-circle::before {
  content: "\f0cb0";
}

.mdi-numeric-9-circle-outline::before {
  content: "\f0cb1";
}

.mdi-numeric-9-plus::before {
  content: "\f0fee";
}

.mdi-numeric-9-plus-box::before {
  content: "\f03bf";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "\f0f18";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f03c0";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f03c1";
}

.mdi-numeric-9-plus-circle::before {
  content: "\f0cb2";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "\f0cb3";
}

.mdi-numeric-negative-1::before {
  content: "\f1052";
}

.mdi-numeric-positive-1::before {
  content: "\f15cb";
}

.mdi-nut::before {
  content: "\f06f8";
}

.mdi-nutrition::before {
  content: "\f03c2";
}

.mdi-nuxt::before {
  content: "\f1106";
}

.mdi-oar::before {
  content: "\f067c";
}

.mdi-ocarina::before {
  content: "\f0de0";
}

.mdi-oci::before {
  content: "\f12e9";
}

.mdi-ocr::before {
  content: "\f113a";
}

.mdi-octagon::before {
  content: "\f03c3";
}

.mdi-octagon-outline::before {
  content: "\f03c4";
}

.mdi-octagram::before {
  content: "\f06f9";
}

.mdi-octagram-outline::before {
  content: "\f0775";
}

.mdi-odnoklassniki::before {
  content: "\f03c5";
}

.mdi-offer::before {
  content: "\f121b";
}

.mdi-office-building::before {
  content: "\f0991";
}

.mdi-office-building-marker::before {
  content: "\f1520";
}

.mdi-office-building-marker-outline::before {
  content: "\f1521";
}

.mdi-office-building-outline::before {
  content: "\f151f";
}

.mdi-oil::before {
  content: "\f03c7";
}

.mdi-oil-lamp::before {
  content: "\f0f19";
}

.mdi-oil-level::before {
  content: "\f1053";
}

.mdi-oil-temperature::before {
  content: "\f0ff8";
}

.mdi-omega::before {
  content: "\f03c9";
}

.mdi-one-up::before {
  content: "\f0bad";
}

.mdi-onepassword::before {
  content: "\f0881";
}

.mdi-opacity::before {
  content: "\f05cc";
}

.mdi-open-in-app::before {
  content: "\f03cb";
}

.mdi-open-in-new::before {
  content: "\f03cc";
}

.mdi-open-source-initiative::before {
  content: "\f0bae";
}

.mdi-openid::before {
  content: "\f03cd";
}

.mdi-opera::before {
  content: "\f03ce";
}

.mdi-orbit::before {
  content: "\f0018";
}

.mdi-orbit-variant::before {
  content: "\f15db";
}

.mdi-order-alphabetical-ascending::before {
  content: "\f020d";
}

.mdi-order-alphabetical-descending::before {
  content: "\f0d07";
}

.mdi-order-bool-ascending::before {
  content: "\f02be";
}

.mdi-order-bool-ascending-variant::before {
  content: "\f098f";
}

.mdi-order-bool-descending::before {
  content: "\f1384";
}

.mdi-order-bool-descending-variant::before {
  content: "\f0990";
}

.mdi-order-numeric-ascending::before {
  content: "\f0545";
}

.mdi-order-numeric-descending::before {
  content: "\f0546";
}

.mdi-origin::before {
  content: "\f0b43";
}

.mdi-ornament::before {
  content: "\f03cf";
}

.mdi-ornament-variant::before {
  content: "\f03d0";
}

.mdi-outdoor-lamp::before {
  content: "\f1054";
}

.mdi-overscan::before {
  content: "\f1005";
}

.mdi-owl::before {
  content: "\f03d2";
}

.mdi-pac-man::before {
  content: "\f0baf";
}

.mdi-package::before {
  content: "\f03d3";
}

.mdi-package-down::before {
  content: "\f03d4";
}

.mdi-package-up::before {
  content: "\f03d5";
}

.mdi-package-variant::before {
  content: "\f03d6";
}

.mdi-package-variant-closed::before {
  content: "\f03d7";
}

.mdi-page-first::before {
  content: "\f0600";
}

.mdi-page-last::before {
  content: "\f0601";
}

.mdi-page-layout-body::before {
  content: "\f06fa";
}

.mdi-page-layout-footer::before {
  content: "\f06fb";
}

.mdi-page-layout-header::before {
  content: "\f06fc";
}

.mdi-page-layout-header-footer::before {
  content: "\f0f7f";
}

.mdi-page-layout-sidebar-left::before {
  content: "\f06fd";
}

.mdi-page-layout-sidebar-right::before {
  content: "\f06fe";
}

.mdi-page-next::before {
  content: "\f0bb0";
}

.mdi-page-next-outline::before {
  content: "\f0bb1";
}

.mdi-page-previous::before {
  content: "\f0bb2";
}

.mdi-page-previous-outline::before {
  content: "\f0bb3";
}

.mdi-pail::before {
  content: "\f1417";
}

.mdi-pail-minus::before {
  content: "\f1437";
}

.mdi-pail-minus-outline::before {
  content: "\f143c";
}

.mdi-pail-off::before {
  content: "\f1439";
}

.mdi-pail-off-outline::before {
  content: "\f143e";
}

.mdi-pail-outline::before {
  content: "\f143a";
}

.mdi-pail-plus::before {
  content: "\f1436";
}

.mdi-pail-plus-outline::before {
  content: "\f143b";
}

.mdi-pail-remove::before {
  content: "\f1438";
}

.mdi-pail-remove-outline::before {
  content: "\f143d";
}

.mdi-palette::before {
  content: "\f03d8";
}

.mdi-palette-advanced::before {
  content: "\f03d9";
}

.mdi-palette-outline::before {
  content: "\f0e0c";
}

.mdi-palette-swatch::before {
  content: "\f08b5";
}

.mdi-palette-swatch-outline::before {
  content: "\f135c";
}

.mdi-palm-tree::before {
  content: "\f1055";
}

.mdi-pan::before {
  content: "\f0bb4";
}

.mdi-pan-bottom-left::before {
  content: "\f0bb5";
}

.mdi-pan-bottom-right::before {
  content: "\f0bb6";
}

.mdi-pan-down::before {
  content: "\f0bb7";
}

.mdi-pan-horizontal::before {
  content: "\f0bb8";
}

.mdi-pan-left::before {
  content: "\f0bb9";
}

.mdi-pan-right::before {
  content: "\f0bba";
}

.mdi-pan-top-left::before {
  content: "\f0bbb";
}

.mdi-pan-top-right::before {
  content: "\f0bbc";
}

.mdi-pan-up::before {
  content: "\f0bbd";
}

.mdi-pan-vertical::before {
  content: "\f0bbe";
}

.mdi-panda::before {
  content: "\f03da";
}

.mdi-pandora::before {
  content: "\f03db";
}

.mdi-panorama::before {
  content: "\f03dc";
}

.mdi-panorama-fisheye::before {
  content: "\f03dd";
}

.mdi-panorama-horizontal::before {
  content: "\f03de";
}

.mdi-panorama-vertical::before {
  content: "\f03df";
}

.mdi-panorama-wide-angle::before {
  content: "\f03e0";
}

.mdi-paper-cut-vertical::before {
  content: "\f03e1";
}

.mdi-paper-roll::before {
  content: "\f1157";
}

.mdi-paper-roll-outline::before {
  content: "\f1158";
}

.mdi-paperclip::before {
  content: "\f03e2";
}

.mdi-parachute::before {
  content: "\f0cb4";
}

.mdi-parachute-outline::before {
  content: "\f0cb5";
}

.mdi-parking::before {
  content: "\f03e3";
}

.mdi-party-popper::before {
  content: "\f1056";
}

.mdi-passport::before {
  content: "\f07e3";
}

.mdi-passport-biometric::before {
  content: "\f0de1";
}

.mdi-pasta::before {
  content: "\f1160";
}

.mdi-patio-heater::before {
  content: "\f0f80";
}

.mdi-patreon::before {
  content: "\f0882";
}

.mdi-pause::before {
  content: "\f03e4";
}

.mdi-pause-circle::before {
  content: "\f03e5";
}

.mdi-pause-circle-outline::before {
  content: "\f03e6";
}

.mdi-pause-octagon::before {
  content: "\f03e7";
}

.mdi-pause-octagon-outline::before {
  content: "\f03e8";
}

.mdi-paw::before {
  content: "\f03e9";
}

.mdi-paw-off::before {
  content: "\f0657";
}

.mdi-paw-off-outline::before {
  content: "\f1676";
}

.mdi-paw-outline::before {
  content: "\f1675";
}

.mdi-pdf-box::before {
  content: "\f0e56";
}

.mdi-peace::before {
  content: "\f0884";
}

.mdi-peanut::before {
  content: "\f0ffc";
}

.mdi-peanut-off::before {
  content: "\f0ffd";
}

.mdi-peanut-off-outline::before {
  content: "\f0fff";
}

.mdi-peanut-outline::before {
  content: "\f0ffe";
}

.mdi-pen::before {
  content: "\f03ea";
}

.mdi-pen-lock::before {
  content: "\f0de2";
}

.mdi-pen-minus::before {
  content: "\f0de3";
}

.mdi-pen-off::before {
  content: "\f0de4";
}

.mdi-pen-plus::before {
  content: "\f0de5";
}

.mdi-pen-remove::before {
  content: "\f0de6";
}

.mdi-pencil::before {
  content: "\f03eb";
}

.mdi-pencil-box::before {
  content: "\f03ec";
}

.mdi-pencil-box-multiple::before {
  content: "\f1144";
}

.mdi-pencil-box-multiple-outline::before {
  content: "\f1145";
}

.mdi-pencil-box-outline::before {
  content: "\f03ed";
}

.mdi-pencil-circle::before {
  content: "\f06ff";
}

.mdi-pencil-circle-outline::before {
  content: "\f0776";
}

.mdi-pencil-lock::before {
  content: "\f03ee";
}

.mdi-pencil-lock-outline::before {
  content: "\f0de7";
}

.mdi-pencil-minus::before {
  content: "\f0de8";
}

.mdi-pencil-minus-outline::before {
  content: "\f0de9";
}

.mdi-pencil-off::before {
  content: "\f03ef";
}

.mdi-pencil-off-outline::before {
  content: "\f0dea";
}

.mdi-pencil-outline::before {
  content: "\f0cb6";
}

.mdi-pencil-plus::before {
  content: "\f0deb";
}

.mdi-pencil-plus-outline::before {
  content: "\f0dec";
}

.mdi-pencil-remove::before {
  content: "\f0ded";
}

.mdi-pencil-remove-outline::before {
  content: "\f0dee";
}

.mdi-pencil-ruler::before {
  content: "\f1353";
}

.mdi-penguin::before {
  content: "\f0ec0";
}

.mdi-pentagon::before {
  content: "\f0701";
}

.mdi-pentagon-outline::before {
  content: "\f0700";
}

.mdi-pentagram::before {
  content: "\f1667";
}

.mdi-percent::before {
  content: "\f03f0";
}

.mdi-percent-outline::before {
  content: "\f1278";
}

.mdi-periodic-table::before {
  content: "\f08b6";
}

.mdi-perspective-less::before {
  content: "\f0d23";
}

.mdi-perspective-more::before {
  content: "\f0d24";
}

.mdi-pharmacy::before {
  content: "\f03f1";
}

.mdi-phone::before {
  content: "\f03f2";
}

.mdi-phone-alert::before {
  content: "\f0f1a";
}

.mdi-phone-alert-outline::before {
  content: "\f118e";
}

.mdi-phone-bluetooth::before {
  content: "\f03f3";
}

.mdi-phone-bluetooth-outline::before {
  content: "\f118f";
}

.mdi-phone-cancel::before {
  content: "\f10bc";
}

.mdi-phone-cancel-outline::before {
  content: "\f1190";
}

.mdi-phone-check::before {
  content: "\f11a9";
}

.mdi-phone-check-outline::before {
  content: "\f11aa";
}

.mdi-phone-classic::before {
  content: "\f0602";
}

.mdi-phone-classic-off::before {
  content: "\f1279";
}

.mdi-phone-dial::before {
  content: "\f1559";
}

.mdi-phone-dial-outline::before {
  content: "\f155a";
}

.mdi-phone-forward::before {
  content: "\f03f4";
}

.mdi-phone-forward-outline::before {
  content: "\f1191";
}

.mdi-phone-hangup::before {
  content: "\f03f5";
}

.mdi-phone-hangup-outline::before {
  content: "\f1192";
}

.mdi-phone-in-talk::before {
  content: "\f03f6";
}

.mdi-phone-in-talk-outline::before {
  content: "\f1182";
}

.mdi-phone-incoming::before {
  content: "\f03f7";
}

.mdi-phone-incoming-outline::before {
  content: "\f1193";
}

.mdi-phone-lock::before {
  content: "\f03f8";
}

.mdi-phone-lock-outline::before {
  content: "\f1194";
}

.mdi-phone-log::before {
  content: "\f03f9";
}

.mdi-phone-log-outline::before {
  content: "\f1195";
}

.mdi-phone-message::before {
  content: "\f1196";
}

.mdi-phone-message-outline::before {
  content: "\f1197";
}

.mdi-phone-minus::before {
  content: "\f0658";
}

.mdi-phone-minus-outline::before {
  content: "\f1198";
}

.mdi-phone-missed::before {
  content: "\f03fa";
}

.mdi-phone-missed-outline::before {
  content: "\f11a5";
}

.mdi-phone-off::before {
  content: "\f0def";
}

.mdi-phone-off-outline::before {
  content: "\f11a6";
}

.mdi-phone-outgoing::before {
  content: "\f03fb";
}

.mdi-phone-outgoing-outline::before {
  content: "\f1199";
}

.mdi-phone-outline::before {
  content: "\f0df0";
}

.mdi-phone-paused::before {
  content: "\f03fc";
}

.mdi-phone-paused-outline::before {
  content: "\f119a";
}

.mdi-phone-plus::before {
  content: "\f0659";
}

.mdi-phone-plus-outline::before {
  content: "\f119b";
}

.mdi-phone-remove::before {
  content: "\f152f";
}

.mdi-phone-remove-outline::before {
  content: "\f1530";
}

.mdi-phone-return::before {
  content: "\f082f";
}

.mdi-phone-return-outline::before {
  content: "\f119c";
}

.mdi-phone-ring::before {
  content: "\f11ab";
}

.mdi-phone-ring-outline::before {
  content: "\f11ac";
}

.mdi-phone-rotate-landscape::before {
  content: "\f0885";
}

.mdi-phone-rotate-portrait::before {
  content: "\f0886";
}

.mdi-phone-settings::before {
  content: "\f03fd";
}

.mdi-phone-settings-outline::before {
  content: "\f119d";
}

.mdi-phone-voip::before {
  content: "\f03fe";
}

.mdi-pi::before {
  content: "\f03ff";
}

.mdi-pi-box::before {
  content: "\f0400";
}

.mdi-pi-hole::before {
  content: "\f0df1";
}

.mdi-piano::before {
  content: "\f067d";
}

.mdi-pickaxe::before {
  content: "\f08b7";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "\f0e57";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\f0e58";
}

.mdi-picture-in-picture-top-right::before {
  content: "\f0e59";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "\f0e5a";
}

.mdi-pier::before {
  content: "\f0887";
}

.mdi-pier-crane::before {
  content: "\f0888";
}

.mdi-pig::before {
  content: "\f0401";
}

.mdi-pig-variant::before {
  content: "\f1006";
}

.mdi-pig-variant-outline::before {
  content: "\f1678";
}

.mdi-piggy-bank::before {
  content: "\f1007";
}

.mdi-piggy-bank-outline::before {
  content: "\f1679";
}

.mdi-pill::before {
  content: "\f0402";
}

.mdi-pillar::before {
  content: "\f0702";
}

.mdi-pin::before {
  content: "\f0403";
}

.mdi-pin-off::before {
  content: "\f0404";
}

.mdi-pin-off-outline::before {
  content: "\f0930";
}

.mdi-pin-outline::before {
  content: "\f0931";
}

.mdi-pine-tree::before {
  content: "\f0405";
}

.mdi-pine-tree-box::before {
  content: "\f0406";
}

.mdi-pine-tree-fire::before {
  content: "\f141a";
}

.mdi-pinterest::before {
  content: "\f0407";
}

.mdi-pinwheel::before {
  content: "\f0ad5";
}

.mdi-pinwheel-outline::before {
  content: "\f0ad6";
}

.mdi-pipe::before {
  content: "\f07e5";
}

.mdi-pipe-disconnected::before {
  content: "\f07e6";
}

.mdi-pipe-leak::before {
  content: "\f0889";
}

.mdi-pipe-wrench::before {
  content: "\f1354";
}

.mdi-pirate::before {
  content: "\f0a08";
}

.mdi-pistol::before {
  content: "\f0703";
}

.mdi-piston::before {
  content: "\f088a";
}

.mdi-pitchfork::before {
  content: "\f1553";
}

.mdi-pizza::before {
  content: "\f0409";
}

.mdi-play::before {
  content: "\f040a";
}

.mdi-play-box::before {
  content: "\f127a";
}

.mdi-play-box-multiple::before {
  content: "\f0d19";
}

.mdi-play-box-multiple-outline::before {
  content: "\f13e6";
}

.mdi-play-box-outline::before {
  content: "\f040b";
}

.mdi-play-circle::before {
  content: "\f040c";
}

.mdi-play-circle-outline::before {
  content: "\f040d";
}

.mdi-play-network::before {
  content: "\f088b";
}

.mdi-play-network-outline::before {
  content: "\f0cb7";
}

.mdi-play-outline::before {
  content: "\f0f1b";
}

.mdi-play-pause::before {
  content: "\f040e";
}

.mdi-play-protected-content::before {
  content: "\f040f";
}

.mdi-play-speed::before {
  content: "\f08ff";
}

.mdi-playlist-check::before {
  content: "\f05c7";
}

.mdi-playlist-edit::before {
  content: "\f0900";
}

.mdi-playlist-minus::before {
  content: "\f0410";
}

.mdi-playlist-music::before {
  content: "\f0cb8";
}

.mdi-playlist-music-outline::before {
  content: "\f0cb9";
}

.mdi-playlist-play::before {
  content: "\f0411";
}

.mdi-playlist-plus::before {
  content: "\f0412";
}

.mdi-playlist-remove::before {
  content: "\f0413";
}

.mdi-playlist-star::before {
  content: "\f0df2";
}

.mdi-plex::before {
  content: "\f06ba";
}

.mdi-plus::before {
  content: "\f0415";
}

.mdi-plus-box::before {
  content: "\f0416";
}

.mdi-plus-box-multiple::before {
  content: "\f0334";
}

.mdi-plus-box-multiple-outline::before {
  content: "\f1143";
}

.mdi-plus-box-outline::before {
  content: "\f0704";
}

.mdi-plus-circle::before {
  content: "\f0417";
}

.mdi-plus-circle-multiple::before {
  content: "\f034c";
}

.mdi-plus-circle-multiple-outline::before {
  content: "\f0418";
}

.mdi-plus-circle-outline::before {
  content: "\f0419";
}

.mdi-plus-minus::before {
  content: "\f0992";
}

.mdi-plus-minus-box::before {
  content: "\f0993";
}

.mdi-plus-minus-variant::before {
  content: "\f14c9";
}

.mdi-plus-network::before {
  content: "\f041a";
}

.mdi-plus-network-outline::before {
  content: "\f0cba";
}

.mdi-plus-one::before {
  content: "\f041b";
}

.mdi-plus-outline::before {
  content: "\f0705";
}

.mdi-plus-thick::before {
  content: "\f11ec";
}

.mdi-podcast::before {
  content: "\f0994";
}

.mdi-podium::before {
  content: "\f0d25";
}

.mdi-podium-bronze::before {
  content: "\f0d26";
}

.mdi-podium-gold::before {
  content: "\f0d27";
}

.mdi-podium-silver::before {
  content: "\f0d28";
}

.mdi-point-of-sale::before {
  content: "\f0d92";
}

.mdi-pokeball::before {
  content: "\f041d";
}

.mdi-pokemon-go::before {
  content: "\f0a09";
}

.mdi-poker-chip::before {
  content: "\f0830";
}

.mdi-polaroid::before {
  content: "\f041e";
}

.mdi-police-badge::before {
  content: "\f1167";
}

.mdi-police-badge-outline::before {
  content: "\f1168";
}

.mdi-poll::before {
  content: "\f041f";
}

.mdi-poll-box::before {
  content: "\f0420";
}

.mdi-poll-box-outline::before {
  content: "\f127b";
}

.mdi-polo::before {
  content: "\f14c3";
}

.mdi-polymer::before {
  content: "\f0421";
}

.mdi-pool::before {
  content: "\f0606";
}

.mdi-popcorn::before {
  content: "\f0422";
}

.mdi-post::before {
  content: "\f1008";
}

.mdi-post-outline::before {
  content: "\f1009";
}

.mdi-postage-stamp::before {
  content: "\f0cbb";
}

.mdi-pot::before {
  content: "\f02e5";
}

.mdi-pot-mix::before {
  content: "\f065b";
}

.mdi-pot-mix-outline::before {
  content: "\f0677";
}

.mdi-pot-outline::before {
  content: "\f02ff";
}

.mdi-pot-steam::before {
  content: "\f065a";
}

.mdi-pot-steam-outline::before {
  content: "\f0326";
}

.mdi-pound::before {
  content: "\f0423";
}

.mdi-pound-box::before {
  content: "\f0424";
}

.mdi-pound-box-outline::before {
  content: "\f117f";
}

.mdi-power::before {
  content: "\f0425";
}

.mdi-power-cycle::before {
  content: "\f0901";
}

.mdi-power-off::before {
  content: "\f0902";
}

.mdi-power-on::before {
  content: "\f0903";
}

.mdi-power-plug::before {
  content: "\f06a5";
}

.mdi-power-plug-off::before {
  content: "\f06a6";
}

.mdi-power-plug-off-outline::before {
  content: "\f1424";
}

.mdi-power-plug-outline::before {
  content: "\f1425";
}

.mdi-power-settings::before {
  content: "\f0426";
}

.mdi-power-sleep::before {
  content: "\f0904";
}

.mdi-power-socket::before {
  content: "\f0427";
}

.mdi-power-socket-au::before {
  content: "\f0905";
}

.mdi-power-socket-de::before {
  content: "\f1107";
}

.mdi-power-socket-eu::before {
  content: "\f07e7";
}

.mdi-power-socket-fr::before {
  content: "\f1108";
}

.mdi-power-socket-it::before {
  content: "\f14ff";
}

.mdi-power-socket-jp::before {
  content: "\f1109";
}

.mdi-power-socket-uk::before {
  content: "\f07e8";
}

.mdi-power-socket-us::before {
  content: "\f07e9";
}

.mdi-power-standby::before {
  content: "\f0906";
}

.mdi-powershell::before {
  content: "\f0a0a";
}

.mdi-prescription::before {
  content: "\f0706";
}

.mdi-presentation::before {
  content: "\f0428";
}

.mdi-presentation-play::before {
  content: "\f0429";
}

.mdi-pretzel::before {
  content: "\f1562";
}

.mdi-printer::before {
  content: "\f042a";
}

.mdi-printer-3d::before {
  content: "\f042b";
}

.mdi-printer-3d-nozzle::before {
  content: "\f0e5b";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "\f11c0";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\f11c1";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "\f0e5c";
}

.mdi-printer-alert::before {
  content: "\f042c";
}

.mdi-printer-check::before {
  content: "\f1146";
}

.mdi-printer-eye::before {
  content: "\f1458";
}

.mdi-printer-off::before {
  content: "\f0e5d";
}

.mdi-printer-pos::before {
  content: "\f1057";
}

.mdi-printer-search::before {
  content: "\f1457";
}

.mdi-printer-settings::before {
  content: "\f0707";
}

.mdi-printer-wireless::before {
  content: "\f0a0b";
}

.mdi-priority-high::before {
  content: "\f0603";
}

.mdi-priority-low::before {
  content: "\f0604";
}

.mdi-professional-hexagon::before {
  content: "\f042d";
}

.mdi-progress-alert::before {
  content: "\f0cbc";
}

.mdi-progress-check::before {
  content: "\f0995";
}

.mdi-progress-clock::before {
  content: "\f0996";
}

.mdi-progress-close::before {
  content: "\f110a";
}

.mdi-progress-download::before {
  content: "\f0997";
}

.mdi-progress-question::before {
  content: "\f1522";
}

.mdi-progress-upload::before {
  content: "\f0998";
}

.mdi-progress-wrench::before {
  content: "\f0cbd";
}

.mdi-projector::before {
  content: "\f042e";
}

.mdi-projector-screen::before {
  content: "\f042f";
}

.mdi-propane-tank::before {
  content: "\f1357";
}

.mdi-propane-tank-outline::before {
  content: "\f1358";
}

.mdi-protocol::before {
  content: "\f0fd8";
}

.mdi-publish::before {
  content: "\f06a7";
}

.mdi-pulse::before {
  content: "\f0430";
}

.mdi-pump::before {
  content: "\f1402";
}

.mdi-pumpkin::before {
  content: "\f0bbf";
}

.mdi-purse::before {
  content: "\f0f1c";
}

.mdi-purse-outline::before {
  content: "\f0f1d";
}

.mdi-puzzle::before {
  content: "\f0431";
}

.mdi-puzzle-check::before {
  content: "\f1426";
}

.mdi-puzzle-check-outline::before {
  content: "\f1427";
}

.mdi-puzzle-edit::before {
  content: "\f14d3";
}

.mdi-puzzle-edit-outline::before {
  content: "\f14d9";
}

.mdi-puzzle-heart::before {
  content: "\f14d4";
}

.mdi-puzzle-heart-outline::before {
  content: "\f14da";
}

.mdi-puzzle-minus::before {
  content: "\f14d1";
}

.mdi-puzzle-minus-outline::before {
  content: "\f14d7";
}

.mdi-puzzle-outline::before {
  content: "\f0a66";
}

.mdi-puzzle-plus::before {
  content: "\f14d0";
}

.mdi-puzzle-plus-outline::before {
  content: "\f14d6";
}

.mdi-puzzle-remove::before {
  content: "\f14d2";
}

.mdi-puzzle-remove-outline::before {
  content: "\f14d8";
}

.mdi-puzzle-star::before {
  content: "\f14d5";
}

.mdi-puzzle-star-outline::before {
  content: "\f14db";
}

.mdi-qi::before {
  content: "\f0999";
}

.mdi-qqchat::before {
  content: "\f0605";
}

.mdi-qrcode::before {
  content: "\f0432";
}

.mdi-qrcode-edit::before {
  content: "\f08b8";
}

.mdi-qrcode-minus::before {
  content: "\f118c";
}

.mdi-qrcode-plus::before {
  content: "\f118b";
}

.mdi-qrcode-remove::before {
  content: "\f118d";
}

.mdi-qrcode-scan::before {
  content: "\f0433";
}

.mdi-quadcopter::before {
  content: "\f0434";
}

.mdi-quality-high::before {
  content: "\f0435";
}

.mdi-quality-low::before {
  content: "\f0a0c";
}

.mdi-quality-medium::before {
  content: "\f0a0d";
}

.mdi-quora::before {
  content: "\f0d29";
}

.mdi-rabbit::before {
  content: "\f0907";
}

.mdi-racing-helmet::before {
  content: "\f0d93";
}

.mdi-racquetball::before {
  content: "\f0d94";
}

.mdi-radar::before {
  content: "\f0437";
}

.mdi-radiator::before {
  content: "\f0438";
}

.mdi-radiator-disabled::before {
  content: "\f0ad7";
}

.mdi-radiator-off::before {
  content: "\f0ad8";
}

.mdi-radio::before {
  content: "\f0439";
}

.mdi-radio-am::before {
  content: "\f0cbe";
}

.mdi-radio-fm::before {
  content: "\f0cbf";
}

.mdi-radio-handheld::before {
  content: "\f043a";
}

.mdi-radio-off::before {
  content: "\f121c";
}

.mdi-radio-tower::before {
  content: "\f043b";
}

.mdi-radioactive::before {
  content: "\f043c";
}

.mdi-radioactive-off::before {
  content: "\f0ec1";
}

.mdi-radiobox-blank::before {
  content: "\f043d";
}

.mdi-radiobox-marked::before {
  content: "\f043e";
}

.mdi-radiology-box::before {
  content: "\f14c5";
}

.mdi-radiology-box-outline::before {
  content: "\f14c6";
}

.mdi-radius::before {
  content: "\f0cc0";
}

.mdi-radius-outline::before {
  content: "\f0cc1";
}

.mdi-railroad-light::before {
  content: "\f0f1e";
}

.mdi-rake::before {
  content: "\f1544";
}

.mdi-raspberry-pi::before {
  content: "\f043f";
}

.mdi-ray-end::before {
  content: "\f0440";
}

.mdi-ray-end-arrow::before {
  content: "\f0441";
}

.mdi-ray-start::before {
  content: "\f0442";
}

.mdi-ray-start-arrow::before {
  content: "\f0443";
}

.mdi-ray-start-end::before {
  content: "\f0444";
}

.mdi-ray-start-vertex-end::before {
  content: "\f15d8";
}

.mdi-ray-vertex::before {
  content: "\f0445";
}

.mdi-react::before {
  content: "\f0708";
}

.mdi-read::before {
  content: "\f0447";
}

.mdi-receipt::before {
  content: "\f0449";
}

.mdi-record::before {
  content: "\f044a";
}

.mdi-record-circle::before {
  content: "\f0ec2";
}

.mdi-record-circle-outline::before {
  content: "\f0ec3";
}

.mdi-record-player::before {
  content: "\f099a";
}

.mdi-record-rec::before {
  content: "\f044b";
}

.mdi-rectangle::before {
  content: "\f0e5e";
}

.mdi-rectangle-outline::before {
  content: "\f0e5f";
}

.mdi-recycle::before {
  content: "\f044c";
}

.mdi-recycle-variant::before {
  content: "\f139d";
}

.mdi-reddit::before {
  content: "\f044d";
}

.mdi-redhat::before {
  content: "\f111b";
}

.mdi-redo::before {
  content: "\f044e";
}

.mdi-redo-variant::before {
  content: "\f044f";
}

.mdi-reflect-horizontal::before {
  content: "\f0a0e";
}

.mdi-reflect-vertical::before {
  content: "\f0a0f";
}

.mdi-refresh::before {
  content: "\f0450";
}

.mdi-refresh-circle::before {
  content: "\f1377";
}

.mdi-regex::before {
  content: "\f0451";
}

.mdi-registered-trademark::before {
  content: "\f0a67";
}

.mdi-reiterate::before {
  content: "\f1588";
}

.mdi-relation-many-to-many::before {
  content: "\f1496";
}

.mdi-relation-many-to-one::before {
  content: "\f1497";
}

.mdi-relation-many-to-one-or-many::before {
  content: "\f1498";
}

.mdi-relation-many-to-only-one::before {
  content: "\f1499";
}

.mdi-relation-many-to-zero-or-many::before {
  content: "\f149a";
}

.mdi-relation-many-to-zero-or-one::before {
  content: "\f149b";
}

.mdi-relation-one-or-many-to-many::before {
  content: "\f149c";
}

.mdi-relation-one-or-many-to-one::before {
  content: "\f149d";
}

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\f149e";
}

.mdi-relation-one-or-many-to-only-one::before {
  content: "\f149f";
}

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\f14a0";
}

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\f14a1";
}

.mdi-relation-one-to-many::before {
  content: "\f14a2";
}

.mdi-relation-one-to-one::before {
  content: "\f14a3";
}

.mdi-relation-one-to-one-or-many::before {
  content: "\f14a4";
}

.mdi-relation-one-to-only-one::before {
  content: "\f14a5";
}

.mdi-relation-one-to-zero-or-many::before {
  content: "\f14a6";
}

.mdi-relation-one-to-zero-or-one::before {
  content: "\f14a7";
}

.mdi-relation-only-one-to-many::before {
  content: "\f14a8";
}

.mdi-relation-only-one-to-one::before {
  content: "\f14a9";
}

.mdi-relation-only-one-to-one-or-many::before {
  content: "\f14aa";
}

.mdi-relation-only-one-to-only-one::before {
  content: "\f14ab";
}

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\f14ac";
}

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\f14ad";
}

.mdi-relation-zero-or-many-to-many::before {
  content: "\f14ae";
}

.mdi-relation-zero-or-many-to-one::before {
  content: "\f14af";
}

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\f14b0";
}

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\f14b1";
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\f14b2";
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\f14b3";
}

.mdi-relation-zero-or-one-to-many::before {
  content: "\f14b4";
}

.mdi-relation-zero-or-one-to-one::before {
  content: "\f14b5";
}

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\f14b6";
}

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\f14b7";
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\f14b8";
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\f14b9";
}

.mdi-relative-scale::before {
  content: "\f0452";
}

.mdi-reload::before {
  content: "\f0453";
}

.mdi-reload-alert::before {
  content: "\f110b";
}

.mdi-reminder::before {
  content: "\f088c";
}

.mdi-remote::before {
  content: "\f0454";
}

.mdi-remote-desktop::before {
  content: "\f08b9";
}

.mdi-remote-off::before {
  content: "\f0ec4";
}

.mdi-remote-tv::before {
  content: "\f0ec5";
}

.mdi-remote-tv-off::before {
  content: "\f0ec6";
}

.mdi-rename-box::before {
  content: "\f0455";
}

.mdi-reorder-horizontal::before {
  content: "\f0688";
}

.mdi-reorder-vertical::before {
  content: "\f0689";
}

.mdi-repeat::before {
  content: "\f0456";
}

.mdi-repeat-off::before {
  content: "\f0457";
}

.mdi-repeat-once::before {
  content: "\f0458";
}

.mdi-replay::before {
  content: "\f0459";
}

.mdi-reply::before {
  content: "\f045a";
}

.mdi-reply-all::before {
  content: "\f045b";
}

.mdi-reply-all-outline::before {
  content: "\f0f1f";
}

.mdi-reply-circle::before {
  content: "\f11ae";
}

.mdi-reply-outline::before {
  content: "\f0f20";
}

.mdi-reproduction::before {
  content: "\f045c";
}

.mdi-resistor::before {
  content: "\f0b44";
}

.mdi-resistor-nodes::before {
  content: "\f0b45";
}

.mdi-resize::before {
  content: "\f0a68";
}

.mdi-resize-bottom-right::before {
  content: "\f045d";
}

.mdi-responsive::before {
  content: "\f045e";
}

.mdi-restart::before {
  content: "\f0709";
}

.mdi-restart-alert::before {
  content: "\f110c";
}

.mdi-restart-off::before {
  content: "\f0d95";
}

.mdi-restore::before {
  content: "\f099b";
}

.mdi-restore-alert::before {
  content: "\f110d";
}

.mdi-rewind::before {
  content: "\f045f";
}

.mdi-rewind-10::before {
  content: "\f0d2a";
}

.mdi-rewind-30::before {
  content: "\f0d96";
}

.mdi-rewind-5::before {
  content: "\f11f9";
}

.mdi-rewind-60::before {
  content: "\f160c";
}

.mdi-rewind-outline::before {
  content: "\f070a";
}

.mdi-rhombus::before {
  content: "\f070b";
}

.mdi-rhombus-medium::before {
  content: "\f0a10";
}

.mdi-rhombus-medium-outline::before {
  content: "\f14dc";
}

.mdi-rhombus-outline::before {
  content: "\f070c";
}

.mdi-rhombus-split::before {
  content: "\f0a11";
}

.mdi-rhombus-split-outline::before {
  content: "\f14dd";
}

.mdi-ribbon::before {
  content: "\f0460";
}

.mdi-rice::before {
  content: "\f07ea";
}

.mdi-rickshaw::before {
  content: "\f15bb";
}

.mdi-rickshaw-electric::before {
  content: "\f15bc";
}

.mdi-ring::before {
  content: "\f07eb";
}

.mdi-rivet::before {
  content: "\f0e60";
}

.mdi-road::before {
  content: "\f0461";
}

.mdi-road-variant::before {
  content: "\f0462";
}

.mdi-robber::before {
  content: "\f1058";
}

.mdi-robot::before {
  content: "\f06a9";
}

.mdi-robot-angry::before {
  content: "\f169d";
}

.mdi-robot-angry-outline::before {
  content: "\f169e";
}

.mdi-robot-confused::before {
  content: "\f169f";
}

.mdi-robot-confused-outline::before {
  content: "\f16a0";
}

.mdi-robot-dead::before {
  content: "\f16a1";
}

.mdi-robot-dead-outline::before {
  content: "\f16a2";
}

.mdi-robot-excited::before {
  content: "\f16a3";
}

.mdi-robot-excited-outline::before {
  content: "\f16a4";
}

.mdi-robot-industrial::before {
  content: "\f0b46";
}

.mdi-robot-love::before {
  content: "\f16a5";
}

.mdi-robot-love-outline::before {
  content: "\f16a6";
}

.mdi-robot-mower::before {
  content: "\f11f7";
}

.mdi-robot-mower-outline::before {
  content: "\f11f3";
}

.mdi-robot-off::before {
  content: "\f16a7";
}

.mdi-robot-off-outline::before {
  content: "\f167b";
}

.mdi-robot-outline::before {
  content: "\f167a";
}

.mdi-robot-vacuum::before {
  content: "\f070d";
}

.mdi-robot-vacuum-variant::before {
  content: "\f0908";
}

.mdi-rocket::before {
  content: "\f0463";
}

.mdi-rocket-launch::before {
  content: "\f14de";
}

.mdi-rocket-launch-outline::before {
  content: "\f14df";
}

.mdi-rocket-outline::before {
  content: "\f13af";
}

.mdi-rodent::before {
  content: "\f1327";
}

.mdi-roller-skate::before {
  content: "\f0d2b";
}

.mdi-roller-skate-off::before {
  content: "\f0145";
}

.mdi-rollerblade::before {
  content: "\f0d2c";
}

.mdi-rollerblade-off::before {
  content: "\f002e";
}

.mdi-rollupjs::before {
  content: "\f0bc0";
}

.mdi-roman-numeral-1::before {
  content: "\f1088";
}

.mdi-roman-numeral-10::before {
  content: "\f1091";
}

.mdi-roman-numeral-2::before {
  content: "\f1089";
}

.mdi-roman-numeral-3::before {
  content: "\f108a";
}

.mdi-roman-numeral-4::before {
  content: "\f108b";
}

.mdi-roman-numeral-5::before {
  content: "\f108c";
}

.mdi-roman-numeral-6::before {
  content: "\f108d";
}

.mdi-roman-numeral-7::before {
  content: "\f108e";
}

.mdi-roman-numeral-8::before {
  content: "\f108f";
}

.mdi-roman-numeral-9::before {
  content: "\f1090";
}

.mdi-room-service::before {
  content: "\f088d";
}

.mdi-room-service-outline::before {
  content: "\f0d97";
}

.mdi-rotate-3d::before {
  content: "\f0ec7";
}

.mdi-rotate-3d-variant::before {
  content: "\f0464";
}

.mdi-rotate-left::before {
  content: "\f0465";
}

.mdi-rotate-left-variant::before {
  content: "\f0466";
}

.mdi-rotate-orbit::before {
  content: "\f0d98";
}

.mdi-rotate-right::before {
  content: "\f0467";
}

.mdi-rotate-right-variant::before {
  content: "\f0468";
}

.mdi-rounded-corner::before {
  content: "\f0607";
}

.mdi-router::before {
  content: "\f11e2";
}

.mdi-router-network::before {
  content: "\f1087";
}

.mdi-router-wireless::before {
  content: "\f0469";
}

.mdi-router-wireless-off::before {
  content: "\f15a3";
}

.mdi-router-wireless-settings::before {
  content: "\f0a69";
}

.mdi-routes::before {
  content: "\f046a";
}

.mdi-routes-clock::before {
  content: "\f1059";
}

.mdi-rowing::before {
  content: "\f0608";
}

.mdi-rss::before {
  content: "\f046b";
}

.mdi-rss-box::before {
  content: "\f046c";
}

.mdi-rss-off::before {
  content: "\f0f21";
}

.mdi-rug::before {
  content: "\f1475";
}

.mdi-rugby::before {
  content: "\f0d99";
}

.mdi-ruler::before {
  content: "\f046d";
}

.mdi-ruler-square::before {
  content: "\f0cc2";
}

.mdi-ruler-square-compass::before {
  content: "\f0ebe";
}

.mdi-run::before {
  content: "\f070e";
}

.mdi-run-fast::before {
  content: "\f046e";
}

.mdi-rv-truck::before {
  content: "\f11d4";
}

.mdi-sack::before {
  content: "\f0d2e";
}

.mdi-sack-percent::before {
  content: "\f0d2f";
}

.mdi-safe::before {
  content: "\f0a6a";
}

.mdi-safe-square::before {
  content: "\f127c";
}

.mdi-safe-square-outline::before {
  content: "\f127d";
}

.mdi-safety-goggles::before {
  content: "\f0d30";
}

.mdi-sail-boat::before {
  content: "\f0ec8";
}

.mdi-sale::before {
  content: "\f046f";
}

.mdi-salesforce::before {
  content: "\f088e";
}

.mdi-sass::before {
  content: "\f07ec";
}

.mdi-satellite::before {
  content: "\f0470";
}

.mdi-satellite-uplink::before {
  content: "\f0909";
}

.mdi-satellite-variant::before {
  content: "\f0471";
}

.mdi-sausage::before {
  content: "\f08ba";
}

.mdi-saw-blade::before {
  content: "\f0e61";
}

.mdi-sawtooth-wave::before {
  content: "\f147a";
}

.mdi-saxophone::before {
  content: "\f0609";
}

.mdi-scale::before {
  content: "\f0472";
}

.mdi-scale-balance::before {
  content: "\f05d1";
}

.mdi-scale-bathroom::before {
  content: "\f0473";
}

.mdi-scale-off::before {
  content: "\f105a";
}

.mdi-scan-helper::before {
  content: "\f13d8";
}

.mdi-scanner::before {
  content: "\f06ab";
}

.mdi-scanner-off::before {
  content: "\f090a";
}

.mdi-scatter-plot::before {
  content: "\f0ec9";
}

.mdi-scatter-plot-outline::before {
  content: "\f0eca";
}

.mdi-school::before {
  content: "\f0474";
}

.mdi-school-outline::before {
  content: "\f1180";
}

.mdi-scissors-cutting::before {
  content: "\f0a6b";
}

.mdi-scooter::before {
  content: "\f15bd";
}

.mdi-scooter-electric::before {
  content: "\f15be";
}

.mdi-scoreboard::before {
  content: "\f127e";
}

.mdi-scoreboard-outline::before {
  content: "\f127f";
}

.mdi-screen-rotation::before {
  content: "\f0475";
}

.mdi-screen-rotation-lock::before {
  content: "\f0478";
}

.mdi-screw-flat-top::before {
  content: "\f0df3";
}

.mdi-screw-lag::before {
  content: "\f0df4";
}

.mdi-screw-machine-flat-top::before {
  content: "\f0df5";
}

.mdi-screw-machine-round-top::before {
  content: "\f0df6";
}

.mdi-screw-round-top::before {
  content: "\f0df7";
}

.mdi-screwdriver::before {
  content: "\f0476";
}

.mdi-script::before {
  content: "\f0bc1";
}

.mdi-script-outline::before {
  content: "\f0477";
}

.mdi-script-text::before {
  content: "\f0bc2";
}

.mdi-script-text-outline::before {
  content: "\f0bc3";
}

.mdi-sd::before {
  content: "\f0479";
}

.mdi-seal::before {
  content: "\f047a";
}

.mdi-seal-variant::before {
  content: "\f0fd9";
}

.mdi-search-web::before {
  content: "\f070f";
}

.mdi-seat::before {
  content: "\f0cc3";
}

.mdi-seat-flat::before {
  content: "\f047b";
}

.mdi-seat-flat-angled::before {
  content: "\f047c";
}

.mdi-seat-individual-suite::before {
  content: "\f047d";
}

.mdi-seat-legroom-extra::before {
  content: "\f047e";
}

.mdi-seat-legroom-normal::before {
  content: "\f047f";
}

.mdi-seat-legroom-reduced::before {
  content: "\f0480";
}

.mdi-seat-outline::before {
  content: "\f0cc4";
}

.mdi-seat-passenger::before {
  content: "\f1249";
}

.mdi-seat-recline-extra::before {
  content: "\f0481";
}

.mdi-seat-recline-normal::before {
  content: "\f0482";
}

.mdi-seatbelt::before {
  content: "\f0cc5";
}

.mdi-security::before {
  content: "\f0483";
}

.mdi-security-network::before {
  content: "\f0484";
}

.mdi-seed::before {
  content: "\f0e62";
}

.mdi-seed-off::before {
  content: "\f13fd";
}

.mdi-seed-off-outline::before {
  content: "\f13fe";
}

.mdi-seed-outline::before {
  content: "\f0e63";
}

.mdi-seesaw::before {
  content: "\f15a4";
}

.mdi-segment::before {
  content: "\f0ecb";
}

.mdi-select::before {
  content: "\f0485";
}

.mdi-select-all::before {
  content: "\f0486";
}

.mdi-select-color::before {
  content: "\f0d31";
}

.mdi-select-compare::before {
  content: "\f0ad9";
}

.mdi-select-drag::before {
  content: "\f0a6c";
}

.mdi-select-group::before {
  content: "\f0f82";
}

.mdi-select-inverse::before {
  content: "\f0487";
}

.mdi-select-marker::before {
  content: "\f1280";
}

.mdi-select-multiple::before {
  content: "\f1281";
}

.mdi-select-multiple-marker::before {
  content: "\f1282";
}

.mdi-select-off::before {
  content: "\f0488";
}

.mdi-select-place::before {
  content: "\f0fda";
}

.mdi-select-search::before {
  content: "\f1204";
}

.mdi-selection::before {
  content: "\f0489";
}

.mdi-selection-drag::before {
  content: "\f0a6d";
}

.mdi-selection-ellipse::before {
  content: "\f0d32";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "\f0f22";
}

.mdi-selection-marker::before {
  content: "\f1283";
}

.mdi-selection-multiple::before {
  content: "\f1285";
}

.mdi-selection-multiple-marker::before {
  content: "\f1284";
}

.mdi-selection-off::before {
  content: "\f0777";
}

.mdi-selection-search::before {
  content: "\f1205";
}

.mdi-semantic-web::before {
  content: "\f1316";
}

.mdi-send::before {
  content: "\f048a";
}

.mdi-send-check::before {
  content: "\f1161";
}

.mdi-send-check-outline::before {
  content: "\f1162";
}

.mdi-send-circle::before {
  content: "\f0df8";
}

.mdi-send-circle-outline::before {
  content: "\f0df9";
}

.mdi-send-clock::before {
  content: "\f1163";
}

.mdi-send-clock-outline::before {
  content: "\f1164";
}

.mdi-send-lock::before {
  content: "\f07ed";
}

.mdi-send-lock-outline::before {
  content: "\f1166";
}

.mdi-send-outline::before {
  content: "\f1165";
}

.mdi-serial-port::before {
  content: "\f065c";
}

.mdi-server::before {
  content: "\f048b";
}

.mdi-server-minus::before {
  content: "\f048c";
}

.mdi-server-network::before {
  content: "\f048d";
}

.mdi-server-network-off::before {
  content: "\f048e";
}

.mdi-server-off::before {
  content: "\f048f";
}

.mdi-server-plus::before {
  content: "\f0490";
}

.mdi-server-remove::before {
  content: "\f0491";
}

.mdi-server-security::before {
  content: "\f0492";
}

.mdi-set-all::before {
  content: "\f0778";
}

.mdi-set-center::before {
  content: "\f0779";
}

.mdi-set-center-right::before {
  content: "\f077a";
}

.mdi-set-left::before {
  content: "\f077b";
}

.mdi-set-left-center::before {
  content: "\f077c";
}

.mdi-set-left-right::before {
  content: "\f077d";
}

.mdi-set-merge::before {
  content: "\f14e0";
}

.mdi-set-none::before {
  content: "\f077e";
}

.mdi-set-right::before {
  content: "\f077f";
}

.mdi-set-split::before {
  content: "\f14e1";
}

.mdi-set-square::before {
  content: "\f145d";
}

.mdi-set-top-box::before {
  content: "\f099f";
}

.mdi-settings-helper::before {
  content: "\f0a6e";
}

.mdi-shaker::before {
  content: "\f110e";
}

.mdi-shaker-outline::before {
  content: "\f110f";
}

.mdi-shape::before {
  content: "\f0831";
}

.mdi-shape-circle-plus::before {
  content: "\f065d";
}

.mdi-shape-outline::before {
  content: "\f0832";
}

.mdi-shape-oval-plus::before {
  content: "\f11fa";
}

.mdi-shape-plus::before {
  content: "\f0495";
}

.mdi-shape-polygon-plus::before {
  content: "\f065e";
}

.mdi-shape-rectangle-plus::before {
  content: "\f065f";
}

.mdi-shape-square-plus::before {
  content: "\f0660";
}

.mdi-shape-square-rounded-plus::before {
  content: "\f14fa";
}

.mdi-share::before {
  content: "\f0496";
}

.mdi-share-all::before {
  content: "\f11f4";
}

.mdi-share-all-outline::before {
  content: "\f11f5";
}

.mdi-share-circle::before {
  content: "\f11ad";
}

.mdi-share-off::before {
  content: "\f0f23";
}

.mdi-share-off-outline::before {
  content: "\f0f24";
}

.mdi-share-outline::before {
  content: "\f0932";
}

.mdi-share-variant::before {
  content: "\f0497";
}

.mdi-share-variant-outline::before {
  content: "\f1514";
}

.mdi-shark-fin::before {
  content: "\f1673";
}

.mdi-shark-fin-outline::before {
  content: "\f1674";
}

.mdi-sheep::before {
  content: "\f0cc6";
}

.mdi-shield::before {
  content: "\f0498";
}

.mdi-shield-account::before {
  content: "\f088f";
}

.mdi-shield-account-outline::before {
  content: "\f0a12";
}

.mdi-shield-account-variant::before {
  content: "\f15a7";
}

.mdi-shield-account-variant-outline::before {
  content: "\f15a8";
}

.mdi-shield-airplane::before {
  content: "\f06bb";
}

.mdi-shield-airplane-outline::before {
  content: "\f0cc7";
}

.mdi-shield-alert::before {
  content: "\f0ecc";
}

.mdi-shield-alert-outline::before {
  content: "\f0ecd";
}

.mdi-shield-bug::before {
  content: "\f13da";
}

.mdi-shield-bug-outline::before {
  content: "\f13db";
}

.mdi-shield-car::before {
  content: "\f0f83";
}

.mdi-shield-check::before {
  content: "\f0565";
}

.mdi-shield-check-outline::before {
  content: "\f0cc8";
}

.mdi-shield-cross::before {
  content: "\f0cc9";
}

.mdi-shield-cross-outline::before {
  content: "\f0cca";
}

.mdi-shield-edit::before {
  content: "\f11a0";
}

.mdi-shield-edit-outline::before {
  content: "\f11a1";
}

.mdi-shield-half::before {
  content: "\f1360";
}

.mdi-shield-half-full::before {
  content: "\f0780";
}

.mdi-shield-home::before {
  content: "\f068a";
}

.mdi-shield-home-outline::before {
  content: "\f0ccb";
}

.mdi-shield-key::before {
  content: "\f0bc4";
}

.mdi-shield-key-outline::before {
  content: "\f0bc5";
}

.mdi-shield-link-variant::before {
  content: "\f0d33";
}

.mdi-shield-link-variant-outline::before {
  content: "\f0d34";
}

.mdi-shield-lock::before {
  content: "\f099d";
}

.mdi-shield-lock-outline::before {
  content: "\f0ccc";
}

.mdi-shield-off::before {
  content: "\f099e";
}

.mdi-shield-off-outline::before {
  content: "\f099c";
}

.mdi-shield-outline::before {
  content: "\f0499";
}

.mdi-shield-plus::before {
  content: "\f0ada";
}

.mdi-shield-plus-outline::before {
  content: "\f0adb";
}

.mdi-shield-refresh::before {
  content: "\f00aa";
}

.mdi-shield-refresh-outline::before {
  content: "\f01e0";
}

.mdi-shield-remove::before {
  content: "\f0adc";
}

.mdi-shield-remove-outline::before {
  content: "\f0add";
}

.mdi-shield-search::before {
  content: "\f0d9a";
}

.mdi-shield-star::before {
  content: "\f113b";
}

.mdi-shield-star-outline::before {
  content: "\f113c";
}

.mdi-shield-sun::before {
  content: "\f105d";
}

.mdi-shield-sun-outline::before {
  content: "\f105e";
}

.mdi-shield-sync::before {
  content: "\f11a2";
}

.mdi-shield-sync-outline::before {
  content: "\f11a3";
}

.mdi-ship-wheel::before {
  content: "\f0833";
}

.mdi-shoe-ballet::before {
  content: "\f15ca";
}

.mdi-shoe-cleat::before {
  content: "\f15c7";
}

.mdi-shoe-formal::before {
  content: "\f0b47";
}

.mdi-shoe-heel::before {
  content: "\f0b48";
}

.mdi-shoe-print::before {
  content: "\f0dfa";
}

.mdi-shoe-sneaker::before {
  content: "\f15c8";
}

.mdi-shopping::before {
  content: "\f049a";
}

.mdi-shopping-music::before {
  content: "\f049b";
}

.mdi-shopping-outline::before {
  content: "\f11d5";
}

.mdi-shopping-search::before {
  content: "\f0f84";
}

.mdi-shore::before {
  content: "\f14f9";
}

.mdi-shovel::before {
  content: "\f0710";
}

.mdi-shovel-off::before {
  content: "\f0711";
}

.mdi-shower::before {
  content: "\f09a0";
}

.mdi-shower-head::before {
  content: "\f09a1";
}

.mdi-shredder::before {
  content: "\f049c";
}

.mdi-shuffle::before {
  content: "\f049d";
}

.mdi-shuffle-disabled::before {
  content: "\f049e";
}

.mdi-shuffle-variant::before {
  content: "\f049f";
}

.mdi-shuriken::before {
  content: "\f137f";
}

.mdi-sigma::before {
  content: "\f04a0";
}

.mdi-sigma-lower::before {
  content: "\f062b";
}

.mdi-sign-caution::before {
  content: "\f04a1";
}

.mdi-sign-direction::before {
  content: "\f0781";
}

.mdi-sign-direction-minus::before {
  content: "\f1000";
}

.mdi-sign-direction-plus::before {
  content: "\f0fdc";
}

.mdi-sign-direction-remove::before {
  content: "\f0fdd";
}

.mdi-sign-pole::before {
  content: "\f14f8";
}

.mdi-sign-real-estate::before {
  content: "\f1118";
}

.mdi-sign-text::before {
  content: "\f0782";
}

.mdi-signal::before {
  content: "\f04a2";
}

.mdi-signal-2g::before {
  content: "\f0712";
}

.mdi-signal-3g::before {
  content: "\f0713";
}

.mdi-signal-4g::before {
  content: "\f0714";
}

.mdi-signal-5g::before {
  content: "\f0a6f";
}

.mdi-signal-cellular-1::before {
  content: "\f08bc";
}

.mdi-signal-cellular-2::before {
  content: "\f08bd";
}

.mdi-signal-cellular-3::before {
  content: "\f08be";
}

.mdi-signal-cellular-outline::before {
  content: "\f08bf";
}

.mdi-signal-distance-variant::before {
  content: "\f0e64";
}

.mdi-signal-hspa::before {
  content: "\f0715";
}

.mdi-signal-hspa-plus::before {
  content: "\f0716";
}

.mdi-signal-off::before {
  content: "\f0783";
}

.mdi-signal-variant::before {
  content: "\f060a";
}

.mdi-signature::before {
  content: "\f0dfb";
}

.mdi-signature-freehand::before {
  content: "\f0dfc";
}

.mdi-signature-image::before {
  content: "\f0dfd";
}

.mdi-signature-text::before {
  content: "\f0dfe";
}

.mdi-silo::before {
  content: "\f0b49";
}

.mdi-silverware::before {
  content: "\f04a3";
}

.mdi-silverware-clean::before {
  content: "\f0fde";
}

.mdi-silverware-fork::before {
  content: "\f04a4";
}

.mdi-silverware-fork-knife::before {
  content: "\f0a70";
}

.mdi-silverware-spoon::before {
  content: "\f04a5";
}

.mdi-silverware-variant::before {
  content: "\f04a6";
}

.mdi-sim::before {
  content: "\f04a7";
}

.mdi-sim-alert::before {
  content: "\f04a8";
}

.mdi-sim-alert-outline::before {
  content: "\f15d3";
}

.mdi-sim-off::before {
  content: "\f04a9";
}

.mdi-sim-off-outline::before {
  content: "\f15d4";
}

.mdi-sim-outline::before {
  content: "\f15d5";
}

.mdi-simple-icons::before {
  content: "\f131d";
}

.mdi-sina-weibo::before {
  content: "\f0adf";
}

.mdi-sine-wave::before {
  content: "\f095b";
}

.mdi-sitemap::before {
  content: "\f04aa";
}

.mdi-size-l::before {
  content: "\f13a6";
}

.mdi-size-m::before {
  content: "\f13a5";
}

.mdi-size-s::before {
  content: "\f13a4";
}

.mdi-size-xl::before {
  content: "\f13a7";
}

.mdi-size-xs::before {
  content: "\f13a3";
}

.mdi-size-xxl::before {
  content: "\f13a8";
}

.mdi-size-xxs::before {
  content: "\f13a2";
}

.mdi-size-xxxl::before {
  content: "\f13a9";
}

.mdi-skate::before {
  content: "\f0d35";
}

.mdi-skateboard::before {
  content: "\f14c2";
}

.mdi-skew-less::before {
  content: "\f0d36";
}

.mdi-skew-more::before {
  content: "\f0d37";
}

.mdi-ski::before {
  content: "\f1304";
}

.mdi-ski-cross-country::before {
  content: "\f1305";
}

.mdi-ski-water::before {
  content: "\f1306";
}

.mdi-skip-backward::before {
  content: "\f04ab";
}

.mdi-skip-backward-outline::before {
  content: "\f0f25";
}

.mdi-skip-forward::before {
  content: "\f04ac";
}

.mdi-skip-forward-outline::before {
  content: "\f0f26";
}

.mdi-skip-next::before {
  content: "\f04ad";
}

.mdi-skip-next-circle::before {
  content: "\f0661";
}

.mdi-skip-next-circle-outline::before {
  content: "\f0662";
}

.mdi-skip-next-outline::before {
  content: "\f0f27";
}

.mdi-skip-previous::before {
  content: "\f04ae";
}

.mdi-skip-previous-circle::before {
  content: "\f0663";
}

.mdi-skip-previous-circle-outline::before {
  content: "\f0664";
}

.mdi-skip-previous-outline::before {
  content: "\f0f28";
}

.mdi-skull::before {
  content: "\f068c";
}

.mdi-skull-crossbones::before {
  content: "\f0bc6";
}

.mdi-skull-crossbones-outline::before {
  content: "\f0bc7";
}

.mdi-skull-outline::before {
  content: "\f0bc8";
}

.mdi-skull-scan::before {
  content: "\f14c7";
}

.mdi-skull-scan-outline::before {
  content: "\f14c8";
}

.mdi-skype::before {
  content: "\f04af";
}

.mdi-skype-business::before {
  content: "\f04b0";
}

.mdi-slack::before {
  content: "\f04b1";
}

.mdi-slash-forward::before {
  content: "\f0fdf";
}

.mdi-slash-forward-box::before {
  content: "\f0fe0";
}

.mdi-sleep::before {
  content: "\f04b2";
}

.mdi-sleep-off::before {
  content: "\f04b3";
}

.mdi-slide::before {
  content: "\f15a5";
}

.mdi-slope-downhill::before {
  content: "\f0dff";
}

.mdi-slope-uphill::before {
  content: "\f0e00";
}

.mdi-slot-machine::before {
  content: "\f1114";
}

.mdi-slot-machine-outline::before {
  content: "\f1115";
}

.mdi-smart-card::before {
  content: "\f10bd";
}

.mdi-smart-card-outline::before {
  content: "\f10be";
}

.mdi-smart-card-reader::before {
  content: "\f10bf";
}

.mdi-smart-card-reader-outline::before {
  content: "\f10c0";
}

.mdi-smog::before {
  content: "\f0a71";
}

.mdi-smoke-detector::before {
  content: "\f0392";
}

.mdi-smoking::before {
  content: "\f04b4";
}

.mdi-smoking-off::before {
  content: "\f04b5";
}

.mdi-smoking-pipe::before {
  content: "\f140d";
}

.mdi-smoking-pipe-off::before {
  content: "\f1428";
}

.mdi-snail::before {
  content: "\f1677";
}

.mdi-snake::before {
  content: "\f150e";
}

.mdi-snapchat::before {
  content: "\f04b6";
}

.mdi-snowboard::before {
  content: "\f1307";
}

.mdi-snowflake::before {
  content: "\f0717";
}

.mdi-snowflake-alert::before {
  content: "\f0f29";
}

.mdi-snowflake-melt::before {
  content: "\f12cb";
}

.mdi-snowflake-off::before {
  content: "\f14e3";
}

.mdi-snowflake-variant::before {
  content: "\f0f2a";
}

.mdi-snowman::before {
  content: "\f04b7";
}

.mdi-soccer::before {
  content: "\f04b8";
}

.mdi-soccer-field::before {
  content: "\f0834";
}

.mdi-social-distance-2-meters::before {
  content: "\f1579";
}

.mdi-social-distance-6-feet::before {
  content: "\f157a";
}

.mdi-sofa::before {
  content: "\f04b9";
}

.mdi-sofa-outline::before {
  content: "\f156d";
}

.mdi-sofa-single::before {
  content: "\f156e";
}

.mdi-sofa-single-outline::before {
  content: "\f156f";
}

.mdi-solar-panel::before {
  content: "\f0d9b";
}

.mdi-solar-panel-large::before {
  content: "\f0d9c";
}

.mdi-solar-power::before {
  content: "\f0a72";
}

.mdi-soldering-iron::before {
  content: "\f1092";
}

.mdi-solid::before {
  content: "\f068d";
}

.mdi-sony-playstation::before {
  content: "\f0414";
}

.mdi-sort::before {
  content: "\f04ba";
}

.mdi-sort-alphabetical-ascending::before {
  content: "\f05bd";
}

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\f1148";
}

.mdi-sort-alphabetical-descending::before {
  content: "\f05bf";
}

.mdi-sort-alphabetical-descending-variant::before {
  content: "\f1149";
}

.mdi-sort-alphabetical-variant::before {
  content: "\f04bb";
}

.mdi-sort-ascending::before {
  content: "\f04bc";
}

.mdi-sort-bool-ascending::before {
  content: "\f1385";
}

.mdi-sort-bool-ascending-variant::before {
  content: "\f1386";
}

.mdi-sort-bool-descending::before {
  content: "\f1387";
}

.mdi-sort-bool-descending-variant::before {
  content: "\f1388";
}

.mdi-sort-calendar-ascending::before {
  content: "\f1547";
}

.mdi-sort-calendar-descending::before {
  content: "\f1548";
}

.mdi-sort-clock-ascending::before {
  content: "\f1549";
}

.mdi-sort-clock-ascending-outline::before {
  content: "\f154a";
}

.mdi-sort-clock-descending::before {
  content: "\f154b";
}

.mdi-sort-clock-descending-outline::before {
  content: "\f154c";
}

.mdi-sort-descending::before {
  content: "\f04bd";
}

.mdi-sort-numeric-ascending::before {
  content: "\f1389";
}

.mdi-sort-numeric-ascending-variant::before {
  content: "\f090d";
}

.mdi-sort-numeric-descending::before {
  content: "\f138a";
}

.mdi-sort-numeric-descending-variant::before {
  content: "\f0ad2";
}

.mdi-sort-numeric-variant::before {
  content: "\f04be";
}

.mdi-sort-reverse-variant::before {
  content: "\f033c";
}

.mdi-sort-variant::before {
  content: "\f04bf";
}

.mdi-sort-variant-lock::before {
  content: "\f0ccd";
}

.mdi-sort-variant-lock-open::before {
  content: "\f0cce";
}

.mdi-sort-variant-remove::before {
  content: "\f1147";
}

.mdi-soundcloud::before {
  content: "\f04c0";
}

.mdi-source-branch::before {
  content: "\f062c";
}

.mdi-source-branch-check::before {
  content: "\f14cf";
}

.mdi-source-branch-minus::before {
  content: "\f14cb";
}

.mdi-source-branch-plus::before {
  content: "\f14ca";
}

.mdi-source-branch-refresh::before {
  content: "\f14cd";
}

.mdi-source-branch-remove::before {
  content: "\f14cc";
}

.mdi-source-branch-sync::before {
  content: "\f14ce";
}

.mdi-source-commit::before {
  content: "\f0718";
}

.mdi-source-commit-end::before {
  content: "\f0719";
}

.mdi-source-commit-end-local::before {
  content: "\f071a";
}

.mdi-source-commit-local::before {
  content: "\f071b";
}

.mdi-source-commit-next-local::before {
  content: "\f071c";
}

.mdi-source-commit-start::before {
  content: "\f071d";
}

.mdi-source-commit-start-next-local::before {
  content: "\f071e";
}

.mdi-source-fork::before {
  content: "\f04c1";
}

.mdi-source-merge::before {
  content: "\f062d";
}

.mdi-source-pull::before {
  content: "\f04c2";
}

.mdi-source-repository::before {
  content: "\f0ccf";
}

.mdi-source-repository-multiple::before {
  content: "\f0cd0";
}

.mdi-soy-sauce::before {
  content: "\f07ee";
}

.mdi-soy-sauce-off::before {
  content: "\f13fc";
}

.mdi-spa::before {
  content: "\f0cd1";
}

.mdi-spa-outline::before {
  content: "\f0cd2";
}

.mdi-space-invaders::before {
  content: "\f0bc9";
}

.mdi-space-station::before {
  content: "\f1383";
}

.mdi-spade::before {
  content: "\f0e65";
}

.mdi-sparkles::before {
  content: "\f1545";
}

.mdi-speaker::before {
  content: "\f04c3";
}

.mdi-speaker-bluetooth::before {
  content: "\f09a2";
}

.mdi-speaker-multiple::before {
  content: "\f0d38";
}

.mdi-speaker-off::before {
  content: "\f04c4";
}

.mdi-speaker-wireless::before {
  content: "\f071f";
}

.mdi-speedometer::before {
  content: "\f04c5";
}

.mdi-speedometer-medium::before {
  content: "\f0f85";
}

.mdi-speedometer-slow::before {
  content: "\f0f86";
}

.mdi-spellcheck::before {
  content: "\f04c6";
}

.mdi-spider::before {
  content: "\f11ea";
}

.mdi-spider-thread::before {
  content: "\f11eb";
}

.mdi-spider-web::before {
  content: "\f0bca";
}

.mdi-spirit-level::before {
  content: "\f14f1";
}

.mdi-spoon-sugar::before {
  content: "\f1429";
}

.mdi-spotify::before {
  content: "\f04c7";
}

.mdi-spotlight::before {
  content: "\f04c8";
}

.mdi-spotlight-beam::before {
  content: "\f04c9";
}

.mdi-spray::before {
  content: "\f0665";
}

.mdi-spray-bottle::before {
  content: "\f0ae0";
}

.mdi-sprinkler::before {
  content: "\f105f";
}

.mdi-sprinkler-variant::before {
  content: "\f1060";
}

.mdi-sprout::before {
  content: "\f0e66";
}

.mdi-sprout-outline::before {
  content: "\f0e67";
}

.mdi-square::before {
  content: "\f0764";
}

.mdi-square-circle::before {
  content: "\f1500";
}

.mdi-square-edit-outline::before {
  content: "\f090c";
}

.mdi-square-medium::before {
  content: "\f0a13";
}

.mdi-square-medium-outline::before {
  content: "\f0a14";
}

.mdi-square-off::before {
  content: "\f12ee";
}

.mdi-square-off-outline::before {
  content: "\f12ef";
}

.mdi-square-outline::before {
  content: "\f0763";
}

.mdi-square-root::before {
  content: "\f0784";
}

.mdi-square-root-box::before {
  content: "\f09a3";
}

.mdi-square-rounded::before {
  content: "\f14fb";
}

.mdi-square-rounded-outline::before {
  content: "\f14fc";
}

.mdi-square-small::before {
  content: "\f0a15";
}

.mdi-square-wave::before {
  content: "\f147b";
}

.mdi-squeegee::before {
  content: "\f0ae1";
}

.mdi-ssh::before {
  content: "\f08c0";
}

.mdi-stack-exchange::before {
  content: "\f060b";
}

.mdi-stack-overflow::before {
  content: "\f04cc";
}

.mdi-stackpath::before {
  content: "\f0359";
}

.mdi-stadium::before {
  content: "\f0ff9";
}

.mdi-stadium-variant::before {
  content: "\f0720";
}

.mdi-stairs::before {
  content: "\f04cd";
}

.mdi-stairs-box::before {
  content: "\f139e";
}

.mdi-stairs-down::before {
  content: "\f12be";
}

.mdi-stairs-up::before {
  content: "\f12bd";
}

.mdi-stamper::before {
  content: "\f0d39";
}

.mdi-standard-definition::before {
  content: "\f07ef";
}

.mdi-star::before {
  content: "\f04ce";
}

.mdi-star-box::before {
  content: "\f0a73";
}

.mdi-star-box-multiple::before {
  content: "\f1286";
}

.mdi-star-box-multiple-outline::before {
  content: "\f1287";
}

.mdi-star-box-outline::before {
  content: "\f0a74";
}

.mdi-star-check::before {
  content: "\f1566";
}

.mdi-star-check-outline::before {
  content: "\f156a";
}

.mdi-star-circle::before {
  content: "\f04cf";
}

.mdi-star-circle-outline::before {
  content: "\f09a4";
}

.mdi-star-cog::before {
  content: "\f1668";
}

.mdi-star-cog-outline::before {
  content: "\f1669";
}

.mdi-star-face::before {
  content: "\f09a5";
}

.mdi-star-four-points::before {
  content: "\f0ae2";
}

.mdi-star-four-points-outline::before {
  content: "\f0ae3";
}

.mdi-star-half::before {
  content: "\f0246";
}

.mdi-star-half-full::before {
  content: "\f04d0";
}

.mdi-star-minus::before {
  content: "\f1564";
}

.mdi-star-minus-outline::before {
  content: "\f1568";
}

.mdi-star-off::before {
  content: "\f04d1";
}

.mdi-star-off-outline::before {
  content: "\f155b";
}

.mdi-star-outline::before {
  content: "\f04d2";
}

.mdi-star-plus::before {
  content: "\f1563";
}

.mdi-star-plus-outline::before {
  content: "\f1567";
}

.mdi-star-remove::before {
  content: "\f1565";
}

.mdi-star-remove-outline::before {
  content: "\f1569";
}

.mdi-star-settings::before {
  content: "\f166a";
}

.mdi-star-settings-outline::before {
  content: "\f166b";
}

.mdi-star-three-points::before {
  content: "\f0ae4";
}

.mdi-star-three-points-outline::before {
  content: "\f0ae5";
}

.mdi-state-machine::before {
  content: "\f11ef";
}

.mdi-steam::before {
  content: "\f04d3";
}

.mdi-steering::before {
  content: "\f04d4";
}

.mdi-steering-off::before {
  content: "\f090e";
}

.mdi-step-backward::before {
  content: "\f04d5";
}

.mdi-step-backward-2::before {
  content: "\f04d6";
}

.mdi-step-forward::before {
  content: "\f04d7";
}

.mdi-step-forward-2::before {
  content: "\f04d8";
}

.mdi-stethoscope::before {
  content: "\f04d9";
}

.mdi-sticker::before {
  content: "\f1364";
}

.mdi-sticker-alert::before {
  content: "\f1365";
}

.mdi-sticker-alert-outline::before {
  content: "\f1366";
}

.mdi-sticker-check::before {
  content: "\f1367";
}

.mdi-sticker-check-outline::before {
  content: "\f1368";
}

.mdi-sticker-circle-outline::before {
  content: "\f05d0";
}

.mdi-sticker-emoji::before {
  content: "\f0785";
}

.mdi-sticker-minus::before {
  content: "\f1369";
}

.mdi-sticker-minus-outline::before {
  content: "\f136a";
}

.mdi-sticker-outline::before {
  content: "\f136b";
}

.mdi-sticker-plus::before {
  content: "\f136c";
}

.mdi-sticker-plus-outline::before {
  content: "\f136d";
}

.mdi-sticker-remove::before {
  content: "\f136e";
}

.mdi-sticker-remove-outline::before {
  content: "\f136f";
}

.mdi-stocking::before {
  content: "\f04da";
}

.mdi-stomach::before {
  content: "\f1093";
}

.mdi-stop::before {
  content: "\f04db";
}

.mdi-stop-circle::before {
  content: "\f0666";
}

.mdi-stop-circle-outline::before {
  content: "\f0667";
}

.mdi-store::before {
  content: "\f04dc";
}

.mdi-store-24-hour::before {
  content: "\f04dd";
}

.mdi-store-minus::before {
  content: "\f165e";
}

.mdi-store-outline::before {
  content: "\f1361";
}

.mdi-store-plus::before {
  content: "\f165f";
}

.mdi-store-remove::before {
  content: "\f1660";
}

.mdi-storefront::before {
  content: "\f07c7";
}

.mdi-storefront-outline::before {
  content: "\f10c1";
}

.mdi-stove::before {
  content: "\f04de";
}

.mdi-strategy::before {
  content: "\f11d6";
}

.mdi-stretch-to-page::before {
  content: "\f0f2b";
}

.mdi-stretch-to-page-outline::before {
  content: "\f0f2c";
}

.mdi-string-lights::before {
  content: "\f12ba";
}

.mdi-string-lights-off::before {
  content: "\f12bb";
}

.mdi-subdirectory-arrow-left::before {
  content: "\f060c";
}

.mdi-subdirectory-arrow-right::before {
  content: "\f060d";
}

.mdi-submarine::before {
  content: "\f156c";
}

.mdi-subtitles::before {
  content: "\f0a16";
}

.mdi-subtitles-outline::before {
  content: "\f0a17";
}

.mdi-subway::before {
  content: "\f06ac";
}

.mdi-subway-alert-variant::before {
  content: "\f0d9d";
}

.mdi-subway-variant::before {
  content: "\f04df";
}

.mdi-summit::before {
  content: "\f0786";
}

.mdi-sunglasses::before {
  content: "\f04e0";
}

.mdi-surround-sound::before {
  content: "\f05c5";
}

.mdi-surround-sound-2-0::before {
  content: "\f07f0";
}

.mdi-surround-sound-3-1::before {
  content: "\f07f1";
}

.mdi-surround-sound-5-1::before {
  content: "\f07f2";
}

.mdi-surround-sound-7-1::before {
  content: "\f07f3";
}

.mdi-svg::before {
  content: "\f0721";
}

.mdi-swap-horizontal::before {
  content: "\f04e1";
}

.mdi-swap-horizontal-bold::before {
  content: "\f0bcd";
}

.mdi-swap-horizontal-circle::before {
  content: "\f0fe1";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "\f0fe2";
}

.mdi-swap-horizontal-variant::before {
  content: "\f08c1";
}

.mdi-swap-vertical::before {
  content: "\f04e2";
}

.mdi-swap-vertical-bold::before {
  content: "\f0bce";
}

.mdi-swap-vertical-circle::before {
  content: "\f0fe3";
}

.mdi-swap-vertical-circle-outline::before {
  content: "\f0fe4";
}

.mdi-swap-vertical-variant::before {
  content: "\f08c2";
}

.mdi-swim::before {
  content: "\f04e3";
}

.mdi-switch::before {
  content: "\f04e4";
}

.mdi-sword::before {
  content: "\f04e5";
}

.mdi-sword-cross::before {
  content: "\f0787";
}

.mdi-syllabary-hangul::before {
  content: "\f1333";
}

.mdi-syllabary-hiragana::before {
  content: "\f1334";
}

.mdi-syllabary-katakana::before {
  content: "\f1335";
}

.mdi-syllabary-katakana-halfwidth::before {
  content: "\f1336";
}

.mdi-symbol::before {
  content: "\f1501";
}

.mdi-symfony::before {
  content: "\f0ae6";
}

.mdi-sync::before {
  content: "\f04e6";
}

.mdi-sync-alert::before {
  content: "\f04e7";
}

.mdi-sync-circle::before {
  content: "\f1378";
}

.mdi-sync-off::before {
  content: "\f04e8";
}

.mdi-tab::before {
  content: "\f04e9";
}

.mdi-tab-minus::before {
  content: "\f0b4b";
}

.mdi-tab-plus::before {
  content: "\f075c";
}

.mdi-tab-remove::before {
  content: "\f0b4c";
}

.mdi-tab-unselected::before {
  content: "\f04ea";
}

.mdi-table::before {
  content: "\f04eb";
}

.mdi-table-account::before {
  content: "\f13b9";
}

.mdi-table-alert::before {
  content: "\f13ba";
}

.mdi-table-arrow-down::before {
  content: "\f13bb";
}

.mdi-table-arrow-left::before {
  content: "\f13bc";
}

.mdi-table-arrow-right::before {
  content: "\f13bd";
}

.mdi-table-arrow-up::before {
  content: "\f13be";
}

.mdi-table-border::before {
  content: "\f0a18";
}

.mdi-table-cancel::before {
  content: "\f13bf";
}

.mdi-table-chair::before {
  content: "\f1061";
}

.mdi-table-check::before {
  content: "\f13c0";
}

.mdi-table-clock::before {
  content: "\f13c1";
}

.mdi-table-cog::before {
  content: "\f13c2";
}

.mdi-table-column::before {
  content: "\f0835";
}

.mdi-table-column-plus-after::before {
  content: "\f04ec";
}

.mdi-table-column-plus-before::before {
  content: "\f04ed";
}

.mdi-table-column-remove::before {
  content: "\f04ee";
}

.mdi-table-column-width::before {
  content: "\f04ef";
}

.mdi-table-edit::before {
  content: "\f04f0";
}

.mdi-table-eye::before {
  content: "\f1094";
}

.mdi-table-eye-off::before {
  content: "\f13c3";
}

.mdi-table-furniture::before {
  content: "\f05bc";
}

.mdi-table-headers-eye::before {
  content: "\f121d";
}

.mdi-table-headers-eye-off::before {
  content: "\f121e";
}

.mdi-table-heart::before {
  content: "\f13c4";
}

.mdi-table-key::before {
  content: "\f13c5";
}

.mdi-table-large::before {
  content: "\f04f1";
}

.mdi-table-large-plus::before {
  content: "\f0f87";
}

.mdi-table-large-remove::before {
  content: "\f0f88";
}

.mdi-table-lock::before {
  content: "\f13c6";
}

.mdi-table-merge-cells::before {
  content: "\f09a6";
}

.mdi-table-minus::before {
  content: "\f13c7";
}

.mdi-table-multiple::before {
  content: "\f13c8";
}

.mdi-table-network::before {
  content: "\f13c9";
}

.mdi-table-of-contents::before {
  content: "\f0836";
}

.mdi-table-off::before {
  content: "\f13ca";
}

.mdi-table-plus::before {
  content: "\f0a75";
}

.mdi-table-refresh::before {
  content: "\f13a0";
}

.mdi-table-remove::before {
  content: "\f0a76";
}

.mdi-table-row::before {
  content: "\f0837";
}

.mdi-table-row-height::before {
  content: "\f04f2";
}

.mdi-table-row-plus-after::before {
  content: "\f04f3";
}

.mdi-table-row-plus-before::before {
  content: "\f04f4";
}

.mdi-table-row-remove::before {
  content: "\f04f5";
}

.mdi-table-search::before {
  content: "\f090f";
}

.mdi-table-settings::before {
  content: "\f0838";
}

.mdi-table-split-cell::before {
  content: "\f142a";
}

.mdi-table-star::before {
  content: "\f13cb";
}

.mdi-table-sync::before {
  content: "\f13a1";
}

.mdi-table-tennis::before {
  content: "\f0e68";
}

.mdi-tablet::before {
  content: "\f04f6";
}

.mdi-tablet-android::before {
  content: "\f04f7";
}

.mdi-tablet-cellphone::before {
  content: "\f09a7";
}

.mdi-tablet-dashboard::before {
  content: "\f0ece";
}

.mdi-tablet-ipad::before {
  content: "\f04f8";
}

.mdi-taco::before {
  content: "\f0762";
}

.mdi-tag::before {
  content: "\f04f9";
}

.mdi-tag-faces::before {
  content: "\f04fa";
}

.mdi-tag-heart::before {
  content: "\f068b";
}

.mdi-tag-heart-outline::before {
  content: "\f0bcf";
}

.mdi-tag-minus::before {
  content: "\f0910";
}

.mdi-tag-minus-outline::before {
  content: "\f121f";
}

.mdi-tag-multiple::before {
  content: "\f04fb";
}

.mdi-tag-multiple-outline::before {
  content: "\f12f7";
}

.mdi-tag-off::before {
  content: "\f1220";
}

.mdi-tag-off-outline::before {
  content: "\f1221";
}

.mdi-tag-outline::before {
  content: "\f04fc";
}

.mdi-tag-plus::before {
  content: "\f0722";
}

.mdi-tag-plus-outline::before {
  content: "\f1222";
}

.mdi-tag-remove::before {
  content: "\f0723";
}

.mdi-tag-remove-outline::before {
  content: "\f1223";
}

.mdi-tag-text::before {
  content: "\f1224";
}

.mdi-tag-text-outline::before {
  content: "\f04fd";
}

.mdi-tailwind::before {
  content: "\f13ff";
}

.mdi-tank::before {
  content: "\f0d3a";
}

.mdi-tanker-truck::before {
  content: "\f0fe5";
}

.mdi-tape-drive::before {
  content: "\f16df";
}

.mdi-tape-measure::before {
  content: "\f0b4d";
}

.mdi-target::before {
  content: "\f04fe";
}

.mdi-target-account::before {
  content: "\f0bd0";
}

.mdi-target-variant::before {
  content: "\f0a77";
}

.mdi-taxi::before {
  content: "\f04ff";
}

.mdi-tea::before {
  content: "\f0d9e";
}

.mdi-tea-outline::before {
  content: "\f0d9f";
}

.mdi-teach::before {
  content: "\f0890";
}

.mdi-teamviewer::before {
  content: "\f0500";
}

.mdi-telegram::before {
  content: "\f0501";
}

.mdi-telescope::before {
  content: "\f0b4e";
}

.mdi-television::before {
  content: "\f0502";
}

.mdi-television-ambient-light::before {
  content: "\f1356";
}

.mdi-television-box::before {
  content: "\f0839";
}

.mdi-television-classic::before {
  content: "\f07f4";
}

.mdi-television-classic-off::before {
  content: "\f083a";
}

.mdi-television-clean::before {
  content: "\f1110";
}

.mdi-television-guide::before {
  content: "\f0503";
}

.mdi-television-off::before {
  content: "\f083b";
}

.mdi-television-pause::before {
  content: "\f0f89";
}

.mdi-television-play::before {
  content: "\f0ecf";
}

.mdi-television-stop::before {
  content: "\f0f8a";
}

.mdi-temperature-celsius::before {
  content: "\f0504";
}

.mdi-temperature-fahrenheit::before {
  content: "\f0505";
}

.mdi-temperature-kelvin::before {
  content: "\f0506";
}

.mdi-tennis::before {
  content: "\f0da0";
}

.mdi-tennis-ball::before {
  content: "\f0507";
}

.mdi-tent::before {
  content: "\f0508";
}

.mdi-terraform::before {
  content: "\f1062";
}

.mdi-terrain::before {
  content: "\f0509";
}

.mdi-test-tube::before {
  content: "\f0668";
}

.mdi-test-tube-empty::before {
  content: "\f0911";
}

.mdi-test-tube-off::before {
  content: "\f0912";
}

.mdi-text::before {
  content: "\f09a8";
}

.mdi-text-account::before {
  content: "\f1570";
}

.mdi-text-box::before {
  content: "\f021a";
}

.mdi-text-box-check::before {
  content: "\f0ea6";
}

.mdi-text-box-check-outline::before {
  content: "\f0ea7";
}

.mdi-text-box-minus::before {
  content: "\f0ea8";
}

.mdi-text-box-minus-outline::before {
  content: "\f0ea9";
}

.mdi-text-box-multiple::before {
  content: "\f0ab7";
}

.mdi-text-box-multiple-outline::before {
  content: "\f0ab8";
}

.mdi-text-box-outline::before {
  content: "\f09ed";
}

.mdi-text-box-plus::before {
  content: "\f0eaa";
}

.mdi-text-box-plus-outline::before {
  content: "\f0eab";
}

.mdi-text-box-remove::before {
  content: "\f0eac";
}

.mdi-text-box-remove-outline::before {
  content: "\f0ead";
}

.mdi-text-box-search::before {
  content: "\f0eae";
}

.mdi-text-box-search-outline::before {
  content: "\f0eaf";
}

.mdi-text-recognition::before {
  content: "\f113d";
}

.mdi-text-search::before {
  content: "\f13b8";
}

.mdi-text-shadow::before {
  content: "\f0669";
}

.mdi-text-short::before {
  content: "\f09a9";
}

.mdi-text-subject::before {
  content: "\f09aa";
}

.mdi-text-to-speech::before {
  content: "\f050a";
}

.mdi-text-to-speech-off::before {
  content: "\f050b";
}

.mdi-texture::before {
  content: "\f050c";
}

.mdi-texture-box::before {
  content: "\f0fe6";
}

.mdi-theater::before {
  content: "\f050d";
}

.mdi-theme-light-dark::before {
  content: "\f050e";
}

.mdi-thermometer::before {
  content: "\f050f";
}

.mdi-thermometer-alert::before {
  content: "\f0e01";
}

.mdi-thermometer-chevron-down::before {
  content: "\f0e02";
}

.mdi-thermometer-chevron-up::before {
  content: "\f0e03";
}

.mdi-thermometer-high::before {
  content: "\f10c2";
}

.mdi-thermometer-lines::before {
  content: "\f0510";
}

.mdi-thermometer-low::before {
  content: "\f10c3";
}

.mdi-thermometer-minus::before {
  content: "\f0e04";
}

.mdi-thermometer-off::before {
  content: "\f1531";
}

.mdi-thermometer-plus::before {
  content: "\f0e05";
}

.mdi-thermostat::before {
  content: "\f0393";
}

.mdi-thermostat-box::before {
  content: "\f0891";
}

.mdi-thought-bubble::before {
  content: "\f07f6";
}

.mdi-thought-bubble-outline::before {
  content: "\f07f7";
}

.mdi-thumb-down::before {
  content: "\f0511";
}

.mdi-thumb-down-outline::before {
  content: "\f0512";
}

.mdi-thumb-up::before {
  content: "\f0513";
}

.mdi-thumb-up-outline::before {
  content: "\f0514";
}

.mdi-thumbs-up-down::before {
  content: "\f0515";
}

.mdi-ticket::before {
  content: "\f0516";
}

.mdi-ticket-account::before {
  content: "\f0517";
}

.mdi-ticket-confirmation::before {
  content: "\f0518";
}

.mdi-ticket-confirmation-outline::before {
  content: "\f13aa";
}

.mdi-ticket-outline::before {
  content: "\f0913";
}

.mdi-ticket-percent::before {
  content: "\f0724";
}

.mdi-ticket-percent-outline::before {
  content: "\f142b";
}

.mdi-tie::before {
  content: "\f0519";
}

.mdi-tilde::before {
  content: "\f0725";
}

.mdi-timelapse::before {
  content: "\f051a";
}

.mdi-timeline::before {
  content: "\f0bd1";
}

.mdi-timeline-alert::before {
  content: "\f0f95";
}

.mdi-timeline-alert-outline::before {
  content: "\f0f98";
}

.mdi-timeline-check::before {
  content: "\f1532";
}

.mdi-timeline-check-outline::before {
  content: "\f1533";
}

.mdi-timeline-clock::before {
  content: "\f11fb";
}

.mdi-timeline-clock-outline::before {
  content: "\f11fc";
}

.mdi-timeline-help::before {
  content: "\f0f99";
}

.mdi-timeline-help-outline::before {
  content: "\f0f9a";
}

.mdi-timeline-minus::before {
  content: "\f1534";
}

.mdi-timeline-minus-outline::before {
  content: "\f1535";
}

.mdi-timeline-outline::before {
  content: "\f0bd2";
}

.mdi-timeline-plus::before {
  content: "\f0f96";
}

.mdi-timeline-plus-outline::before {
  content: "\f0f97";
}

.mdi-timeline-remove::before {
  content: "\f1536";
}

.mdi-timeline-remove-outline::before {
  content: "\f1537";
}

.mdi-timeline-text::before {
  content: "\f0bd3";
}

.mdi-timeline-text-outline::before {
  content: "\f0bd4";
}

.mdi-timer::before {
  content: "\f13ab";
}

.mdi-timer-10::before {
  content: "\f051c";
}

.mdi-timer-3::before {
  content: "\f051d";
}

.mdi-timer-off::before {
  content: "\f13ac";
}

.mdi-timer-off-outline::before {
  content: "\f051e";
}

.mdi-timer-outline::before {
  content: "\f051b";
}

.mdi-timer-sand::before {
  content: "\f051f";
}

.mdi-timer-sand-empty::before {
  content: "\f06ad";
}

.mdi-timer-sand-full::before {
  content: "\f078c";
}

.mdi-timetable::before {
  content: "\f0520";
}

.mdi-toaster::before {
  content: "\f1063";
}

.mdi-toaster-off::before {
  content: "\f11b7";
}

.mdi-toaster-oven::before {
  content: "\f0cd3";
}

.mdi-toggle-switch::before {
  content: "\f0521";
}

.mdi-toggle-switch-off::before {
  content: "\f0522";
}

.mdi-toggle-switch-off-outline::before {
  content: "\f0a19";
}

.mdi-toggle-switch-outline::before {
  content: "\f0a1a";
}

.mdi-toilet::before {
  content: "\f09ab";
}

.mdi-toolbox::before {
  content: "\f09ac";
}

.mdi-toolbox-outline::before {
  content: "\f09ad";
}

.mdi-tools::before {
  content: "\f1064";
}

.mdi-tooltip::before {
  content: "\f0523";
}

.mdi-tooltip-account::before {
  content: "\f000c";
}

.mdi-tooltip-check::before {
  content: "\f155c";
}

.mdi-tooltip-check-outline::before {
  content: "\f155d";
}

.mdi-tooltip-edit::before {
  content: "\f0524";
}

.mdi-tooltip-edit-outline::before {
  content: "\f12c5";
}

.mdi-tooltip-image::before {
  content: "\f0525";
}

.mdi-tooltip-image-outline::before {
  content: "\f0bd5";
}

.mdi-tooltip-minus::before {
  content: "\f155e";
}

.mdi-tooltip-minus-outline::before {
  content: "\f155f";
}

.mdi-tooltip-outline::before {
  content: "\f0526";
}

.mdi-tooltip-plus::before {
  content: "\f0bd6";
}

.mdi-tooltip-plus-outline::before {
  content: "\f0527";
}

.mdi-tooltip-remove::before {
  content: "\f1560";
}

.mdi-tooltip-remove-outline::before {
  content: "\f1561";
}

.mdi-tooltip-text::before {
  content: "\f0528";
}

.mdi-tooltip-text-outline::before {
  content: "\f0bd7";
}

.mdi-tooth::before {
  content: "\f08c3";
}

.mdi-tooth-outline::before {
  content: "\f0529";
}

.mdi-toothbrush::before {
  content: "\f1129";
}

.mdi-toothbrush-electric::before {
  content: "\f112c";
}

.mdi-toothbrush-paste::before {
  content: "\f112a";
}

.mdi-torch::before {
  content: "\f1606";
}

.mdi-tortoise::before {
  content: "\f0d3b";
}

.mdi-toslink::before {
  content: "\f12b8";
}

.mdi-tournament::before {
  content: "\f09ae";
}

.mdi-tow-truck::before {
  content: "\f083c";
}

.mdi-tower-beach::before {
  content: "\f0681";
}

.mdi-tower-fire::before {
  content: "\f0682";
}

.mdi-toy-brick::before {
  content: "\f1288";
}

.mdi-toy-brick-marker::before {
  content: "\f1289";
}

.mdi-toy-brick-marker-outline::before {
  content: "\f128a";
}

.mdi-toy-brick-minus::before {
  content: "\f128b";
}

.mdi-toy-brick-minus-outline::before {
  content: "\f128c";
}

.mdi-toy-brick-outline::before {
  content: "\f128d";
}

.mdi-toy-brick-plus::before {
  content: "\f128e";
}

.mdi-toy-brick-plus-outline::before {
  content: "\f128f";
}

.mdi-toy-brick-remove::before {
  content: "\f1290";
}

.mdi-toy-brick-remove-outline::before {
  content: "\f1291";
}

.mdi-toy-brick-search::before {
  content: "\f1292";
}

.mdi-toy-brick-search-outline::before {
  content: "\f1293";
}

.mdi-track-light::before {
  content: "\f0914";
}

.mdi-trackpad::before {
  content: "\f07f8";
}

.mdi-trackpad-lock::before {
  content: "\f0933";
}

.mdi-tractor::before {
  content: "\f0892";
}

.mdi-tractor-variant::before {
  content: "\f14c4";
}

.mdi-trademark::before {
  content: "\f0a78";
}

.mdi-traffic-cone::before {
  content: "\f137c";
}

.mdi-traffic-light::before {
  content: "\f052b";
}

.mdi-train::before {
  content: "\f052c";
}

.mdi-train-car::before {
  content: "\f0bd8";
}

.mdi-train-variant::before {
  content: "\f08c4";
}

.mdi-tram::before {
  content: "\f052d";
}

.mdi-tram-side::before {
  content: "\f0fe7";
}

.mdi-transcribe::before {
  content: "\f052e";
}

.mdi-transcribe-close::before {
  content: "\f052f";
}

.mdi-transfer::before {
  content: "\f1065";
}

.mdi-transfer-down::before {
  content: "\f0da1";
}

.mdi-transfer-left::before {
  content: "\f0da2";
}

.mdi-transfer-right::before {
  content: "\f0530";
}

.mdi-transfer-up::before {
  content: "\f0da3";
}

.mdi-transit-connection::before {
  content: "\f0d3c";
}

.mdi-transit-connection-horizontal::before {
  content: "\f1546";
}

.mdi-transit-connection-variant::before {
  content: "\f0d3d";
}

.mdi-transit-detour::before {
  content: "\f0f8b";
}

.mdi-transit-skip::before {
  content: "\f1515";
}

.mdi-transit-transfer::before {
  content: "\f06ae";
}

.mdi-transition::before {
  content: "\f0915";
}

.mdi-transition-masked::before {
  content: "\f0916";
}

.mdi-translate::before {
  content: "\f05ca";
}

.mdi-translate-off::before {
  content: "\f0e06";
}

.mdi-transmission-tower::before {
  content: "\f0d3e";
}

.mdi-trash-can::before {
  content: "\f0a79";
}

.mdi-trash-can-outline::before {
  content: "\f0a7a";
}

.mdi-tray::before {
  content: "\f1294";
}

.mdi-tray-alert::before {
  content: "\f1295";
}

.mdi-tray-full::before {
  content: "\f1296";
}

.mdi-tray-minus::before {
  content: "\f1297";
}

.mdi-tray-plus::before {
  content: "\f1298";
}

.mdi-tray-remove::before {
  content: "\f1299";
}

.mdi-treasure-chest::before {
  content: "\f0726";
}

.mdi-tree::before {
  content: "\f0531";
}

.mdi-tree-outline::before {
  content: "\f0e69";
}

.mdi-trello::before {
  content: "\f0532";
}

.mdi-trending-down::before {
  content: "\f0533";
}

.mdi-trending-neutral::before {
  content: "\f0534";
}

.mdi-trending-up::before {
  content: "\f0535";
}

.mdi-triangle::before {
  content: "\f0536";
}

.mdi-triangle-outline::before {
  content: "\f0537";
}

.mdi-triangle-wave::before {
  content: "\f147c";
}

.mdi-triforce::before {
  content: "\f0bd9";
}

.mdi-trophy::before {
  content: "\f0538";
}

.mdi-trophy-award::before {
  content: "\f0539";
}

.mdi-trophy-broken::before {
  content: "\f0da4";
}

.mdi-trophy-outline::before {
  content: "\f053a";
}

.mdi-trophy-variant::before {
  content: "\f053b";
}

.mdi-trophy-variant-outline::before {
  content: "\f053c";
}

.mdi-truck::before {
  content: "\f053d";
}

.mdi-truck-check::before {
  content: "\f0cd4";
}

.mdi-truck-check-outline::before {
  content: "\f129a";
}

.mdi-truck-delivery::before {
  content: "\f053e";
}

.mdi-truck-delivery-outline::before {
  content: "\f129b";
}

.mdi-truck-fast::before {
  content: "\f0788";
}

.mdi-truck-fast-outline::before {
  content: "\f129c";
}

.mdi-truck-outline::before {
  content: "\f129d";
}

.mdi-truck-trailer::before {
  content: "\f0727";
}

.mdi-trumpet::before {
  content: "\f1096";
}

.mdi-tshirt-crew::before {
  content: "\f0a7b";
}

.mdi-tshirt-crew-outline::before {
  content: "\f053f";
}

.mdi-tshirt-v::before {
  content: "\f0a7c";
}

.mdi-tshirt-v-outline::before {
  content: "\f0540";
}

.mdi-tumble-dryer::before {
  content: "\f0917";
}

.mdi-tumble-dryer-alert::before {
  content: "\f11ba";
}

.mdi-tumble-dryer-off::before {
  content: "\f11bb";
}

.mdi-tune::before {
  content: "\f062e";
}

.mdi-tune-variant::before {
  content: "\f1542";
}

.mdi-tune-vertical::before {
  content: "\f066a";
}

.mdi-tune-vertical-variant::before {
  content: "\f1543";
}

.mdi-turnstile::before {
  content: "\f0cd5";
}

.mdi-turnstile-outline::before {
  content: "\f0cd6";
}

.mdi-turtle::before {
  content: "\f0cd7";
}

.mdi-twitch::before {
  content: "\f0543";
}

.mdi-twitter::before {
  content: "\f0544";
}

.mdi-twitter-retweet::before {
  content: "\f0547";
}

.mdi-two-factor-authentication::before {
  content: "\f09af";
}

.mdi-typewriter::before {
  content: "\f0f2d";
}

.mdi-ubisoft::before {
  content: "\f0bda";
}

.mdi-ubuntu::before {
  content: "\f0548";
}

.mdi-ufo::before {
  content: "\f10c4";
}

.mdi-ufo-outline::before {
  content: "\f10c5";
}

.mdi-ultra-high-definition::before {
  content: "\f07f9";
}

.mdi-umbraco::before {
  content: "\f0549";
}

.mdi-umbrella::before {
  content: "\f054a";
}

.mdi-umbrella-closed::before {
  content: "\f09b0";
}

.mdi-umbrella-closed-outline::before {
  content: "\f13e2";
}

.mdi-umbrella-closed-variant::before {
  content: "\f13e1";
}

.mdi-umbrella-outline::before {
  content: "\f054b";
}

.mdi-undo::before {
  content: "\f054c";
}

.mdi-undo-variant::before {
  content: "\f054d";
}

.mdi-unfold-less-horizontal::before {
  content: "\f054e";
}

.mdi-unfold-less-vertical::before {
  content: "\f0760";
}

.mdi-unfold-more-horizontal::before {
  content: "\f054f";
}

.mdi-unfold-more-vertical::before {
  content: "\f0761";
}

.mdi-ungroup::before {
  content: "\f0550";
}

.mdi-unicode::before {
  content: "\f0ed0";
}

.mdi-unicorn::before {
  content: "\f15c2";
}

.mdi-unicorn-variant::before {
  content: "\f15c3";
}

.mdi-unicycle::before {
  content: "\f15e5";
}

.mdi-unity::before {
  content: "\f06af";
}

.mdi-unreal::before {
  content: "\f09b1";
}

.mdi-untappd::before {
  content: "\f0551";
}

.mdi-update::before {
  content: "\f06b0";
}

.mdi-upload::before {
  content: "\f0552";
}

.mdi-upload-lock::before {
  content: "\f1373";
}

.mdi-upload-lock-outline::before {
  content: "\f1374";
}

.mdi-upload-multiple::before {
  content: "\f083d";
}

.mdi-upload-network::before {
  content: "\f06f6";
}

.mdi-upload-network-outline::before {
  content: "\f0cd8";
}

.mdi-upload-off::before {
  content: "\f10c6";
}

.mdi-upload-off-outline::before {
  content: "\f10c7";
}

.mdi-upload-outline::before {
  content: "\f0e07";
}

.mdi-usb::before {
  content: "\f0553";
}

.mdi-usb-flash-drive::before {
  content: "\f129e";
}

.mdi-usb-flash-drive-outline::before {
  content: "\f129f";
}

.mdi-usb-port::before {
  content: "\f11f0";
}

.mdi-valve::before {
  content: "\f1066";
}

.mdi-valve-closed::before {
  content: "\f1067";
}

.mdi-valve-open::before {
  content: "\f1068";
}

.mdi-van-passenger::before {
  content: "\f07fa";
}

.mdi-van-utility::before {
  content: "\f07fb";
}

.mdi-vanish::before {
  content: "\f07fc";
}

.mdi-vanish-quarter::before {
  content: "\f1554";
}

.mdi-vanity-light::before {
  content: "\f11e1";
}

.mdi-variable::before {
  content: "\f0ae7";
}

.mdi-variable-box::before {
  content: "\f1111";
}

.mdi-vector-arrange-above::before {
  content: "\f0554";
}

.mdi-vector-arrange-below::before {
  content: "\f0555";
}

.mdi-vector-bezier::before {
  content: "\f0ae8";
}

.mdi-vector-circle::before {
  content: "\f0556";
}

.mdi-vector-circle-variant::before {
  content: "\f0557";
}

.mdi-vector-combine::before {
  content: "\f0558";
}

.mdi-vector-curve::before {
  content: "\f0559";
}

.mdi-vector-difference::before {
  content: "\f055a";
}

.mdi-vector-difference-ab::before {
  content: "\f055b";
}

.mdi-vector-difference-ba::before {
  content: "\f055c";
}

.mdi-vector-ellipse::before {
  content: "\f0893";
}

.mdi-vector-intersection::before {
  content: "\f055d";
}

.mdi-vector-line::before {
  content: "\f055e";
}

.mdi-vector-link::before {
  content: "\f0fe8";
}

.mdi-vector-point::before {
  content: "\f055f";
}

.mdi-vector-polygon::before {
  content: "\f0560";
}

.mdi-vector-polyline::before {
  content: "\f0561";
}

.mdi-vector-polyline-edit::before {
  content: "\f1225";
}

.mdi-vector-polyline-minus::before {
  content: "\f1226";
}

.mdi-vector-polyline-plus::before {
  content: "\f1227";
}

.mdi-vector-polyline-remove::before {
  content: "\f1228";
}

.mdi-vector-radius::before {
  content: "\f074a";
}

.mdi-vector-rectangle::before {
  content: "\f05c6";
}

.mdi-vector-selection::before {
  content: "\f0562";
}

.mdi-vector-square::before {
  content: "\f0001";
}

.mdi-vector-triangle::before {
  content: "\f0563";
}

.mdi-vector-union::before {
  content: "\f0564";
}

.mdi-vhs::before {
  content: "\f0a1b";
}

.mdi-vibrate::before {
  content: "\f0566";
}

.mdi-vibrate-off::before {
  content: "\f0cd9";
}

.mdi-video::before {
  content: "\f0567";
}

.mdi-video-3d::before {
  content: "\f07fd";
}

.mdi-video-3d-off::before {
  content: "\f13d9";
}

.mdi-video-3d-variant::before {
  content: "\f0ed1";
}

.mdi-video-4k-box::before {
  content: "\f083e";
}

.mdi-video-account::before {
  content: "\f0919";
}

.mdi-video-box::before {
  content: "\f00fd";
}

.mdi-video-box-off::before {
  content: "\f00fe";
}

.mdi-video-check::before {
  content: "\f1069";
}

.mdi-video-check-outline::before {
  content: "\f106a";
}

.mdi-video-high-definition::before {
  content: "\f152e";
}

.mdi-video-image::before {
  content: "\f091a";
}

.mdi-video-input-antenna::before {
  content: "\f083f";
}

.mdi-video-input-component::before {
  content: "\f0840";
}

.mdi-video-input-hdmi::before {
  content: "\f0841";
}

.mdi-video-input-scart::before {
  content: "\f0f8c";
}

.mdi-video-input-svideo::before {
  content: "\f0842";
}

.mdi-video-minus::before {
  content: "\f09b2";
}

.mdi-video-minus-outline::before {
  content: "\f02ba";
}

.mdi-video-off::before {
  content: "\f0568";
}

.mdi-video-off-outline::before {
  content: "\f0bdb";
}

.mdi-video-outline::before {
  content: "\f0bdc";
}

.mdi-video-plus::before {
  content: "\f09b3";
}

.mdi-video-plus-outline::before {
  content: "\f01d3";
}

.mdi-video-stabilization::before {
  content: "\f091b";
}

.mdi-video-switch::before {
  content: "\f0569";
}

.mdi-video-switch-outline::before {
  content: "\f0790";
}

.mdi-video-vintage::before {
  content: "\f0a1c";
}

.mdi-video-wireless::before {
  content: "\f0ed2";
}

.mdi-video-wireless-outline::before {
  content: "\f0ed3";
}

.mdi-view-agenda::before {
  content: "\f056a";
}

.mdi-view-agenda-outline::before {
  content: "\f11d8";
}

.mdi-view-array::before {
  content: "\f056b";
}

.mdi-view-array-outline::before {
  content: "\f1485";
}

.mdi-view-carousel::before {
  content: "\f056c";
}

.mdi-view-carousel-outline::before {
  content: "\f1486";
}

.mdi-view-column::before {
  content: "\f056d";
}

.mdi-view-column-outline::before {
  content: "\f1487";
}

.mdi-view-comfy::before {
  content: "\f0e6a";
}

.mdi-view-comfy-outline::before {
  content: "\f1488";
}

.mdi-view-compact::before {
  content: "\f0e6b";
}

.mdi-view-compact-outline::before {
  content: "\f0e6c";
}

.mdi-view-dashboard::before {
  content: "\f056e";
}

.mdi-view-dashboard-outline::before {
  content: "\f0a1d";
}

.mdi-view-dashboard-variant::before {
  content: "\f0843";
}

.mdi-view-dashboard-variant-outline::before {
  content: "\f1489";
}

.mdi-view-day::before {
  content: "\f056f";
}

.mdi-view-day-outline::before {
  content: "\f148a";
}

.mdi-view-grid::before {
  content: "\f0570";
}

.mdi-view-grid-outline::before {
  content: "\f11d9";
}

.mdi-view-grid-plus::before {
  content: "\f0f8d";
}

.mdi-view-grid-plus-outline::before {
  content: "\f11da";
}

.mdi-view-headline::before {
  content: "\f0571";
}

.mdi-view-list::before {
  content: "\f0572";
}

.mdi-view-list-outline::before {
  content: "\f148b";
}

.mdi-view-module::before {
  content: "\f0573";
}

.mdi-view-module-outline::before {
  content: "\f148c";
}

.mdi-view-parallel::before {
  content: "\f0728";
}

.mdi-view-parallel-outline::before {
  content: "\f148d";
}

.mdi-view-quilt::before {
  content: "\f0574";
}

.mdi-view-quilt-outline::before {
  content: "\f148e";
}

.mdi-view-sequential::before {
  content: "\f0729";
}

.mdi-view-sequential-outline::before {
  content: "\f148f";
}

.mdi-view-split-horizontal::before {
  content: "\f0bcb";
}

.mdi-view-split-vertical::before {
  content: "\f0bcc";
}

.mdi-view-stream::before {
  content: "\f0575";
}

.mdi-view-stream-outline::before {
  content: "\f1490";
}

.mdi-view-week::before {
  content: "\f0576";
}

.mdi-view-week-outline::before {
  content: "\f1491";
}

.mdi-vimeo::before {
  content: "\f0577";
}

.mdi-violin::before {
  content: "\f060f";
}

.mdi-virtual-reality::before {
  content: "\f0894";
}

.mdi-virus::before {
  content: "\f13b6";
}

.mdi-virus-outline::before {
  content: "\f13b7";
}

.mdi-vk::before {
  content: "\f0579";
}

.mdi-vlc::before {
  content: "\f057c";
}

.mdi-voice-off::before {
  content: "\f0ed4";
}

.mdi-voicemail::before {
  content: "\f057d";
}

.mdi-volleyball::before {
  content: "\f09b4";
}

.mdi-volume-high::before {
  content: "\f057e";
}

.mdi-volume-low::before {
  content: "\f057f";
}

.mdi-volume-medium::before {
  content: "\f0580";
}

.mdi-volume-minus::before {
  content: "\f075e";
}

.mdi-volume-mute::before {
  content: "\f075f";
}

.mdi-volume-off::before {
  content: "\f0581";
}

.mdi-volume-plus::before {
  content: "\f075d";
}

.mdi-volume-source::before {
  content: "\f1120";
}

.mdi-volume-variant-off::before {
  content: "\f0e08";
}

.mdi-volume-vibrate::before {
  content: "\f1121";
}

.mdi-vote::before {
  content: "\f0a1f";
}

.mdi-vote-outline::before {
  content: "\f0a20";
}

.mdi-vpn::before {
  content: "\f0582";
}

.mdi-vuejs::before {
  content: "\f0844";
}

.mdi-vuetify::before {
  content: "\f0e6d";
}

.mdi-walk::before {
  content: "\f0583";
}

.mdi-wall::before {
  content: "\f07fe";
}

.mdi-wall-sconce::before {
  content: "\f091c";
}

.mdi-wall-sconce-flat::before {
  content: "\f091d";
}

.mdi-wall-sconce-flat-variant::before {
  content: "\f041c";
}

.mdi-wall-sconce-round::before {
  content: "\f0748";
}

.mdi-wall-sconce-round-variant::before {
  content: "\f091e";
}

.mdi-wallet::before {
  content: "\f0584";
}

.mdi-wallet-giftcard::before {
  content: "\f0585";
}

.mdi-wallet-membership::before {
  content: "\f0586";
}

.mdi-wallet-outline::before {
  content: "\f0bdd";
}

.mdi-wallet-plus::before {
  content: "\f0f8e";
}

.mdi-wallet-plus-outline::before {
  content: "\f0f8f";
}

.mdi-wallet-travel::before {
  content: "\f0587";
}

.mdi-wallpaper::before {
  content: "\f0e09";
}

.mdi-wan::before {
  content: "\f0588";
}

.mdi-wardrobe::before {
  content: "\f0f90";
}

.mdi-wardrobe-outline::before {
  content: "\f0f91";
}

.mdi-warehouse::before {
  content: "\f0f81";
}

.mdi-washing-machine::before {
  content: "\f072a";
}

.mdi-washing-machine-alert::before {
  content: "\f11bc";
}

.mdi-washing-machine-off::before {
  content: "\f11bd";
}

.mdi-watch::before {
  content: "\f0589";
}

.mdi-watch-export::before {
  content: "\f058a";
}

.mdi-watch-export-variant::before {
  content: "\f0895";
}

.mdi-watch-import::before {
  content: "\f058b";
}

.mdi-watch-import-variant::before {
  content: "\f0896";
}

.mdi-watch-variant::before {
  content: "\f0897";
}

.mdi-watch-vibrate::before {
  content: "\f06b1";
}

.mdi-watch-vibrate-off::before {
  content: "\f0cda";
}

.mdi-water::before {
  content: "\f058c";
}

.mdi-water-alert::before {
  content: "\f1502";
}

.mdi-water-alert-outline::before {
  content: "\f1503";
}

.mdi-water-boiler::before {
  content: "\f0f92";
}

.mdi-water-boiler-alert::before {
  content: "\f11b3";
}

.mdi-water-boiler-off::before {
  content: "\f11b4";
}

.mdi-water-check::before {
  content: "\f1504";
}

.mdi-water-check-outline::before {
  content: "\f1505";
}

.mdi-water-minus::before {
  content: "\f1506";
}

.mdi-water-minus-outline::before {
  content: "\f1507";
}

.mdi-water-off::before {
  content: "\f058d";
}

.mdi-water-off-outline::before {
  content: "\f1508";
}

.mdi-water-outline::before {
  content: "\f0e0a";
}

.mdi-water-percent::before {
  content: "\f058e";
}

.mdi-water-percent-alert::before {
  content: "\f1509";
}

.mdi-water-plus::before {
  content: "\f150a";
}

.mdi-water-plus-outline::before {
  content: "\f150b";
}

.mdi-water-polo::before {
  content: "\f12a0";
}

.mdi-water-pump::before {
  content: "\f058f";
}

.mdi-water-pump-off::before {
  content: "\f0f93";
}

.mdi-water-remove::before {
  content: "\f150c";
}

.mdi-water-remove-outline::before {
  content: "\f150d";
}

.mdi-water-well::before {
  content: "\f106b";
}

.mdi-water-well-outline::before {
  content: "\f106c";
}

.mdi-watering-can::before {
  content: "\f1481";
}

.mdi-watering-can-outline::before {
  content: "\f1482";
}

.mdi-watermark::before {
  content: "\f0612";
}

.mdi-wave::before {
  content: "\f0f2e";
}

.mdi-waveform::before {
  content: "\f147d";
}

.mdi-waves::before {
  content: "\f078d";
}

.mdi-waze::before {
  content: "\f0bde";
}

.mdi-weather-cloudy::before {
  content: "\f0590";
}

.mdi-weather-cloudy-alert::before {
  content: "\f0f2f";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "\f0e6e";
}

.mdi-weather-fog::before {
  content: "\f0591";
}

.mdi-weather-hail::before {
  content: "\f0592";
}

.mdi-weather-hazy::before {
  content: "\f0f30";
}

.mdi-weather-hurricane::before {
  content: "\f0898";
}

.mdi-weather-lightning::before {
  content: "\f0593";
}

.mdi-weather-lightning-rainy::before {
  content: "\f067e";
}

.mdi-weather-night::before {
  content: "\f0594";
}

.mdi-weather-night-partly-cloudy::before {
  content: "\f0f31";
}

.mdi-weather-partly-cloudy::before {
  content: "\f0595";
}

.mdi-weather-partly-lightning::before {
  content: "\f0f32";
}

.mdi-weather-partly-rainy::before {
  content: "\f0f33";
}

.mdi-weather-partly-snowy::before {
  content: "\f0f34";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "\f0f35";
}

.mdi-weather-pouring::before {
  content: "\f0596";
}

.mdi-weather-rainy::before {
  content: "\f0597";
}

.mdi-weather-snowy::before {
  content: "\f0598";
}

.mdi-weather-snowy-heavy::before {
  content: "\f0f36";
}

.mdi-weather-snowy-rainy::before {
  content: "\f067f";
}

.mdi-weather-sunny::before {
  content: "\f0599";
}

.mdi-weather-sunny-alert::before {
  content: "\f0f37";
}

.mdi-weather-sunny-off::before {
  content: "\f14e4";
}

.mdi-weather-sunset::before {
  content: "\f059a";
}

.mdi-weather-sunset-down::before {
  content: "\f059b";
}

.mdi-weather-sunset-up::before {
  content: "\f059c";
}

.mdi-weather-tornado::before {
  content: "\f0f38";
}

.mdi-weather-windy::before {
  content: "\f059d";
}

.mdi-weather-windy-variant::before {
  content: "\f059e";
}

.mdi-web::before {
  content: "\f059f";
}

.mdi-web-box::before {
  content: "\f0f94";
}

.mdi-web-clock::before {
  content: "\f124a";
}

.mdi-webcam::before {
  content: "\f05a0";
}

.mdi-webhook::before {
  content: "\f062f";
}

.mdi-webpack::before {
  content: "\f072b";
}

.mdi-webrtc::before {
  content: "\f1248";
}

.mdi-wechat::before {
  content: "\f0611";
}

.mdi-weight::before {
  content: "\f05a1";
}

.mdi-weight-gram::before {
  content: "\f0d3f";
}

.mdi-weight-kilogram::before {
  content: "\f05a2";
}

.mdi-weight-lifter::before {
  content: "\f115d";
}

.mdi-weight-pound::before {
  content: "\f09b5";
}

.mdi-whatsapp::before {
  content: "\f05a3";
}

.mdi-wheel-barrow::before {
  content: "\f14f2";
}

.mdi-wheelchair-accessibility::before {
  content: "\f05a4";
}

.mdi-whistle::before {
  content: "\f09b6";
}

.mdi-whistle-outline::before {
  content: "\f12bc";
}

.mdi-white-balance-auto::before {
  content: "\f05a5";
}

.mdi-white-balance-incandescent::before {
  content: "\f05a6";
}

.mdi-white-balance-iridescent::before {
  content: "\f05a7";
}

.mdi-white-balance-sunny::before {
  content: "\f05a8";
}

.mdi-widgets::before {
  content: "\f072c";
}

.mdi-widgets-outline::before {
  content: "\f1355";
}

.mdi-wifi::before {
  content: "\f05a9";
}

.mdi-wifi-alert::before {
  content: "\f16b5";
}

.mdi-wifi-arrow-down::before {
  content: "\f16b6";
}

.mdi-wifi-arrow-left::before {
  content: "\f16b7";
}

.mdi-wifi-arrow-left-right::before {
  content: "\f16b8";
}

.mdi-wifi-arrow-right::before {
  content: "\f16b9";
}

.mdi-wifi-arrow-up::before {
  content: "\f16ba";
}

.mdi-wifi-arrow-up-down::before {
  content: "\f16bb";
}

.mdi-wifi-cancel::before {
  content: "\f16bc";
}

.mdi-wifi-check::before {
  content: "\f16bd";
}

.mdi-wifi-cog::before {
  content: "\f16be";
}

.mdi-wifi-lock::before {
  content: "\f16bf";
}

.mdi-wifi-lock-open::before {
  content: "\f16c0";
}

.mdi-wifi-marker::before {
  content: "\f16c1";
}

.mdi-wifi-minus::before {
  content: "\f16c2";
}

.mdi-wifi-off::before {
  content: "\f05aa";
}

.mdi-wifi-plus::before {
  content: "\f16c3";
}

.mdi-wifi-refresh::before {
  content: "\f16c4";
}

.mdi-wifi-remove::before {
  content: "\f16c5";
}

.mdi-wifi-settings::before {
  content: "\f16c6";
}

.mdi-wifi-star::before {
  content: "\f0e0b";
}

.mdi-wifi-strength-1::before {
  content: "\f091f";
}

.mdi-wifi-strength-1-alert::before {
  content: "\f0920";
}

.mdi-wifi-strength-1-lock::before {
  content: "\f0921";
}

.mdi-wifi-strength-1-lock-open::before {
  content: "\f16cb";
}

.mdi-wifi-strength-2::before {
  content: "\f0922";
}

.mdi-wifi-strength-2-alert::before {
  content: "\f0923";
}

.mdi-wifi-strength-2-lock::before {
  content: "\f0924";
}

.mdi-wifi-strength-2-lock-open::before {
  content: "\f16cc";
}

.mdi-wifi-strength-3::before {
  content: "\f0925";
}

.mdi-wifi-strength-3-alert::before {
  content: "\f0926";
}

.mdi-wifi-strength-3-lock::before {
  content: "\f0927";
}

.mdi-wifi-strength-3-lock-open::before {
  content: "\f16cd";
}

.mdi-wifi-strength-4::before {
  content: "\f0928";
}

.mdi-wifi-strength-4-alert::before {
  content: "\f0929";
}

.mdi-wifi-strength-4-lock::before {
  content: "\f092a";
}

.mdi-wifi-strength-4-lock-open::before {
  content: "\f16ce";
}

.mdi-wifi-strength-alert-outline::before {
  content: "\f092b";
}

.mdi-wifi-strength-lock-open-outline::before {
  content: "\f16cf";
}

.mdi-wifi-strength-lock-outline::before {
  content: "\f092c";
}

.mdi-wifi-strength-off::before {
  content: "\f092d";
}

.mdi-wifi-strength-off-outline::before {
  content: "\f092e";
}

.mdi-wifi-strength-outline::before {
  content: "\f092f";
}

.mdi-wifi-sync::before {
  content: "\f16c7";
}

.mdi-wikipedia::before {
  content: "\f05ac";
}

.mdi-wind-turbine::before {
  content: "\f0da5";
}

.mdi-window-close::before {
  content: "\f05ad";
}

.mdi-window-closed::before {
  content: "\f05ae";
}

.mdi-window-closed-variant::before {
  content: "\f11db";
}

.mdi-window-maximize::before {
  content: "\f05af";
}

.mdi-window-minimize::before {
  content: "\f05b0";
}

.mdi-window-open::before {
  content: "\f05b1";
}

.mdi-window-open-variant::before {
  content: "\f11dc";
}

.mdi-window-restore::before {
  content: "\f05b2";
}

.mdi-window-shutter::before {
  content: "\f111c";
}

.mdi-window-shutter-alert::before {
  content: "\f111d";
}

.mdi-window-shutter-open::before {
  content: "\f111e";
}

.mdi-windsock::before {
  content: "\f15fa";
}

.mdi-wiper::before {
  content: "\f0ae9";
}

.mdi-wiper-wash::before {
  content: "\f0da6";
}

.mdi-wizard-hat::before {
  content: "\f1477";
}

.mdi-wordpress::before {
  content: "\f05b4";
}

.mdi-wrap::before {
  content: "\f05b6";
}

.mdi-wrap-disabled::before {
  content: "\f0bdf";
}

.mdi-wrench::before {
  content: "\f05b7";
}

.mdi-wrench-outline::before {
  content: "\f0be0";
}

.mdi-xamarin::before {
  content: "\f0845";
}

.mdi-xamarin-outline::before {
  content: "\f0846";
}

.mdi-xing::before {
  content: "\f05be";
}

.mdi-xml::before {
  content: "\f05c0";
}

.mdi-xmpp::before {
  content: "\f07ff";
}

.mdi-y-combinator::before {
  content: "\f0624";
}

.mdi-yahoo::before {
  content: "\f0b4f";
}

.mdi-yeast::before {
  content: "\f05c1";
}

.mdi-yin-yang::before {
  content: "\f0680";
}

.mdi-yoga::before {
  content: "\f117c";
}

.mdi-youtube::before {
  content: "\f05c3";
}

.mdi-youtube-gaming::before {
  content: "\f0848";
}

.mdi-youtube-studio::before {
  content: "\f0847";
}

.mdi-youtube-subscription::before {
  content: "\f0d40";
}

.mdi-youtube-tv::before {
  content: "\f0448";
}

.mdi-yurt::before {
  content: "\f1516";
}

.mdi-z-wave::before {
  content: "\f0aea";
}

.mdi-zend::before {
  content: "\f0aeb";
}

.mdi-zigbee::before {
  content: "\f0d41";
}

.mdi-zip-box::before {
  content: "\f05c4";
}

.mdi-zip-box-outline::before {
  content: "\f0ffa";
}

.mdi-zip-disk::before {
  content: "\f0a23";
}

.mdi-zodiac-aquarius::before {
  content: "\f0a7d";
}

.mdi-zodiac-aries::before {
  content: "\f0a7e";
}

.mdi-zodiac-cancer::before {
  content: "\f0a7f";
}

.mdi-zodiac-capricorn::before {
  content: "\f0a80";
}

.mdi-zodiac-gemini::before {
  content: "\f0a81";
}

.mdi-zodiac-leo::before {
  content: "\f0a82";
}

.mdi-zodiac-libra::before {
  content: "\f0a83";
}

.mdi-zodiac-pisces::before {
  content: "\f0a84";
}

.mdi-zodiac-sagittarius::before {
  content: "\f0a85";
}

.mdi-zodiac-scorpio::before {
  content: "\f0a86";
}

.mdi-zodiac-taurus::before {
  content: "\f0a87";
}

.mdi-zodiac-virgo::before {
  content: "\f0a88";
}

.mdi-blank::before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*# sourceMappingURL=materialdesignicons.css.map */